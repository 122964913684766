@import url(http://fonts.googleapis.com/css?family=Lora:400,400italic,700italic,700);

// @font-face {
//     font-family: 'proxima_nova_regular';
//     src: url('fonts/proximanova-regular-webfont.eot');
//     src: url('fonts/proximanova-regular-webfont.eot?#iefix') format('embedded-opentype'),
//          url('fonts/proximanova-regular-webfont.woff') format('woff'),
//          url('fonts/proximanova-regular-webfont.ttf') format('truetype'),
//          url('fonts/proximanova-regular-webfont.svg#proxima_nova_regular') format('svg');
//     font-weight: normal;
//     font-style: normal;

// }

// @font-face {
//     font-family: 'proxima_nova_bold';
//     src: url('fonts/proximanova-bold-webfont.eot');
//     src: url('fonts/proximanova-bold-webfont.eot?#iefix') format('embedded-opentype'),
//          url('fonts/proximanova-bold-webfont.woff') format('woff'),
//          url('fonts/proximanova-bold-webfont.ttf') format('truetype'),
//          url('fonts/proximanova-bold-webfont.svg#proxima_nova_bold') format('svg');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'proxima_novalight';
//     src: url('fonts/proximanova-light-webfont.eot');
//     src: url('fonts/proximanova-light-webfont.eot?#iefix') format('embedded-opentype'),
//          url('fonts/proximanova-light-webfont.woff') format('woff'),
//          url('fonts/proximanova-light-webfont.ttf') format('truetype'),
//          url('fonts/proximanova-light-webfont.svg#proxima_novalight') format('svg');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'proxima_novablack';
//     src: url('fonts/proximanova-black-webfont.eot');
//     src: url('fonts/proximanova-black-webfont.eot?#iefix') format('embedded-opentype'),
//          url('fonts/proximanova-black-webfont.woff') format('woff'),
//          url('fonts/proximanova-black-webfont.ttf') format('truetype'),
//          url('fonts/proximanova-black-webfont.svg#proxima_novablack') format('svg');
//     font-weight: normal;
//     font-style: normal;

// }

// @font-face {
//     font-family: 'proxima_novaextrabold';
//     src: url('fonts/proximanova-extrabold-webfont.eot');
//     src: url('fonts/proximanova-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
//          url('fonts/proximanova-extrabold-webfont.woff') format('woff'),
//          url('fonts/proximanova-extrabold-webfont.ttf') format('truetype'),
//          url('fonts/proximanova-extrabold-webfont.svg#proxima_novaextrabold') format('svg');
//     font-weight: normal;
//     font-style: normal;

// }

/*--------------------------------------------------------------
1.0 Reset
--------------------------------------------------------------*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
	border: 0;
	font-family: inherit;
	font-size: 100%;
	font-style: inherit;
	font-weight: inherit;
	margin: 0;
	outline: 0;
	padding: 0;
	vertical-align: baseline;
}

html {
	font-size: 100%; /* Corrects text resizing oddly in IE6/7 when body font-size is set using em units http://clagnut.com/blog/348/#c790 */
	overflow-y: scroll; /* Keeps page centered in all browsers regardless of content height */
	-webkit-text-size-adjust: 100%; /* Prevents iOS text size adjust after orientation change, without disabling user zoom */
	-ms-text-size-adjust: 100%; /* www.456bereastreet.com/archive/201012/controlling_text_size_in_safari_for_ios_without_disabling_user_zoom/ */
	box-sizing: border-box; /* Apply a natural box layout model to the document; see http://www.paulirish.com/2012/box-sizing-border-box-ftw/ */
}
*,
*:before,
*:after {
	/* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
	box-sizing: inherit;
}

body {
	background: #fff; /* Fallback for when there is no custom background color defined. */
	width: 100%;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav,
section {
	display: block;
}

ol,
ul {
	list-style: none;
}

table {
	/* tables still need 'cellspacing="0"' in the markup */
	border-collapse: separate;
	border-spacing: 0;
}

caption,
th,
td {
	font-weight: normal;
	text-align: left;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
	content: "";
}

blockquote,
q {
	quotes: "" "";
}

a:focus {
	outline: thin dotted;
}

a:hover,
a:active {
	outline: 0;
}

a img {
	border: 0;
}

.hidden {
	display: none !important;
}

/*--------------------------------------------------------------
2.0 Typography
--------------------------------------------------------------*/
body,
button,
input,
select,
textarea {
	color: #4f4f4f;
	font-family: sans-serif;
	font-size: 14px;
	line-height: 1.5;
	font-family: "Lora", serif;
	font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	clear: both;
}

p {
	margin-bottom: 1.8em;
}

b,
strong {
	font-weight: bold;
}

dfn,
cite,
em,
i {
	font-style: italic;
}

blockquote {
	margin: 0 1.5em;
}

address {
	margin: 0 0 1.5em;
}

pre {
	background: #eee;
	font-family: "Courier 10 Pitch", Courier, monospace;
	font-size: 15px;
	font-size: 1.5rem;
	line-height: 1.6;
	margin-bottom: 1.6em;
	max-width: 100%;
	overflow: auto;
	padding: 1.6em;
}

code,
kbd,
tt,
var {
	font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
	font-size: 15px;
	font-size: 1.5rem;
}

abbr,
acronym {
	border-bottom: 1px dotted #666;
	cursor: help;
}

mark,
ins {
	background: #fff9c0;
	text-decoration: none;
}

sup,
sub {
	font-size: 75%;
	height: 0;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sup {
	bottom: 1ex;
}

sub {
	top: 0.5ex;
}

small {
	font-size: 75%;
}

big {
	font-size: 125%;
}

button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
	border: none;
	padding: 0;
	margin: 0;
}

/*--------------------------------------------------------------
3.0 Elements
--------------------------------------------------------------*/
hr {
	background-color: #ccc;
	border: 0;
	height: 1px;
	margin-bottom: 1.5em;
}

ul,
ol {
	margin: 0 0 1.5em 3em;
}

ul {
	list-style: disc;
}

ol {
	list-style: decimal;
}

li > ul,
li > ol {
	margin-bottom: 0;
	margin-left: 1.5em;
}

dt {
	font-weight: bold;
}

dd {
	margin: 0 1.5em 1.5em;
}

img {
	height: auto; /* Make sure images are scaled correctly. */
	max-width: 100%; /* Adhere to container width. */
}

figure {
	margin: 0;
}

table {
	margin: 0 0 1.5em;
	width: 100%;
}

th {
	font-weight: bold;
}

/*--------------------------------------------------------------
4.0 Forms
--------------------------------------------------------------*/
button,
input,
select,
textarea {
	font-size: 100%; /* Corrects font size not being inherited in all browsers */
	margin: 0; /* Addresses margins set differently in IE6/7, F3/4, S5, Chrome */
	vertical-align: baseline; /* Improves appearance and consistency in all browsers */
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
	border: 1px solid;
	border-color: #ccc #ccc #bbb;
	border-radius: 3px;
	background: #e6e6e6;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.5), inset 0 15px 17px rgba(255, 255, 255, 0.5),
		inset 0 -5px 12px rgba(0, 0, 0, 0.05);
	color: rgba(0, 0, 0, 0.8);
	cursor: pointer; /* Improves usability and consistency of cursor style between image-type 'input' and others */
	-webkit-appearance: button; /* Corrects inability to style clickable 'input' types in iOS */
	font-size: 12px;
	font-size: 1.2rem;
	line-height: 1;
	padding: 0.6em 1em 0.4em;
	text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
}

button:hover,
input[type="button"]:hover,
input[type="reset"]:hover,
input[type="submit"]:hover {
	border-color: #ccc #bbb #aaa;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.8), inset 0 15px 17px rgba(255, 255, 255, 0.8),
		inset 0 -5px 12px rgba(0, 0, 0, 0.02);
}

button:focus,
input[type="button"]:focus,
input[type="reset"]:focus,
input[type="submit"]:focus,
button:active,
input[type="button"]:active,
input[type="reset"]:active,
input[type="submit"]:active {
	border-color: #aaa #bbb #bbb;
	box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 0.5), inset 0 2px 5px rgba(0, 0, 0, 0.15);
}

input[type="checkbox"],
input[type="radio"] {
	padding: 0; /* Addresses excess padding in IE8/9 */
}

input[type="search"] {
	-webkit-appearance: textfield; /* Addresses appearance set to searchfield in S5, Chrome */
	box-sizing: content-box;
}

input[type="search"]::-webkit-search-decoration {
	/* Corrects inner padding displayed oddly in S5, Chrome on OSX */
	-webkit-appearance: none;
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
textarea {
	color: #666;
	border: 1px solid #ccc;
	border-radius: 3px;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
textarea:focus {
	color: #111;
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"] {
	padding: 3px;
}

textarea {
	overflow: auto; /* Removes default vertical scrollbar in IE6/7/8/9 */
	padding-left: 3px;
	vertical-align: top; /* Improves readability and alignment in all browsers */
	width: 100%;
}

/*--------------------------------------------------------------
5.0 Navigation
--------------------------------------------------------------*/
/*--------------------------------------------------------------
5.1 Links
--------------------------------------------------------------*/
a {
	color: royalblue;
}

a:visited {
	color: purple;
}

a:hover,
a:focus,
a:active {
	color: midnightblue;
}

/*--------------------------------------------------------------
5.2 Menus
--------------------------------------------------------------*/
.main-navigation {
	display: none;
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 9999;
}

.main-navigation .menu_inside {
	float: left;
	height: 100%;
	text-align: center;
	width: 100%;
}

.main-navigation .menu_inside img#menu_close {
	cursor: pointer;
	display: block;
	position: absolute;
	right: 2em;
	top: 2em;
}

.main-navigation .menu_inside h1 {
	display: inline-block;
	margin-top: 1em;
	font-family: "proxima_novablack";
	color: #fff;
	font-size: 4.2857em;
	text-transform: uppercase;
	font-weight: normal;
	margin-bottom: 1em;
}

.main-navigation ul {
	list-style: none;
	margin: 0;
	padding-left: 0;
}

.main-navigation li {
	position: relative;
	margin-bottom: 2em;
}

.main-navigation a {
	display: inline-block;
	text-decoration: none;
	font-family: "proxima_novablack";
	font-size: 2.5em;
	color: #029e93;
	text-transform: uppercase;
}

.main-navigation li a:hover {
	color: #fff;
}

.main-navigation ul ul {
	box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
	float: left;
	position: absolute;
	top: 1.5em;
	left: -999em;
	z-index: 99999;
}

.main-navigation ul ul ul {
	left: -999em;
	top: 0;
}

.main-navigation ul ul a {
	width: 200px;
}

.main-navigation ul ul li {
}

.main-navigation li:hover > a {
}

.main-navigation ul ul :hover > a {
}

.main-navigation ul ul a:hover {
}

.main-navigation ul li:hover > ul {
	left: auto;
}

.main-navigation ul ul li:hover > ul {
	left: 100%;
}

.main-navigation .current_page_item > a,
.main-navigation .current-menu-item > a,
.main-navigation .current_page_ancestor > a {
	color: #fff;
}

.site-main .comment-navigation,
.site-main .paging-navigation,
.site-main .post-navigation {
	margin: 0 0 1.5em;
}

.comment-navigation .nav-previous,
.paging-navigation .nav-previous,
.post-navigation .nav-previous {
	float: left;
	width: 50%;
}

.comment-navigation .nav-next,
.paging-navigation .nav-next,
.post-navigation .nav-next {
	float: right;
	text-align: right;
	width: 50%;
}

/*--------------------------------------------------------------
6.0 Accessibility
--------------------------------------------------------------*/
/* Text meant only for screen readers */
.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;
}

.screen-reader-text:hover,
.screen-reader-text:active,
.screen-reader-text:focus {
	background-color: #f1f1f1;
	border-radius: 3px;
	box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
	clip: auto !important;
	color: #21759b;
	display: block;
	font-size: 14px;
	font-size: 1.4rem;
	font-weight: bold;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	text-decoration: none;
	top: 5px;
	width: auto;
	z-index: 100000; /* Above WP toolbar */
}

/*--------------------------------------------------------------
7.0 Alignments
--------------------------------------------------------------*/
.alignleft {
	display: inline;
	float: left;
	margin-right: 1.8em;
	margin-bottom: 1.8em;
}

.alignright {
	display: inline;
	float: right;
	margin-left: 1.8em;
	margin-bottom: 1.8em;
}

.aligncenter {
	clear: both;
	display: block;
	margin: 0 auto;
}

/*--------------------------------------------------------------
8.0 Clearings
--------------------------------------------------------------*/
.clear:before,
.clear:after,
.entry-content:before,
.entry-content:after,
.comment-content:before,
.comment-content:after,
.site-header:before,
.site-header:after,
.site-content:before,
.site-content:after,
.site-footer:before,
.site-footer:after {
	content: "";
	display: table;
}

.clear:after,
.entry-content:after,
.comment-content:after,
.site-header:after,
.site-content:after,
.site-footer:after {
	clear: both;
}

/*--------------------------------------------------------------
9.0 Widgets
--------------------------------------------------------------*/
.widget {
	margin: 0 0 1.5em;
}

/* Make sure select elements fit in widgets */
.widget select {
	max-width: 100%;
}

/* Search widget */
.widget_search .search-submit {
	display: none;
}

/*--------------------------------------------------------------
10.0 Content
--------------------------------------------------------------*/
/*--------------------------------------------------------------
10.1 Posts and pages
--------------------------------------------------------------*/
.sticky {
	display: block;
}

.hentry {
	margin: 0;
}

.byline,
.updated:not(.published) {
	display: none;
}

.single .byline,
.group-blog .byline {
	display: inline;
}

.page-content,
.entry-content,
.entry-summary {
	margin: 1.5em 0 0;
}

.page-links {
	clear: both;
	margin: 0 0 1.5em;
}

/*--------------------------------------------------------------
10.2 Asides
--------------------------------------------------------------*/
.blog .format-aside .entry-title,
.archive .format-aside .entry-title {
	display: none;
}

/*--------------------------------------------------------------
10.3 Comments
--------------------------------------------------------------*/
.comment-content a {
	word-wrap: break-word;
}

.bypostauthor {
	display: block;
}

/*--------------------------------------------------------------
10.4 Hero
--------------------------------------------------------------*/
.hero {
	background: {
		image: url("assets/images/hero.jpg");
		size: cover;
		position: center;
	}
	height: 500px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	&:before {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background: rgba(0, 0, 0, 60%);
	}
	h1 {
		position: relative;
		color: #fff;
		font-size: 40px;
	}
	.logo {
		position: relative;
	}
	figcaption {
		display: none;
	}
}

/*--------------------------------------------------------------
11.0 Infinite scroll
--------------------------------------------------------------*/
/* Globally hidden elements when Infinite Scroll is supported and in use. */
.infinite-scroll .paging-navigation, /* Older / Newer Posts Navigation (always hidden) */
.infinite-scroll.neverending .site-footer {
	/* Theme Footer (when set to scrolling) */
	display: none;
}

/* When Infinite Scroll has reached its end we need to re-display elements that were hidden (via .neverending) before */
.infinity-end.neverending .site-footer {
	display: block;
}

/*--------------------------------------------------------------
12.0 Media
--------------------------------------------------------------*/
.page-content img.wp-smiley,
.entry-content img.wp-smiley,
.comment-content img.wp-smiley {
	border: none;
	margin-bottom: 0;
	margin-top: 0;
	padding: 0;
}

/* Make sure embeds and iframes fit their containers */
embed,
iframe,
object {
	max-width: 100%;
}

/*--------------------------------------------------------------
12.1 Captions
--------------------------------------------------------------*/
.wp-caption {
	margin-bottom: 1.5em;
	max-width: 100%;
}

.wp-caption img[class*="wp-image-"] {
	display: block;
	margin: 0 auto;
}

.wp-caption-text {
	text-align: center;
}

.wp-caption .wp-caption-text {
	margin: 0.8075em 0;
}

/*--------------------------------------------------------------
12.2 Galleries
--------------------------------------------------------------*/
.gallery {
	margin-bottom: 1.5em;
}

.gallery-item {
	display: inline-block;
	text-align: center;
	vertical-align: top;
	width: 100%;
}

.gallery-columns-2 .gallery-item {
	max-width: 50%;
}

.gallery-columns-3 .gallery-item {
	max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
	max-width: 25%;
}

.gallery-columns-5 .gallery-item {
	max-width: 20%;
}

.gallery-columns-6 .gallery-item {
	max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
	max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
	max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
	max-width: 11.11%;
}

.gallery-caption {
	display: block;
}

/*****************************************Header******************************************************/

#masthead.site-header {
	background-color: #0c0c0c;
	height: 7.4em;
	margin-bottom: 1.5em;
	position: relative;
	width: 100%;
	z-index: 2;
}

#masthead.site-header .wrap,
.archive .site-content .wrap,
.error404 .site-content .wrap,
.single .site-content .wrap,
.page .site-content .wrap,
#partners div.wrap,
#product_side div.wrap {
	margin: 0 auto;
	width: 96%;
	position: relative;
	height: 100%;
	max-width: 1820px;
}

#masthead.site-header .wrap {
	width: 96%;
	max-width: 1820px;
}

#masthead.site-header div.wrap div.menu-button {
	cursor: pointer;
	display: inline-block;
	position: relative;
	top: 1.7em;
	width: 3.5em;
}

#masthead.site-header div.wrap div.menu-button img {
	width: 100%;
}

#masthead.site-header div.wrap div.site-branding {
	float: left;
	left: 5em;
	position: absolute;
	top: 0;
	width: 10.13em;
}

#masthead.site-header div.wrap div.site-branding h1.site-title a img {
	width: 75%;
	margin: 12px 0 0;
}

#masthead.site-header div.wrap div.social {
	display: table;
	float: right;
	height: 7em;
}

#masthead.site-header div.wrap div.social span#ttl {
	color: #6f6f6f;
	display: table-cell;
	font-family: "proxima_novalight";
	font-size: 0.95em;
	letter-spacing: 0.4em;
	text-transform: uppercase;
	vertical-align: middle;
	padding-right: 1em;
}

#masthead.site-header div.wrap div.social div#top_social {
	display: table-cell;
	vertical-align: middle;
}

#masthead.site-header div.wrap div.social div#top_social span.ext_sl {
	display: inline-block;
	width: 1.86em;
	margin-left: 0.3em;
}

#masthead.site-header div.wrap div.social div#top_social span.ext_sl img {
	width: 100%;
}

#content.site-content {
	min-height: 200px;
	position: relative;
	z-index: 1;
	margin-top: -1.5em;
}

/***************************************footer*********************************************/

.site-footer {
	background-color: #0c0c0c;
	height: 39em;
}

.site-footer div.site-info {
	text-align: center;
	margin-top: 8em;
}

.site-footer div.site-info span.ext_sl {
}

.site-footer div.site-info span.ext_sl img#footer_logo {
}

.site-footer div.site-info #copy {
	color: #747474;
	font-family: "proxima_novalight";
	font-size: 0.85em;
	margin-top: 1em;
}

.site-footer div.site-info div#design {
	color: #747474;
	font-family: "proxima_novalight";
	font-size: 0.85em;
	margin-top: 0.5em;
}

.site-footer div.site-info div#design a {
	color: #747474;
}

.site-footer div.site-info div#design a:hover {
	text-decoration: none;
}

.site-footer div.site-info div.social {
	margin-top: 1.5em;
}

.site-footer div.site-info div.social div#bottom_social span.ext_sl {
	display: inline-block;
	margin-left: 0.3em;
	width: 1.86em;
}

.site-footer div.site-info div.social div#bottom_social span.ext_sl img {
	width: 100%;
}

#partners {
	background-color: #020202;
	width: 100%;
	height: 20em;
}

#partners div.wrap div.head {
	color: #747474;
	float: left;
	font-family: "proxima_novalight";
	font-size: 3.5714em;
	line-height: 1.15em;
	margin-top: 1.7em;
	text-transform: uppercase;
	width: 8em;
	margin-right: 0.7em;
}

#partners div.wrap div.head span {
	display: block;
	color: #fff;
	font-family: "proxima_novablack";
}

#partners div.wrap div.sponsor {
	float: left;
	margin-left: 2em;
}

#partners div.wrap div.sponsor.s1 {
	margin-top: 5em;
	max-width: 341px;
	width: 24.35em;
}

#partners div.wrap div.sponsor.s2 {
	margin-top: 4em;
	max-width: 200px;
	width: 14.3em;
}

#partners div.wrap div.sponsor.s3 {
	margin-top: 6.8em;
	max-width: 333px;
	width: 24em;
}

#partners div.wrap div.sponsor.s4 {
	margin-left: 4em;
	margin-top: 7em;
	max-width: 239px;
	width: 17.05em;
}

#partners div.wrap div.sponsor img {
	width: 100%;
}

/********************************single******************************************/
.single #page.site {
	background-color: #ececec;
}

.single .site-content #primary.content-area .site-main .entry-header {
	float: left;
	width: 100%;
}

.single .site-content #primary.content-area .site-main .entry-header h1.entry-title {
	color: #272727;
	font-family: "proxima_nova_bold";
	font-size: 3.5em;
	border-bottom: 1px solid #dfdfdf;
	text-transform: uppercase;
	padding-bottom: 0.18em;
}

#primary.content-area {
	float: left;
	margin-right: 2.3%;
	width: 75% !important;
}

#secondary.widget-area {
	float: left;
	width: 22% !important;
	margin-top: 2.05em;
}

.single .site-content .wrap {
	margin-top: 4em;
}

.single .site-content #primary.content-area .site-main .entry-header div.entry-meta {
	color: #909090;
	font-family: "proxima_novalight";
	font-size: 0.95em;
	margin: 0.8em 0 1em;
}

.single .site-content #primary.content-area .site-main .entry-content {
	width: 98%;
}

#secondary.widget-area .widget {
	margin-bottom: 6em;
}

#secondary.widget-area .widget h1.widget-title {
	color: #272727;
	font-family: "proxima_nova_bold";
	font-size: 2.12em;
	border-bottom: 1px solid #dfdfdf;
	text-transform: uppercase;
	padding-bottom: 1em;
	margin-bottom: 1.1em;
}

#secondary.widget-area .widget ul {
	list-style: outside none none;
	margin: 0 0 0 0.3em;
}

#secondary.widget-area .widget ul li {
	margin-bottom: 1.2em;
}

#secondary.widget-area .widget ul li a {
	color: #4f4f4f;
	text-decoration: none;
}

#secondary.widget-area .widget ul li a:hover {
	color: #029e93;
	text-decoration: underline;
}

/***********************************************************************/

#latest_article {
	background-color: #fff;
	float: left;
	width: 100%;
}

#latest_article .wrap {
	margin-top: 4.5em;
	position: relative;
	width: 96%;
}

#latest_article h1 {
	font-size: 3.572em;
	font-family: "proxima_novalight";
	text-transform: uppercase;
	color: #272727;
	margin-bottom: 0.3em;
}

#latest_article h1 span {
	font-family: "proxima_novablack";
}

#latest_article span#stay_connected {
	color: #7d7d7d;
	font-family: "proxima_nova_regular";
	font-size: 1.428em;
	position: absolute;
	right: 3.7%;
	text-transform: uppercase;
	top: -1em;
	letter-spacing: 0.12em;
}

#latest_article .item {
	float: left;
	margin-right: 8px;
	width: 18.22%;
	max-width: 200px;
}

#latest_article .item.odd {
	background-color: #f8f8f8;
}

#latest_article .item.last {
	margin-right: 0;
}

#latest_article .item .wrap_int {
	padding: 1.7em;
}

#latest_article .item div.entry-meta {
	color: #7b7b7b;
	font-size: 0.786em;
	font-family: "proxima_nova_regular";
	text-transform: uppercase;
	margin-bottom: 0.5em;
}

#latest_article .item h3.entry-title {
	color: #272727;
	text-transform: uppercase;
	font-family: "proxima_novaextrabold";
	font-size: 1.2857em;
	line-height: 1.2em;
}

#latest_article .item h3.entry-title a {
	color: #272727;
	text-decoration: none;
}

#latest_article .item .short_content {
}

#latest_article .item div.imageside {
}

#latest_article .item div.imageside img {
}

#latest_article div.wrap div.borderline {
	background-color: #f8f8f8;
	float: left;
	height: 0.7em;
	margin: 4.5em 0 6.2em;
	text-align: center;
	width: 95%;
	position: relative;
}

#latest_article div.wrap div.borderline span.ext_sl {
	display: inline-block;
	position: relative;
	top: -0.28em;
	width: 5.14285em;
}

#latest_article div.wrap div.borderline span.ext_sl img {
	width: 100%;
	display: block;
}

/****************************homepage************************************/

#homeslider {
	background-color: #000;
	float: left;
	position: relative;
	width: 100%;
}

#homeslider .slider-for {
	max-height: 480px;
	overflow: hidden;
}

#homeslider .slider-nav {
	margin: 0 auto;
	width: 98%;
	max-width: 1823px;
	padding-bottom: 2em;
}

#homeslider .slider-for .slide_item div.img_ext {
	height: 470px;
	opacity: 0.7;
}

#homeslider .slide_thumbnail_item {
	margin-right: 1.57em;
	padding-top: 1.07em;
	opacity: 0.25;
	cursor: pointer;
	width: 17.4em;
}

// #homeslider .slide_thumbnail_item.slick-center{
// 	background: url("images/slider/slide_thumb_activeline.png") no-repeat scroll center top transparent;
// 	opacity:0.8;
// }

#homeslider .slide_thumbnail_item img {
	width: 100%;
}

#homeslider .slider-for .slide_item .content_side {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
}

#homeslider .slider-for .slide_item .wrap {
	margin: 0 auto;
	width: 98%;
	height: 100%;
	position: relative;
	max-width: 1880px;
}

#homeslider .slider-for .slide_item .wrap h1 {
	bottom: 29%;
	color: #fff;
	font-family: "proxima_novablack";
	font-size: 4.285em;
	position: absolute;
	text-transform: uppercase;
	width: 80%;
}

#homeslider .slider-for .slide_item .content_side div.wrap div.buttons {
	bottom: 30%;
	position: absolute;
	right: 0;
}

.btn {
	color: #fff;
	display: inline-block;
	font-size: 1.07em;
	font-family: "proxima_nova_bold";
	line-height: 1;
	text-decoration: none;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	margin-bottom: 0.5em;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#homeslider .btn {
	display: block;
}

a.btn {
	color: #fff;
	text-decoration: none;
}

.btn span.right,
.btn span.left {
	display: inline-block;
	height: 3.6em;
	line-height: 3.6em;
	padding: 0 1em;
	vertical-align: top;
	-moz-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.13);
	-webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.13);
	box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.13);
}

.btn span.left {
	-webkit-transition: background-position 100ms 100ms ease;
	-moz-transition: background-position 100ms 100ms ease;
	transition: background-position 100ms 100ms ease;
	width: 12.44em;
	text-align: center;
}

.btn span.right {
	-webkit-transition: background-position 100ms ease;
	-moz-transition: background-position 100ms ease;
	transition: background-position 100ms ease;
	padding: 0;
	position: relative;
}

.btn span.left.aqua {
	background-color: #029e93;
	background: -webkit-linear-gradient(right, #029e93 50%, #14c0b4 50%);
	background: -moz-linear-gradient(right, #029e93 50%, #14c0b4 50%);
	background: -o-linear-gradient(right, #029e93 50%, #14c0b4 50%);
	background: linear-gradient(to left, #029e93 50%, #14c0b4 50%);
	background-size: 200% 100%;
	background-position: right bottom;
	-webkit-transition: background-position 100ms ease;
	-moz-transition: background-position 100ms ease;
	transition: background-position 100ms ease;
}

.btn span.right.aqua {
	background-color: #018178;
	background: -webkit-linear-gradient(right, #018178 50%, #14c0b4 50%);
	background: -moz-linear-gradient(right, #018178 50%, #14c0b4 50%);
	background: -o-linear-gradient(right, #018178 50%, #14c0b4 50%);
	background: linear-gradient(to left, #018178 50%, #14c0b4 50%);
	background-size: 200% 100%;
	background-position: right bottom;
	-webkit-transition: background-position 100ms ease;
	-moz-transition: background-position 100ms ease;
	transition: background-position 100ms ease;
}

.btn span.left.pomar {
	background-color: #ea593b;
	background: -webkit-linear-gradient(right, #ea593b 50%, #fa7255 50%);
	background: -moz-linear-gradient(right, #ea593b 50%, #fa7255 50%);
	background: -o-linear-gradient(right, #ea593b 50%, #fa7255 50%);
	background: linear-gradient(to left, #ea593b 50%, #fa7255 50%);
	background-size: 200% 100%;
	background-position: right bottom;
	-webkit-transition: background-position 100ms ease;
	-moz-transition: background-position 100ms ease;
	transition: background-position 100ms ease;
}

.btn span.right.pomar {
	background-color: #d44d31;
	background: -webkit-linear-gradient(right, #d44d31 50%, #fa7255 50%);
	background: -moz-linear-gradient(right, #d44d31 50%, #fa7255 50%);
	background: -o-linear-gradient(right, #d44d31 50%, #fa7255 50%);
	background: linear-gradient(to left, #d44d31 50%, #fa7255 50%);
	background-size: 200% 100%;
	background-position: right bottom;
	-webkit-transition: background-position 100ms ease;
	-moz-transition: background-position 100ms ease;
	transition: background-position 100ms ease;
}

.btn span.left.dark {
	background-color: #0c0c0c;
	background: -webkit-linear-gradient(right, #0c0c0c 50%, #282626 50%);
	background: -moz-linear-gradient(right, #0c0c0c 50%, #282626 50%);
	background: -o-linear-gradient(right, #0c0c0c 50%, #282626 50%);
	background: linear-gradient(to left, #0c0c0c 50%, #282626 50%);
	background-size: 200% 100%;
	background-position: right bottom;
	-webkit-transition: background-position 100ms ease;
	-moz-transition: background-position 100ms ease;
	transition: background-position 100ms ease;
}

.btn span.right.dark {
	background-color: #3d3d3d;
	background: -webkit-linear-gradient(right, #3d3d3d 50%, #282626 50%);
	background: -moz-linear-gradient(right, #3d3d3d 50%, #282626 50%);
	background: -o-linear-gradient(right, #3d3d3d 50%, #282626 50%);
	background: linear-gradient(to left, #3d3d3d 50%, #282626 50%);
	background-size: 200% 100%;
	background-position: right bottom;
	-webkit-transition: background-position 100ms ease;
	-moz-transition: background-position 100ms ease;
	transition: background-position 100ms ease;
}

.btn span.left.blue {
	background-color: #3b6ecb;
	background: -webkit-linear-gradient(right, #3b6ecb 50%, #4077dc 50%);
	background: -moz-linear-gradient(right, #3b6ecb 50%, #4077dc 50%);
	background: -o-linear-gradient(right, #3b6ecb 50%, #4077dc 50%);
	background: linear-gradient(to left, #3b6ecb 50%, #4077dc 50%);
	background-size: 200% 100%;
	background-position: right bottom;
	-webkit-transition: background-position 100ms ease;
	-moz-transition: background-position 100ms ease;
	transition: background-position 100ms ease;
}

.btn span.right.blue {
	background-color: #2e5aab;
	background: -webkit-linear-gradient(right, #2e5aab 50%, #4077dc 50%);
	background: -moz-linear-gradient(right, #2e5aab 50%, #4077dc 50%);
	background: -o-linear-gradient(right, #2e5aab 50%, #4077dc 50%);
	background: linear-gradient(to left, #2e5aab 50%, #4077dc 50%);
	background-size: 200% 100%;
	background-position: right bottom;
	-webkit-transition: background-position 100ms ease;
	-moz-transition: background-position 100ms ease;
	transition: background-position 100ms ease;
}

.btn span.right span {
	background-image: url("assets/images/btn_arrow.png");
	background-position: center center;
	background-repeat: no-repeat;
	float: none;
}

.btn span {
	padding: 0.13em 1.5em;
}

@keyframes circlearrow {
	0% {
		background-position-x: 0px;
	}
	60% {
		background-position-x: 55px;
	}
	60.05% {
		background-position-x: -65px;
	}
	80% {
		background-position-x: 0;
	}
}
@-moz-keyframes circlearrow {
	0% {
		background-position-x: 0px;
	}
	60% {
		background-position-x: 55px;
	}
	60.05% {
		background-position-x: -65px;
	}
	80% {
		background-position-x: 0;
	}
}
@-webkit-keyframes circlearrow /* Safari and Chrome */ {
	0% {
		background-position-x: 0px;
	}
	60% {
		background-position-x: 55px;
		z-index: -1;
	}
	60.005% {
		background-position-x: -65px;
		z-index: 1;
	}
	80% {
		background-position-x: 0px;
	}
}

.btn:hover span.right,
.btn:hover span.left {
	background-position: left bottom;
}
.btn:hover span.right {
	-webkit-transition: background-position 100ms 100ms ease;
	-moz-transition: background-position 100ms 100ms ease;
	transition: background-position 100ms 100ms ease;
}
.btn:hover span.left {
	-webkit-transition: background-position 100ms ease;
	-moz-transition: background-position 100ms ease;
	transition: background-position 100ms ease;
}
.btn:hover span.right span {
	animation: circlearrow 500ms;
	-moz-animation: circlearrow 500ms;
	-webkit-animation: circlearrow 500ms;
}
.btn:hover span.right span {
	animation: circlearrow 500ms;
	-moz-animation: circlearrow 500ms;
	-webkit-animation: circlearrow 500ms;
}

#newsletter {
	background-color: #0c0c0c;
	float: left;
	width: 100%;
}

#newsletter div.wrap {
	width: 1400px;
}

#newsletter div.wrap div.art {
	color: #747474;
	display: inline-block;
	font-family: "proxima_novalight";
	font-size: 3.5714em;
	line-height: 1.1em;
	padding: 1.05em 0.8em 1.05em 0;
	text-transform: uppercase;
	float: left;
	letter-spacing: -0.025em;
}

#newsletter div.wrap div.art span#bld {
	display: block;
	color: #fff;
	font-family: "proxima_novablack";
}

#newsletter div.wrap div.art span#bld span#end {
	color: #747474;
}

#newsletter div.wrap div.newsletter {
	float: left;
	margin-top: 3.55em;
}

#newsletter div.wrap div.newsletter div.head,
#newsletter div.wrap div.singup_facebook div.head {
	color: #fff;
	font-family: "proxima_novablack";
	font-size: 1.7857em;
	margin-bottom: 0.3em;
	text-transform: uppercase;
}

#newsletter div.wrap div.singup_facebook div.head {
	margin-bottom: 0.2em;
}

#newsletter div.wrap div.newsletter form input.newsletter-email {
	// background: url("images/form_icon/2.png") no-repeat scroll 1.8em 1.4em #fff;
	border-radius: 0;
	float: left;
	font-family: "proxima_nova_regular";
	font-size: 0.8571em;
	height: 4.43em;
	margin-right: 0.4em;
	padding: 0 1em 0 5em;
	width: 26.66em;
}

#newsletter div.wrap div.newsletter form button.newsletter-submit {
	background: none repeat scroll 0 0 transparent;
	border: 0 none;
	box-shadow: none;
	text-shadow: none;
	padding: 0;
	margin: 0;
	float: left;
	border-radius: 0;
}

#newsletter .btn span.left {
	display: block;
	float: left;
	width: 11em;
}

#newsletter .btn.fbb {
	cursor: pointer;
}

#newsletter .btn.fbb span.left {
	display: block;
	float: right;
	width: 17em;
}

#newsletter .btn.fbb span.right {
}

#newsletter .btn.fbb span.right span {
	display: block;
	height: 100%;
	width: 100%;
}

#newsletter span.or {
	color: #777777;
	display: inline-block;
	font-family: "proxima_novalight";
	font-size: 1.42857em;
	margin: 4.5em 2em 0;
	text-transform: uppercase;
	float: left;
}

#newsletter div.wrap div.singup_facebook {
	float: left;
	margin-top: 3.4em;
}

#talebeh {
	background-color: #0c0c0c;
	float: left;
	width: 100%;
}

#talebeh .wrap {
	margin-top: 3.2em;
}

#talebeh .talent {
	width: 49%;
	float: left;
	margin-right: 2%;
}

#talebeh .talent h1 {
	border-bottom: 0.16em solid #ea593b;
	color: #fff;
	font-family: "proxima_novablack";
	font-size: 3.571em;
	margin-bottom: 0.4em;
	padding-bottom: 0.2em;
	text-transform: uppercase;
	width: 100%;
}

#talebeh .talent div.item {
	float: left;
	width: 100%;
	margin-bottom: 3.5em;
}

#talebeh .talent div.item div.leftside {
	float: left;
	width: 21%;
	margin-right: 1em;
	max-width: 185px;
	margin-top: 0.8em;
}

#talebeh div.leftside a {
}

#talebeh .talent div.item div.rightside {
	float: left;
	width: 73%;
	color: #aeaeae;
}

#talebeh .talent div.item div.rightside #about {
	color: #d3d3d3;
	display: block;
	font-family: "proxima_nova_regular";
	font-size: 1.428em;
	margin-bottom: 0.5em;
}

#talebeh .talent div.item div.rightside .short_content {
	display: block;
	font-style: italic;
	margin-bottom: 1.8em;
}

#talebeh .talent div.item div.leftside a.pop_up {
	display: block;
	max-width: 340px;
	width: 94%;
	position: relative;
}

#talebeh .talent div.item div.leftside a.pop_up span#shadow {
	// background: url("images/shadow.png") repeat scroll 0 0 transparent;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

#talebeh .talent div.item div.leftside a.pop_up span#video_icon {
	// background: url("images/play_btn_mid.png") no-repeat scroll center center transparent;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	cursor: pointer;
}

#talebeh .talent div.item div.leftside a.pop_up img#video_b {
	width: 100%;
	display: block;
}

#talebeh .talent div.item div.rightside h3 {
	font-size: 2.143em;
	font-family: "proxima_nova_bold";
	color: #fff;
	text-transform: uppercase;
	line-height: 1.2em;
	margin-bottom: 0.5em;
}

#talebeh .talent div.item div.rightside h3 a {
	color: #fff;
	text-decoration: none;
}

#talebeh .talent div.item div.rightside a.btn {
	margin-bottom: 0;
}

#talebeh .talent div.item div.rightside a.btn span.left {
	width: 11em;
}

#talebeh .behinds {
	float: left;
	width: 49%;
}

#talebeh .behinds h1 {
	border-bottom: 0.16em solid #029e93;
	color: #fff;
	font-family: "proxima_novablack";
	font-size: 3.571em;
	margin-bottom: 0.6em;
	padding-bottom: 0.2em;
	text-transform: uppercase;
	width: 100%;
}

#talebeh .behinds div.item {
	float: left;
	width: 100%;
	margin-bottom: 3.5em;
}

#talebeh .behinds div.item div.leftside {
	float: left;
	width: 41%;
	margin-right: 1em;
}

#talebeh .behinds div.item div.rightside {
	float: left;
	width: 51%;
	color: #aeaeae;
}

#talebeh .behinds div.item div.rightside .btn span.left {
	width: 10.24em;
}

#talebeh .behinds div.item div.rightside .short_content {
	margin-bottom: 1.8em;
}

#talebeh .behinds div.item div.leftside a.pop_up {
	display: block;
	max-width: 340px;
	width: 100%;
	position: relative;
}

#talebeh .behinds div.item div.leftside a.pop_up span#shadow {
	// background: url("images/shadow.png") repeat scroll 0 0 transparent;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

#talebeh .behinds div.item div.leftside a.pop_up span#video_icon {
	// background: url("images/play_btn_mid.png") no-repeat scroll center center transparent;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	cursor: pointer;
}

#talebeh .behinds div.item div.leftside a.pop_up img#video_b {
	width: 100%;
	display: block;
}

#talebeh .behinds div.item div.rightside h3 {
	font-size: 1.785em;
	font-family: "proxima_novaextrabold";
	color: #fff;
	text-transform: uppercase;
	line-height: 1.2em;
	margin-bottom: 0.5em;
}

#talebeh .behinds div.item div.rightside h3 a {
	color: #fff;
	text-decoration: none;
}

#store {
	background-color: #fff;
	float: left;
	width: 100%;
}

#store div.wrap {
	margin-top: 3.2em;
}

#store div.wrap h1 {
	font-family: "proxima_novalight";
	font-size: 3.571em;
	text-transform: uppercase;
	color: #272727;
	margin-bottom: 0.3em;
}

#store div.wrap h1 span {
	font-family: "proxima_novablack";
}

#store div.wrap .wrap_store {
	float: left;
	margin-bottom: 1.6em;
	margin-right: 1.5em;
	width: 30.5%;
}

#store div.wrap div.product-item {
	float: left;
	width: calc(50vw - 25px);
	margin-right: 4px;
	background-color: #f8f8f8;
	border-right: 3px solid #eaeaea;
}

#store div.wrap div.product-item div.leftside {
	float: left;
	margin-right: 0.5%;
	max-width: 12.714em;
	width: 31.6%;
	height: 18.857em;
}

#store div.wrap div.product-item div.leftside a {
	display: block;
}

#store div.wrap div.product-item div.leftside div.ext_img {
}

#store div.wrap div.product-item div.leftside div.ext_img img.product_image {
	display: block;
	width: 100%;
}

#store div.wrap div.product-item div.rightside {
	background-color: #f8f8f8;
	float: left;
	height: 18.857em;
	padding: 1.1em;
	width: 67.9%;
}

#store div.wrap div.product-item div.rightside h3 {
	font-family: "proxima_nova_bold";
	font-size: 1.7857em;
	text-transform: uppercase;
	color: #272727;
	margin-bottom: 0.2em;
	line-height: 1.1em;
}

#store div.wrap div.product-item div.rightside h3 a {
	color: #272727;
	text-decoration: none;
}

#store div.wrap div.product-item div.rightside .short_content {
	float: left;
	margin-bottom: 3em;
	width: 100%;
}

#store div.wrap div.product-item div.rightside div.price {
	display: inline-block;
	float: left;
	margin-right: 1em;
}

#store div.wrap div.product-item div.rightside div.price p.price {
}

#store div.wrap div.product-item div.rightside div.price p.price span.heads {
	color: #029e93;
	display: block;
	font-family: "proxima_nova_bold";
	font-size: 1.1428em;
	text-transform: uppercase;
	line-height: 1.4em;
}

#store div.wrap div.product-item div.rightside div.price p.price span.amount {
	color: #000;
	display: block;
	font-family: "proxima_nova_bold";
	font-size: 1.714285em;
	text-transform: uppercase;
	line-height: 1;
}

#store div.wrap div.product-item div.rightside div.buy {
	display: inline-block;
	float: left;
}

#store div.wrap div.product-item div.rightside a.btn {
	background: none repeat scroll 0 0 transparent;
	border: medium none;
	border-radius: 0;
	box-shadow: none;
	padding: 0;
	text-shadow: none;
	display: flex;
}

#store div.wrap div.product-item div.rightside div.buy .btn span.left {
	padding: 0 0.5em;
	width: 7em;
}

#store div.wrap div.product-item div.rightside a.store_link {
	color: #029e93;
	display: inline-block;
	font-family: "proxima_nova_bold";
	font-size: 1.07143em;
	margin: 1.2em;
	text-transform: uppercase;
}

#store div.wrap div.product-item .borderline {
	background-color: #f8f8f8;
	float: left;
	height: 0.7em;
	margin: 1em 0 4em;
	position: relative;
	text-align: left;
	width: 100%;
}

#store div.wrap div.product-item .borderline span.ext_sl {
	display: inline-block;
	position: relative;
	top: -0.28em;
	width: 5.14285em;
	left: 1em;
}

#store div.wrap div.product-item .borderline span.ext_sl img {
	display: block;
	width: 100%;
}

#store div.wrap div.store_form {
	float: left;
	width: 68%;
}

#store div.wrap div.store_form h1 {
	color: #272727;
	font-family: "proxima_novaextrabold";
	font-size: 3.571em;
	margin-bottom: 0.3em;
	text-transform: uppercase;
	letter-spacing: -0.03em;
}

#store div.wrap div.store_form div.form_side {
	background-color: #272727;
	border: 2px solid #000;
	padding: 1em 2.28em;
	height: 18.857em;
}

#store div.wrap div.store_form div.form_side .desc {
	font-family: "proxima_nova_regular";
	color: #fff;
	font-size: 1.7857em;
	margin: 0.2em 0 0.5em;
}

#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper div.itm {
	margin-bottom: 0.6em;
}

#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper div.itm textarea.comment {
	// background: url("images/form_icon/4.png") no-repeat scroll 1.5em 1.5em #fff;
	border: 1px solid transparent;
	border-radius: 0;
	color: #747474;
	font-family: "proxima_nova_regular";
	height: 8.5em;
	padding: 1.2em 1em 0 3.9em;
	resize: none;
	width: 100%;
	font-size: 0.8571em;
}

#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper input.inout {
	background-color: #fff;
	border: 1px solid transparent;
	border-radius: 0;
	color: #747474;
	float: left;
	font-family: "proxima_nova_regular";
	font-size: 0.8571em;
	height: 4.25em;
	line-height: 4.25em;
	padding: 0 1em 0 3.9em;
	width: 26.666em;
}

// #store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper input.inout.name{
// 	background: url("images/form_icon/1.png") no-repeat scroll 1.25em center #fff;
// 	margin-right: 2%;
// }

// #store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper input.inout.email{
// 	background: url("images/form_icon/2.png") no-repeat scroll 1.25em center #fff;
// }

button.btn.form {
	background: none repeat scroll 0 0 transparent;
	border: medium none;
	border-radius: 0;
	box-shadow: none;
	display: block;
	padding: 0;
	position: relative;
	text-shadow: none;
	top: -0.05em;
}

.btn.form span.right,
.btn.form span.left {
	height: 3.4em;
	line-height: 3.4em;
}

#red_carpet {
	background-color: #fff;
	float: left;
	width: 100%;
	margin-bottom: 4em;
	overflow: hidden;
}

#red_carpet div.wrap {
	margin-top: 3.2em;
}

#red_carpet div.wrap h1 {
	color: #d44d31;
	font-family: "proxima_novalight";
	font-size: 3.571em;
	margin-bottom: 0.3em;
	text-transform: uppercase;
	line-height: 1em;
	margin: 0.5em 0;
	position: relative;
}

#red_carpet div.wrap h1 span {
	font-family: "proxima_novablack";
}

#red_carpet div.wrap a#all_events {
	color: #029e93;
	font-family: "proxima_nova_bold";
	font-size: 0.35em;
	line-height: 1em;
	margin-left: 1em;
	position: absolute;
	text-decoration: none;
	text-transform: none;
	top: 1.5em;
}

#red_carpet div.wrap div.item {
	float: left;
	width: 49.2%;
	min-height: 265px;
}

#red_carpet div.wrap div.item.noimage {
	width: 49.2%;
}

#red_carpet div.wrap div.item {
	margin-right: 8px;
	background-color: #f8f8f8;
	margin-bottom: 8px;
}

#red_carpet div.wrap div.item div.img_side {
	float: left;
	width: 28%;
	position: relative;
	background-color: #f8f8f8;
}

#red_carpet div.wrap div.item div.img_side img.ev_image {
	display: block;
	height: auto;
	max-width: 100%;
	width: 100%;
}

#red_carpet div.wrap div.item div.content_side {
	background-color: #f8f8f8;
	padding: 2em 1.8em 2.4em;
	width: 71.5%;
	float: left;
}

#red_carpet div.wrap div.item div.content_side.full {
	width: 100%;
}

#red_carpet div.wrap div.item div.content_side div.date {
	color: #272727;
	font-family: "proxima_novalight";
	font-size: 0.7857em;
	text-transform: uppercase;
}

#red_carpet div.wrap div.item div.content_side h3 {
	color: #272727;
	font-family: "proxima_nova_bold";
	font-size: 1.7857em;
	line-height: 1em;
	margin: 0.4em 0;
	text-transform: uppercase;
}

#red_carpet div.wrap div.item div.content_side h3 a {
	color: #272727;
	text-decoration: none;
}

#red_carpet div.wrap div.item div.content_side div.link {
	margin-top: 1.5em;
}

#red_carpet div.wrap div.item div.content_side div.link a.more {
	border-bottom: 1px solid #efd6d2;
	color: #d44d31;
	display: inline-block;
	font-family: "proxima_nova_bold";
	font-size: 1.2857em;
	line-height: 1;
	text-decoration: none;
	text-transform: uppercase;
}

#red_carpet div.wrap div.borderline {
	background-color: #f8f8f8;
	float: left;
	height: 0.7em;
	margin: 1.3em 0;
	position: relative;
	text-align: left;
	width: 95%;
}

#red_carpet div.wrap div.borderline span.ext_sl {
	display: inline-block;
	position: relative;
	top: -0.28em;
	width: 5.14285em;
	left: 1em;
	position: relative;
}

#red_carpet div.wrap div.borderline span.ext_sl img {
	display: block;
	width: 100%;
}

/**************************************Single Movie****************************************************/

.movies_single #movies_section {
	background-color: #0c0c0c;
	float: left;
	position: relative;
	width: 100%;
}

#movies_section div.img_ext {
	height: 588px;
	overflow: hidden;
	opacity: 0.7;
	text-align: center;
}

#movies_section div.img_ext div.panoram_img {
	float: left;
	height: 100%;
	width: 100%;
}

#movies_section img.panoram_image {
	width: 100%;
}

.movies_single #movies_section .content_side {
	left: 0;
	position: absolute;
	top: 3em;
	width: 100%;
}

.movies_single #movies_section .content_side .wrap {
	height: 100%;
	margin: 0 auto;
	position: relative;
	width: 96%;
	max-width: 1800px;
}

.movies_single #movies_section .content_side .wrap .ext {
	// background: transparent url("images/single_bg_top.png") repeat scroll 0 0;
	float: left;
	width: 100%;
	padding: 3em 3em 2em;
}

.movies_single #movies_section div.content_side .wrap .image_side {
	float: left;
	margin-right: 4%;
	width: 14.85%;
	max-width: 20em;
}

.movies_single #movies_section div.content_side .wrap .image_side .ext_img {
}

.movies_single #movies_section div.content_side .wrap .image_side .ext_img img.panoram_image {
	width: 100%;
}

.movies_single #movies_section div.content_side div.wrap div.right_side {
	float: left;
	width: 81.15%;
	position: relative;
}

.movies_single #movies_section div.content_side div.wrap div.right_side .topside {
	border-bottom: 1px solid #262626;
	margin-bottom: 2em;
}

.movies_single #movies_section div.content_side div.wrap div.right_side h1 {
	color: #fff;
	font-family: "proxima_nova_bold";
	font-size: 3.571428em;
	text-transform: uppercase;
	margin-right: 250px;
}

.movies_single #movies_section div.content_side div.wrap div.right_side h1 span {
	color: #747474;
}

.movies_single #movies_section div.content_side div.wrap div.right_side div.top_content_left {
	width: 43%;
	float: left;
	margin-right: 6%;
}

.movies_single #movies_section div.content_side div.wrap div.right_side div.top_content_right {
	width: 51%;
	float: left;
}

.movies_single #movies_section div.content_side div.wrap div.right_side div.m_item {
	color: #a9a9a9;
	font-family: "proxima_novalight";
	font-size: 1.143em;
	margin-bottom: 1.8em;
	width: 100%;
}

.movies_single #movies_section div.content_side div.wrap div.right_side div.m_item h4 {
	font-size: 1.0625em;
	font-family: "proxima_novaextrabold";
	color: #fff;
	text-transform: uppercase;
}

.movies_single #movies_section div.content_side div.wrap div.right_side div.top_content_right div.m_item {
	margin-bottom: 1em;
}

.movies_single #movies_section div.content_side div.wrap div.right_side div.top_content_right div.m_item h4 {
	display: inline-block;
	float: left;
	margin-right: 1.8em;
	text-align: right;
	width: 6.2em;
}

.movies_single #movies_section div.content_side div.wrap div.right_side div.top_content_right div.m_item span.article {
	display: block;
	margin-left: 8.5em;
}

.movies_single #movies_section div.content_side div.wrap div.right_side div.top_content_left div.m_item span.article {
	display: block;
}

.movies_single
	#movies_section
	div.content_side
	div.wrap
	div.right_side
	.top_content_left
	div.m_item
	span.article.synopsis {
	height: 150px;
}

.movies_single
	#movies_section
	div.content_side
	div.wrap
	div.right_side
	.top_content_right
	div.m_item
	span.article.actor {
	height: 100px;
}

#movies_section div.content_side div.wrap div.right_side div.pg {
	display: inline-block;
	position: absolute;
	right: -1em;
	top: -1em;
}

#movies_section div.content_side div.wrap div.right_side div.pg .copy {
	color: #fff;
	display: inline-block;
	float: left;
	margin-right: 1.2em;
	font-size: 0.786em;
	font-family: "proxima_novalight";
}

#movies_section div.content_side div.wrap div.right_side div.pg span.obi {
	display: inline-block;
}

#movies_section div.content_side div.wrap div.right_side div.pg span.obi img {
}

#movies_section div.content_side div.wrap div.right_side div.bottom_side {
	float: left;
	width: 100%;
	margin-top: 0.4em;
}

#movies_section div.content_side div.wrap div.right_side div.bottom_side div.treiler {
	display: inline-block;
	float: left;
	margin-right: 1em;
}

#movies_section .btn span.left {
	padding: 0 0.5em;
	width: 13em;
}

#movies_section .btn span.left.short {
	padding: 0 0.5em;
	width: 10em;
}

#movies_section div.content_side div.wrap div.right_side div.bottom_side div.buy {
	display: inline-block;
	float: left;
	margin-right: 2em;
}

#movies_section div.content_side div.wrap div.right_side div.bottom_side .watch_online {
	float: left;
	margin-right: 1em;
	margin-top: 1.3em;
}

#movies_section div.content_side div.wrap div.right_side div.bottom_side .watch_online span.watch_span {
	border-bottom: 1px solid #a9a9a9;
	display: block;
	line-height: 1.2em;
	color: #a9a9a9;
}

#movies_section div.content_side div.wrap div.right_side div.bottom_side .watch_online span.watch_span a {
	color: #a9a9a9;
	font-size: 1.214em;
	font-family: "proxima_novaextrabold";
	color: #fff;
	text-transform: uppercase;
	text-decoration: none;
}
/***************/

#movies_section div.content_side div.wrap div.ext div.image_side div.stay_review {
	margin: 0.4em 0 0 0;
	text-align: center;
}

#movies_section div.content_side div.wrap div.ext div.image_side div.stay_review span.review_click {
	border-bottom: 1px solid #a9a9a9;
	color: #a9a9a9;
	display: inline-block;
	line-height: 0.5em;
}

#movies_section div.content_side div.wrap div.ext div.image_side div.stay_review span.review_click a {
	color: #a9a9a9;
	font-size: 0.9em;
	font-family: "proxima_novaextrabold";
	color: #fff;
	text-transform: uppercase;
	text-decoration: none;
	line-height: 1;
}
/**********************/
#movies_section div.content_side div.wrap div.right_side div.bottom_side div.off_web {
	float: left;
	margin-right: 1em;
	margin-top: 1.3em;
}

#movies_section div.content_side div.wrap div.right_side div.bottom_side div.off_web span {
	color: #a9a9a9;
	border-bottom: 1px solid #a9a9a9;
	display: block;
	line-height: 1.2em;
}

#movies_section div.content_side div.wrap div.right_side div.bottom_side div.off_web a {
	font-size: 1.214em;
	font-family: "proxima_novaextrabold";
	color: #fff;
	text-transform: uppercase;
	text-decoration: none;
}

#movies_section div.content_side div.wrap div.right_side div.bottom_side div.social {
	float: left;
	margin-top: 1em;
}

#movies_section div.content_side div.wrap div.right_side div.bottom_side div.social a {
	display: block;
	float: left;
	height: 25px;
	margin-right: 10px;
	width: 26px;
}

// #movies_section div.content_side div.wrap div.right_side div.bottom_side div.social a.fb{
// 	background: url("images/social_movie.png") no-repeat scroll 0 0  transparent;
// }

// #movies_section div.content_side div.wrap div.right_side div.bottom_side div.social a.twt{
// 	background: url("images/social_movie.png") no-repeat scroll  -35px 0 transparent;
// }

// #movies_section div.content_side div.wrap div.right_side div.bottom_side div.social a.vimeo{
// 	background: url("images/social_movie.png") no-repeat scroll -70px 0 transparent;
// }

#our_movies {
	background-color: #0c0c0c;
	width: 100%;
	padding-bottom: 4em;
	display: flex;
}

#our_movies.mavie_page {
	background-color: #e1e1e1;
}

#our_movies div.wrap {
	margin-top: 5em;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}

#our_movies div.wrap .head {
	border-bottom: 0.5714em solid #ea593b;
	margin-bottom: 1.714em;
	width: calc(100% - 20px);
	max-width: 1300px;
	position: relative;
}

#our_movies.mavie_page div.wrap .head {
	border-bottom: none;
	margin-bottom: 2em;
}

#our_movies div.wrap h1 {
	color: #fff;
	font-family: "proxima_novablack";
	font-size: 3.571428em;
	text-transform: uppercase;
}

#our_movies.mavie_page div.wrap h1 {
	color: #040404;
	border-bottom: 1px solid #d7d7d7;
	font-family: "proxima_nova_bold";
}

#our_movies div.wrap {
	.movie-grid {
		display: flex;
		flex-wrap: wrap;
		width: calc(100% - 20px);
		max-width: 1300px;
	}
	.movie_itm {
		background-color: #040404;
		width: 100%;
		margin-right: 0;
		margin-bottom: 10px;
		text-decoration: none;
		display: block;

		@media screen and (min-width: 576px) {
			width: calc((100% - 20px) / 2);
			margin-right: 10px;
			&:nth-child(2n+2) {
				margin-right: 0;
			}
		}

		@media screen and (min-width: 768px) {
			width: calc((100% - 45px) / 4);
			margin-right: 15px;
			&:nth-child(2n+2) {
				margin-right: 15px;
			}
			&:nth-child(4n+4) {
				margin-right: 0;
			}
		}
		
		@media screen and (min-width: 992px) {
			width: calc((100% - 80px) / 5);
			margin-right: 20px;
			max-width: 245px;
			margin-bottom: 15px;
			&:nth-child(2n+2),
			&:nth-child(4n+4) {
				margin-right: 20px;
			}
			&:nth-child(5n+5) {
				margin-right: 0;
			}
		}
	}
}

#our_movies div.wrap a.movie_itm.last_itm {
	margin-right: 0;
}

#our_movies div.wrap a.movie_itm div.item {
	display: flex;
	flex-direction: column;
	height: 100%;
}

#our_movies div.wrap a.movie_itm div.item .topside {
	padding: 1.214em;
	flex-grow: 1;
}

#our_movies div.wrap a.movie_itm div.item h3 {
	font-size: 1.2857em;
	font-family: "proxima_novaextrabold";
	color: #fff;
	text-transform: uppercase;
	text-decoration: none;
	line-height: 1.1em;
}

#our_movies div.wrap a.movie_itm div.item h3 {
	color: #fff;
	text-decoration: none;
}

#our_movies div.wrap a.movie_itm.itm div.item div.topside div.ratings {
	margin-top: 0.5em;
	display: block;
}

#our_movies div.wrap a.movie_itm div.item a div.img_side {
}

#our_movies div.wrap a.movie_itm div.item a div.img_side img.img-cover_image {
	display: block;
	width: 100%;
}

#our_movies div.wrap div.head div.toggle {
	float: right;
	position: absolute;
	right: 0;
	bottom: 0;
}

#our_movies div.wrap div.head div.toggle span {
	background-color: #2f2f2f;
	color: #fff;
	display: inline-block;
	float: left;
	font-family: "proxima_nova_bold";
	font-size: 1.2857em;
	line-height: 1;
	margin-left: 0.3em;
	padding: 0.95em 1.5em;
	text-transform: uppercase;
	cursor: pointer;
}

#our_movies div.wrap div.head div.toggle span.active {
	background-color: #ea593b;
}

#social_feed {
	background-color: #fff;
	float: left;
	width: 100%;
	display: none;
}

#social_feed div.wrap {
	margin-top: 2em;
	min-height: 16em;
}

#social_feed div.wrap h1 {
	font-size: 3.572em;
	font-family: "proxima_novalight";
	text-transform: uppercase;
	color: #272727;
	margin-bottom: 0.3em;
}

#social_feed div.wrap h1 span {
	font-family: "proxima_novablack";
}

/**************************************Behind the Scenes******************************************************/

#behind_section {
	background-color: #181818;
	float: left;
	width: 100%;
	padding-bottom: 5em;
}

#behind_section div.wrap {
	margin-top: 0;
}

#behind_section div.wrap div#bsl {
	padding-top: 4.8em;
	width: 94.5%;
}

#behind_section div.wrap div#bsl div.item {
}

#behind_section div.wrap div#bsl div.item .topside {
	border-bottom: 0.58em solid #ea593b;
	margin-bottom: 2.8em;
}

#behind_section div.wrap div#bsl div.item h1 {
	color: #fff;
	font-family: "proxima_novablack";
	font-size: 3.571em;
	padding-bottom: 0.2em;
	text-transform: uppercase;
	width: 100%;
}

#behind_section div.wrap div#bsl div.item div.leftside {
	float: left;
	width: 44.2%;
	margin-top: 0.4em;
}

#behind_section div.wrap div#bsl div.item div.leftside a.pop_up {
	display: block;
	max-width: 755px;
	width: 96%;
	position: relative;
	background-color: #000;
}

#behind_section div.wrap div#bsl div.item div.leftside a.pop_up img#video_b {
	display: block;
	width: 100%;
}

#behind_section div.wrap div#bsl div.item div.leftside a.pop_up span#shadow {
	// background: url("images/shadow.png") repeat scroll 0 0 transparent;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

#behind_section div.wrap div#bsl div.item div.leftside a.pop_up span#video_icon {
	// background: url("images/play_btn_big.png") no-repeat scroll center center transparent;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	cursor: pointer;
}

#behind_section div.wrap div#bsl div.item div.rightside {
	color: #aeaeae;
	float: left;
	font-style: italic;
	width: 54%;
}

.behind #more_like_this {
	background-color: #0c0c0c;
	float: left;
	width: 100%;
}

.behind #more_like_this div.wrap {
}

.behind #more_like_this div.wrap div#bcm {
	width: 94.5%;
}

.behind #more_like_this div.wrap div#bcm h1 {
	border-bottom: 0.16em solid #ea593b;
	color: #fff;
	font-family: "proxima_novablack";
	font-size: 3.571em;
	margin-bottom: 1.1em;
	padding-bottom: 0.2em;
	text-transform: uppercase;
	width: 100%;
}

.behind #more_like_this div.wrap div#bcm div.item {
	float: left;
	width: 50%;
	margin-bottom: 3.5em;
}

.behind #more_like_this div.wrap div#bcm div.item div.leftside {
	float: left;
	width: 41%;
}

.behind #more_like_this div.wrap div#bcm div.item div.rightside {
	float: left;
	width: 51%;
	color: #aeaeae;
}

.behind #more_like_this div.wrap div#bcm div.item div.leftside a.pop_up {
	display: block;
	max-width: 340px;
	width: 94%;
	position: relative;
}

.behind #more_like_this div.wrap div#bcm div.item div.leftside a.pop_up span#shadow {
	// background: url("images/shadow.png") repeat scroll 0 0 transparent;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

.behind #more_like_this div.wrap div#bcm div.item div.leftside a.pop_up span#video_icon {
	// background: url("images/play_btn_mid.png") no-repeat scroll center center transparent;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	cursor: pointer;
}

.behind #more_like_this div.wrap div#bcm div.item div.leftside a.pop_up img#video_b {
	width: 100%;
	display: block;
}

.behind #more_like_this div.wrap div#bcm div.item div.rightside h3 {
	font-size: 1.785em;
	font-family: "proxima_novaextrabold";
	color: #fff;
	text-transform: uppercase;
	line-height: 1.2em;
	margin-bottom: 0.5em;
}

.behind #more_like_this div.wrap div#bcm div.item div.rightside h3 a {
	color: #fff;
	text-decoration: none;
}

/**************************************Talent Highlight******************************************************/

#talent_highlight {
	background-color: #181818;
	float: left;
	width: 100%;
	padding-bottom: 5em;
}

#talent_highlight div.wrap {
	margin-top: 0;
}

#talent_highlight div.wrap div#bsl {
	padding-top: 4.8em;
	width: 94.5%;
	margin: 0 auto;
}

#talent_highlight div.wrap div#bsl div.item {
}

#talent_highlight div.wrap div#bsl div.item .topside {
	border-bottom: 0.58em solid #ea593b;
	margin-bottom: 2.8em;
}

#talent_highlight div.wrap div#bsl div.item h1 {
	color: #fff;
	display: inline-block;
	font-family: "proxima_novablack";
	font-size: 3.571em;
	margin-right: 0.65em;
	padding-bottom: 0.2em;
	text-transform: uppercase;
}

#talent_highlight div.wrap div#bsl div.item div.topside span#about {
	color: #d3d3d3;
	font-family: "proxima_nova_regular";
	font-size: 1.428em;
	position: relative;
	top: -0.5em;
}

#talent_highlight div.wrap div#bsl div.item div.leftside {
	float: left;
	width: 44.2%;
}

#talent_highlight div.wrap div#bsl div.item div.leftside span.pop_up {
	display: block;
	max-width: 755px;
	width: 96%;
	position: relative;
	background-color: #000;
}

#talent_highlight div.wrap div#bsl div.item div.leftside span.pop_up img#video_b {
	display: block;
	width: 100%;
}

#talent_highlight div.wrap div#bsl div.item div.leftside span.pop_up span#shadow {
	// background: url("images/shadow.png") repeat scroll 0 0 transparent;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

#talent_highlight div.wrap div#bsl div.item div.leftside span.pop_up span#video_icon {
	// background: url("images/play_btn_big.png") no-repeat scroll center center transparent;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	cursor: pointer;
}

#talent_highlight div.wrap div#bsl div.item div.rightside {
	color: #aeaeae;
	float: left;
	width: 100%;
	font-size: 1.12em;
}

#talent_highlight div.wrap div#bsl div.item div.rightside h1,
#talent_highlight div.wrap div#bsl div.item div.rightside h2,
#talent_highlight div.wrap div#bsl div.item div.rightside h3,
#talent_highlight div.wrap div#bsl div.item div.rightside h4,
#talent_highlight div.wrap div#bsl div.item div.rightside h5 {
	clear: none;
	font-family: "proxima_nova_bold";
	color: #fff;
	line-height: 1.2em;
}

#talent_highlight div.wrap div#bsl div.item div.rightside h1 {
	font-size: 2.4em;
}

#talent_highlight div.wrap div#bsl div.item div.rightside h2 {
	font-size: 1.9em;
}

#talent_highlight div.wrap div#bsl div.item div.rightside h3 {
	font-size: 1.7em;
}

#talent_highlight div.wrap div#bsl div.item div.rightside h4 {
	font-size: 1.5em;
}

#talent_highlight div.wrap div#bsl div.item div.rightside h5 {
	font-size: 1.3em;
}

#talent_highlight div.wrap div#bsl div.item div.rightside img.talent_image {
	display: block;
	float: left;
	margin-right: 1.2em;
	margin-top: 0.6em;
	max-width: 40%;
}

.talent_highlight #more_like_this {
	background-color: #0c0c0c;
	float: left;
	width: 100%;
}

.talent_highlight #more_like_this div.wrap {
}

.talent_highlight #more_like_this div.wrap div#bcm {
	width: 94.5%;
}

.talent_highlight #more_like_this div.wrap div#bcm h1 {
	border-bottom: 0.16em solid #ea593b;
	color: #fff;
	font-family: "proxima_novablack";
	font-size: 3.571em;
	margin-bottom: 1.1em;
	padding-bottom: 0.2em;
	text-transform: uppercase;
	width: 100%;
}

.talent_highlight #more_like_this div.wrap div#bcm div.item {
	float: left;
	width: 50%;
	margin-bottom: 3.5em;
}

.talent_highlight #more_like_this div.wrap div#bcm div.item div.leftside {
	float: left;
	width: 24%;
}

.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside {
	float: left;
	width: 73%;
	color: #aeaeae;
}

.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside #about {
	color: #d3d3d3;
	display: block;
	font-family: "proxima_nova_regular";
	font-size: 1.428em;
	margin-bottom: 0.5em;
}

.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside .short_content {
	display: block;
	font-style: italic;
	margin-bottom: 3.5em;
}

.talent_highlight #more_like_this div.wrap div#bcm div.item div.leftside a.pop_up {
	display: block;
	max-width: 340px;
	width: 94%;
	position: relative;
}

.talent_highlight #more_like_this div.wrap div#bcm div.item div.leftside a.pop_up span#shadow {
	// background: url("images/shadow.png") repeat scroll 0 0 transparent;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

.talent_highlight #more_like_this div.wrap div#bcm div.item div.leftside a.pop_up span#video_icon {
	// background: url("images/play_btn_mid.png") no-repeat scroll center center transparent;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	cursor: pointer;
}

.talent_highlight #more_like_this div.wrap div#bcm div.item div.leftside a.pop_up img#video_b {
	width: 100%;
	display: block;
}

.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside h3 {
	font-size: 2.143em;
	font-family: "proxima_nova_bold";
	color: #fff;
	text-transform: uppercase;
	line-height: 1.2em;
	margin-bottom: 0.5em;
}

.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside h3 a {
	color: #fff;
	text-decoration: none;
}

.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside a.btn {
	margin-bottom: 0;
}

.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside a.btn span.left {
	width: 11em;
}

/********************************************Red Carpet****************************************************************/
#red-carpet-event {
	background-color: #fff;
	float: left;
	padding-bottom: 5.85em;
	padding-top: 1em;
	width: 100%;
}

#red-carpet-event div.wrap {
}

#red-carpet-event div.wrap div.img_side {
	float: left;
	width: 38.5%;
	margin-right: 0.5em;
	background-color: #f8f8f8;
}

#red-carpet-event div.wrap div.img_side img {
	width: 100%;
}

#red-carpet-event div.wrap div.content_side {
	background-color: #f8f8f8;
	float: left;
	padding: 2.28em;
	width: 60%;
	margin-right: 0%;
}

#red-carpet-event div.wrap div.content_side.full {
	width: 68%;
	margin-right: 0.6%;
}

#red-carpet-event div.wrap div.content_side h1 {
	color: #272727;
	font-family: "proxima_nova_bold";
	font-size: 3.572em;
	text-transform: uppercase;
	line-height: 1em;
	margin: 0.5em 0;
}

#red-carpet-event div.wrap div.content_side .date {
	color: #272727;
	font-size: 0.7857em;
	font-family: "proxima_novalight";
	text-transform: uppercase;
}

#red-carpet-event div.wrap div.form_side {
	float: left;
	width: 0%;
	display: none;
}

#red-carpet-event div.wrap div.form_side div.sform {
	background-color: #d44d31;
	padding: 2.2em 2.5em 1.7em;
}

#red-carpet-event div.wrap div.form_side div.sform h1 {
	color: #fff;
	font-family: "proxima_nova_bold";
	font-size: 3.572em;
	line-height: 1em;
	margin-bottom: 0.1em;
	text-transform: uppercase;
}

#red-carpet-event div.wrap div.form_side div.sform div.desc {
	color: #fff;
	margin-bottom: 1.5em;
}

#red-carpet-event div.wrap div.form_side div.sform form#rcs_form div#c_wrapper div.itm {
	margin-bottom: 0.7142em;
}

#red-carpet-event div.wrap div.form_side div.sform form#rcs_form div#c_wrapper div.itm input.inout {
	border: 1px solid transparent;
	border-radius: 0;
	font-family: "proxima_nova_regular";
	height: 4.25em;
	line-height: 4.25em;
	padding: 0 1em 0 3.9em;
	width: 100%;
	color: #747474;
	background-color: #fff;
}

// #red-carpet-event div.wrap div.form_side div.sform form#rcs_form div#c_wrapper div.itm input.inout.name{
// 	background: url("images/form_icon/1.png") no-repeat scroll 1.25em center #fff;
// }

// #red-carpet-event div.wrap div.form_side div.sform form#rcs_form div#c_wrapper div.itm input.inout.email{
// 	background: url("images/form_icon/2.png") no-repeat scroll 1.25em center #fff;
// }

// #red-carpet-event div.wrap div.form_side div.sform form#rcs_form div#c_wrapper div.itm input.inout.phone{
// 	background: url("images/form_icon/3.png") no-repeat scroll 1.5em center #fff;
// }

#red-carpet-event div.wrap div.form_side div.sform form#rcs_form div#c_wrapper div.itm textarea {
	border: 1px solid transparent;
	border-radius: 0;
	font-family: "proxima_nova_regular";
	height: 4.25em;
	padding: 1.2em 1em 0 3.9em;
	width: 100%;
	color: #747474;
	// background: url("images/form_icon/4.png") no-repeat scroll 1.5em 1.5em #fff;
	height: 6.14em;
	resize: none;
}

#red-carpet-event div.wrap div.form_side div.sform form#rcs_form div#c_wrapper div.submit-holder .btn span.left {
	padding: 0 2.5em;
	text-align: left;
	width: 14.8em;
}

/**************************************Single Product****************************************************/

.single-product #page.site {
	background-color: #fff;
}

.product_single #product_section {
	position: relative;
	background-color: #0c0c0c;
}

.product_single div.img_ext {
	height: 588px;
	opacity: 0.7;
	text-align: center;
}

.product_single div.img_ext div.panoram_img {
	float: left;
	height: 100%;
	width: 100%;
}

.product_single #product_section .content_side {
	left: 0;
	position: absolute;
	top: 5.5em;
	width: 100%;
}

.product_single #product_section .content_side .wrap {
	margin: 0 auto;
	position: relative;
	width: 96%;
	max-width: 1800px;
	position: relative;
}

.product_single #product_section .content_side .wrap .ext {
	// background: transparent url("images/single_bg_top.png") repeat scroll 0 0;
	float: left;
	height: 100%;
	padding: 3em 3em 2em;
	width: 100%;
}

.product_single #product_section div.content_side .wrap .image_side {
	float: left;
	margin-right: 4%;
	width: 14.85%;
	max-width: 20em;
	margin-top: 1.5em;
}

.product_single #product_section div.content_side .wrap .image_side .ext_img {
}

.product_single #product_section div.content_side .wrap .image_side .ext_img img.panoram_image {
	width: 100%;
}

.product_single #product_section div.content_side div.wrap div.right_side {
	float: left;
	width: 81.15%;
	position: relative;
}

.product_single #product_section div.content_side div.wrap div.right_side .topside {
	border-bottom: 1px solid #262626;
	margin-bottom: 2em;
}

.product_single #product_section div.content_side div.wrap div.right_side h1 {
	color: #fff;
	font-family: "proxima_nova_bold";
	font-size: 3.571428em;
	text-transform: uppercase;
	margin-right: 250px;
}

.product_single #product_section div.content_side div.wrap div.right_side h1 span {
	color: #747474;
}

.product_single #product_section div.content_side div.wrap div.right_side div.top_content_left {
	float: left;
	margin-right: 0;
	width: 76%;
	margin-bottom: 1.5em;
}

.product_single #product_section div.content_side div.wrap div.right_side div.m_item {
	color: #a9a9a9;
	font-family: "proxima_novalight";
	font-size: 1.143em;
	margin-bottom: 1.8em;
	width: 100%;
}

.product_single #product_section div.content_side div.wrap div.right_side div.m_item h4 {
	font-size: 1.0625em;
	font-family: "proxima_novaextrabold";
	color: #fff;
	text-transform: uppercase;
}

.product_single #product_section div.content_side div.wrap div.right_side div.pg {
	display: inline-block;
	position: absolute;
	right: -1em;
	top: -1em;
}

.product_single #product_section div.content_side div.wrap div.right_side div.pg .copy {
	color: #fff;
	display: inline-block;
	float: left;
	margin-right: 1.2em;
	font-size: 0.786em;
	font-family: "proxima_novalight";
}

.product_single #product_section div.content_side div.wrap div.right_side div.pg span.obi {
	display: inline-block;
}

.product_single #product_section div.content_side div.wrap div.right_side div.bottom_side {
	float: left;
	width: 100%;
	margin-top: 0.4em;
}

.product_single #product_section div.content_side div.wrap div.right_side div.bottom_side div.treiler {
	display: inline-block;
	float: left;
	margin-right: 1em;
}

.product_single #product_section div.content_side div.wrap div.right_side div.bottom_side a.btn {
	font-size: 1em;
}

.product_single #product_section div.content_side div.wrap div.right_side div.bottom_side div.watch a.btn span.left {
	width: 11em;
}

.product_single #product_section div.content_side div.wrap div.right_side div.bottom_side div.buy a.btn span.left {
	width: 8em;
}

.product_single #product_section .btn span.left {
	padding: 0 1em;
	width: 14em;
}

.product_single #product_section div.content_side div.wrap div.right_side div.bottom_side div.buy {
	display: inline-block;
	float: left;
	margin-right: 3em;
}

.product_single #product_section a.btn {
	background: none repeat scroll 0 0 transparent;
	border: medium none;
	border-radius: 0;
	box-shadow: none;
	padding: 0;
	text-shadow: none;
}

.product_single #product_section div.bottom_side div.price {
	display: inline-block;
	float: left;
	margin-right: 3em;
	margin-bottom: 2em;
}

.product_single #product_section div.bottom_side div.price p.price {
	color: #fff;
}

.product_single #product_section div.bottom_side div.price p.price span.heads {
	display: block;
	font-size: 1.4285em;
	font-family: "Myriad Pro";
	text-transform: uppercase;
	line-height: 1;
	text-align: center;
}

.product_single #product_section div.bottom_side div.price p.price span.amount {
	font-size: 2.2857em;
	font-family: "proxima_novaextrabold";
	line-height: 1;
}

#more_product {
	background-color: #fff;
	float: left;
	width: 100%;
	margin-bottom: 4em;
}

#more_product div.wrap {
}

#more_product div.wrap h1 {
	font-family: "proxima_novalight";
	font-size: 3.571em;
	text-transform: uppercase;
	color: #272727;
	margin-bottom: 0.3em;
}

#more_product div.wrap h1 span {
	font-family: "proxima_novablack";
}

#more_product div.wrap div.product-item {
	float: left;
	margin-bottom: 1.6em;
	margin-right: 0.7em;
	width: 32.5%;
}

#more_product div.wrap div.product-item div.leftside {
	float: left;
	margin-right: 1%;
	max-width: 178px;
	width: 32%;
}

#more_product div.wrap div.product-item div.leftside div.ext_img {
}

#more_product div.wrap div.product-item div.leftside div.ext_img img.product_image {
	display: block;
	width: 100%;
}

#more_product div.wrap div.product-item div.rightside {
	background-color: #f8f8f8;
	float: left;
	padding: 1.1em;
	width: 67%;
}

#more_product div.wrap div.product-item div.rightside h3 {
	font-family: "proxima_nova_bold";
	font-size: 1.7em;
	text-transform: uppercase;
	color: #272727;
	margin-bottom: 0.2em;
	line-height: 1.1em;
}

#more_product div.wrap div.product-item div.rightside h3 a {
	color: #272727;
	text-decoration: none;
}

#more_product div.wrap div.product-item div.rightside .short_content {
	float: left;
	margin-bottom: 3em;
	width: 100%;
}

#more_product div.wrap div.product-item div.rightside div.price {
	display: inline-block;
	float: left;
	margin-right: 1em;
}

#more_product div.wrap div.product-item div.rightside div.price p.price {
}

#more_product div.wrap div.product-item div.rightside div.price p.price span.heads {
	color: #029e93;
	display: block;
	font-family: "proxima_nova_bold";
	font-size: 1.1428em;
	text-transform: uppercase;
	line-height: 1.4em;
}

#more_product div.wrap div.product-item div.rightside div.price p.price span.amount {
	color: #000;
	display: block;
	font-family: "proxima_nova_bold";
	font-size: 1.714285em;
	text-transform: uppercase;
	line-height: 1;
}

#more_product div.wrap div.product-item div.rightside div.buy {
	display: inline-block;
}

#more_product div.wrap div.product-item div.rightside a.btn {
	background: none repeat scroll 0 0 transparent;
	border: medium none;
	border-radius: 0;
	box-shadow: none;
	padding: 0;
	text-shadow: none;
}

#more_product div.wrap div.product-item div.rightside div.buy .btn span.left {
	padding: 0 0.2em;
	width: 7em;
}

/*************************************Store***********************************************/
#product_side {
	background-color: #fff;
	float: left;
	width: 100%;
	margin-bottom: 5.4em;
}

#product_side div.wrap {
	margin-top: 4.4em;
}

#product_side div.wrap h1 {
	font-family: "proxima_novalight";
	font-size: 3.571em;
	text-transform: uppercase;
	color: #272727;
	margin-bottom: 0.3em;
}

#product_side div.wrap h1 span {
	font-family: "proxima_novablack";
}

#product_side div.wrap div.product-item {
	float: left;
	width: 33%;
	margin-bottom: 1.6em;
}

#product_side div.wrap div.product-item div.leftside {
	float: left;
	margin-right: 2%;
	max-width: 12.714em;
	width: 31.6%;
	height: 18.857em;
}

#product_side div.wrap div.product-item div.leftside div.ext_img {
}

#product_side div.wrap div.product-item div.leftside div.ext_img img.product_image {
	display: block;
	width: 100%;
}

#product_side div.wrap div.product-item div.rightside {
	background-color: #f8f8f8;
	float: left;
	height: 18.857em;
	padding: 1.1em;
	width: 66.4%;
}

#product_side div.wrap div.product-item div.rightside h3 {
	font-family: "proxima_nova_bold";
	font-size: 1.7857em;
	text-transform: uppercase;
	color: #272727;
	margin-bottom: 0.2em;
	line-height: 1.1em;
}

#product_side div.wrap div.product-item div.rightside h3 a {
	color: #272727;
	text-decoration: none;
}

#product_side div.wrap div.product-item div.rightside .short_content {
	float: left;
	margin-bottom: 3em;
	width: 100%;
}

#product_side div.wrap div.product-item div.rightside div.price {
	display: inline-block;
	float: left;
	margin-right: 1em;
}

#product_side div.wrap div.product-item div.rightside div.price p.price {
}

#product_side div.wrap div.product-item div.rightside div.price p.price span.heads {
	color: #029e93;
	display: block;
	font-family: "proxima_nova_bold";
	font-size: 1.1428em;
	text-transform: uppercase;
	line-height: 1.4em;
}

#product_side div.wrap div.product-item div.rightside div.price p.price span.amount {
	color: #000;
	display: block;
	font-family: "proxima_nova_bold";
	font-size: 1.714285em;
	text-transform: uppercase;
	line-height: 1;
}

#product_side div.wrap div.product-item div.rightside div.buy {
	display: inline-block;
}

#product_side div.wrap div.product-item div.rightside a.btn {
	background: none repeat scroll 0 0 transparent;
	border: medium none;
	border-radius: 0;
	box-shadow: none;
	padding: 0;
	text-shadow: none;
}

#product_side div.wrap div.product-item div.rightside div.buy .btn span.left {
	padding: 0 0.5em;
	width: 7.5em;
}

/***************************************Page************************************************/

.page #content.site-content div.content-area {
	background-color: #e1e1e1;
}

.page #content.site-content div.content-area .wrap .page {
	padding: 5.8em 0;
}

.page #content.site-content div.content-area .wrap .page .entry-header {
	border-bottom: 1px solid #d7d7d7;
	margin-bottom: 2.4em;
	width: 100%;
}

.page #content.site-content div.content-area .wrap .page .entry-header h1.entry-title {
	color: #272727;
	font-family: "proxima_nova_bold";
	font-size: 3.571428em;
	text-transform: uppercase;
	margin-bottom: 0.2em;
}

.blur {
	filter: blur(3px);
	transition: all 0.3s ease 0s;
}

#overlay {
	background-color: rgba(0, 0, 0, 0.8);
	bottom: 0;
	display: block;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 9999;
}

#video-box {
	background-color: #000;
	position: absolute;
	right: 0;
	top: 0;
	width: 42.5em;
	height: 30em;
}

.video_box_close {
	// background: url("images/ico_close_btn.png") no-repeat scroll left top transparent;
	display: block;
	height: 28px;
	position: absolute;
	right: -40px;
	top: 0;
	width: 27px;
}

/*****************************************************************************************************/

#content.site-content div.news_archive {
	background-color: #ececec;
	float: left;
	width: 100%;
}

.page #content.site-content div.content-area.news {
	background-color: #ececec;
	margin-top: 1.5em;
}

.page #content.site-content div.content-area {
	background-color: #e1e1e1;
}

.site-content .news_archive div.wrap {
	margin-top: 4em;
}

.news_archive div.wrap #primary .entry-header h2.entry-title {
	border-bottom: 1px solid #dfdfdf;
	color: #272727;
	font-family: "proxima_nova_bold";
	font-size: 3.5em;
	padding-bottom: 0.18em;
	text-transform: uppercase;
}

.news_archive div.wrap #primary .entry-header h2.entry-title a {
	color: #272727;
	text-decoration: none;
}

.news_archive div.wrap #primary .entry-header div.entry-meta {
	color: #909090;
	font-family: "proxima_novalight";
	font-size: 0.95em;
	margin: 0.8em 0 1em;
}

.news_archive div.wrap #primary .site-main div.news-item {
	float: left;
	margin-bottom: 4em;
	width: 100%;
}

.news_archive div.wrap #primary .site-main .post-navigation {
	float: left;
	margin: 0 0 3em;
	text-align: center;
	width: 100%;
}

.news_archive div.wrap #primary .site-main .post-navigation .page-numbers {
	background-color: #fff;
	display: inline-block;
	margin-right: 0.5em;
	padding: 0.23em 0.73em;
}

.news_archive div.wrap #primary .site-main .post-navigation a.page-numbers {
	text-decoration: none;
	color: #4f4f4f;
}

.news_archive div.wrap #primary .site-main .post-navigation a.page-numbers:hover {
	color: #029e93;
}

.archive .page-header h1.page-title {
	border-bottom: 1px solid #dfdfdf;
	color: #272727;
	font-family: "proxima_nova_bold";
	font-size: 3.5em;
	padding-bottom: 0.18em;
	text-transform: uppercase;
	margin-top: 0.45em;
	margin-bottom: 1em;
}

.news_archive div.wrap #primary .entry-header h2.entry-title {
	font-size: 2.5em;
}

.error404 div#content.site-content {
	background-color: #ececec;
}

.error404 .site-content div.content-area .site-main div.wrap {
	margin-top: 5em;
	margin-bottom: 15em;
}

.error404 .site-content div.content-area .site-main div.wrap .page-header h1.page-title {
	border-bottom: 1px solid #dfdfdf;
	color: #272727;
	font-family: "proxima_nova_bold";
	font-size: 3.5em;
	padding-bottom: 0.18em;
	text-transform: uppercase;
	margin-top: 0.45em;
	margin-bottom: 1em;
}

.movies_single #rating_form.rating_form {
	display: none;
}

.mfp-content div#rating_form {
	background: #fff none repeat scroll 0 0;
	margin: 40px auto;
	max-width: 1000px;
	padding: 20px 30px;
	position: relative;
	text-align: left;
}

.mfp-content div#rating_form .mfp-close {
	// background: transparent url("images/close_btn_rating.jpg") no-repeat scroll left top;
	box-shadow: none;
	display: block;
	height: 32px;
	opacity: 1;
	position: absolute;
	right: -5px;
	text-indent: -9999px;
	text-shadow: none;
	top: -5px;
	width: 31px;
}
.mfp-content div#rating_form form.rate-form h1 {
	color: #0c0c0c;
	font-family: "proxima_novablack";
	font-size: 3.57143em;
	text-transform: uppercase;
}

.mfp-content div#rating_form form.rate-form div.rateit {
	float: left;
	margin-right: 1.5em;
	margin-top: 1em;
}

.mfp-content div#rating_form form.rate-form div.rate_text {
	color: #717171;
	float: left;
	font-family: "proxima_nova_regular";
	font-size: 1.428em;
	margin: 0.5em 0 1.5em;
}

.mfp-content div#rating_form form.rate-form textarea#comment {
	// background: url("images/form_icon/4.png") no-repeat scroll 1.5em 1.5em #fff;
	border: 1px solid #cfcfcf;
	border-radius: 0;
	color: #747474;
	font-family: "proxima_nova_regular";
	height: 8.5em;
	padding: 1.3em 1em 0 3.9em;
	resize: none;
	width: 100%;
}

.mfp-content div#rating_form form.rate-form input {
	background-color: #fff;
	border: 1px solid #cfcfcf;
	border-radius: 0;
	color: #747474;
	float: left;
	font-family: "proxima_nova_regular";
	height: 3.8em;
	line-height: 3.8em;
	margin-right: 1em;
	padding: 0 1em 0 3.9em;
	width: 35%;
}

// .mfp-content div#rating_form form.rate-form input#author{
// 	background: url("images/form_icon/1.png") no-repeat scroll 1.25em center #fff;
// }

// .mfp-content div#rating_form form.rate-form input#email{
// 	background: url("images/form_icon/2.png") no-repeat scroll 1.25em center #fff;
// }

.mfp-content div#rating_form form.rate-form button.btn.form {
	left: 0em;
	top: 0em;
}

#movies_section div.mobile_res,
#product_section div.mobile_res {
	display: none;
}

#movies_section div.ratings_sm {
	margin: 30px auto;
	text-align: center;
	width: 100%;
}

#movies_section div.rateit_sm {
	height: 16px;
	line-height: 1;
	top: 3px;
	margin-right: 8px;
}

#movies_section div.ratings_sm .read_rew {
	color: #fff;
	display: inline-block;
	font-family: "proxima_nova_regular";
	font-size: 12px;
	line-height: 1;
}

.movies_single .movies #rating_form_listing {
	display: none;
}

.mfp-content #rating_form_listing {
	background: #fff none repeat scroll 0 0;
	margin: 40px auto;
	max-width: 1000px;
	padding: 60px;
	position: relative;
	text-align: left;
}

.mfp-content #rating_form_listing .mfp-close {
	// background: transparent url("images/rating_listing_close.png") no-repeat scroll left top;
	box-shadow: none;
	display: block;
	height: 34px;
	opacity: 1;
	position: absolute;
	right: -12px;
	text-indent: -9999px;
	text-shadow: none;
	top: -12px;
	width: 34px;
}

.mfp-content #rating_form_listing .item {
	border-bottom: 1px solid #f1f1f1;
	margin-bottom: 30px;
	padding-bottom: 20px;
}

.mfp-content #rating_form_listing .item h2 {
	font-size: 26px;
	color: #373737;
	text-transform: uppercase;
	font-family: "proxima_nova_regular";
}

.mfp-content #rating_form_listing .item .r_stars {
	float: left;
	width: 100%;
	margin-bottom: 10px;
}

.mfp-content #rating_form_listing .item .r_stars span.rating-star {
	// background: transparent url("images/star_passive.png") no-repeat scroll 0 0;
	display: block;
	float: left;
	height: 16px;
	width: 16px;
}

// .mfp-content #rating_form_listing .item .r_stars span.rating-star.active{
// 	background: transparent url("images/star_active.png") no-repeat scroll 0 0;
// }

.mfp-content #rating_form_listing .item div.content {
	color: #787878;
	font-family: "proxima_novalight";
	font-size: 17px;
}

.mfp-content #rating_form_listing .item div.content p {
	margin-bottom: 10px;
}

.mfp-content #rating_form_listing .item div.author {
	font-size: 17px;
	color: #787878;
	font-family: "proxima_nova_regular";
}

/******************************Subscription*********************************************/

.page #content.site-content div.content-area.subscription_confirmation {
	background-color: #fff;
	margin: 0 auto;
	max-width: 600px;
}

.subscription_confirmation #subscription_header {
	background-color: #000;
	height: 110px;
	width: 100%;
}

#subscription_header div.site-branding {
	text-align: center;
}

#subscription_header div.site-branding h1.site-title {
	display: inline-block;
	position: relative;
	top: 18px;
}

.subscription_confirmation #subscription_content {
	padding: 48px;
	width: 100%;
	position: relative;
	min-height: 320px;
}

.subscription_confirmation #subscription_content span#date {
	color: #7b7b7b;
	font-family: "proxima_nova_regular";
	font-size: 11px;
	position: absolute;
	right: 48px;
	text-transform: uppercase;
	top: 56px;
}

.subscription_confirmation #subscription_content h3 {
	font-family: "proxima_novalight";
	font-size: 35px;
}

.subscription_confirmation #subscription_content p {
	font-family: "proxima_novalight";
	font-size: 20px;
	line-height: 1.25em;
	margin-bottom: 20px;
}

.subscription_confirmation #subscription_content p.bld {
	font-family: "proxima_nova_bold";
}

.subscription_confirmation #subscription_content p.btm {
	margin-top: 60px;
}

.subscription_confirmation #subscription_footer {
	background-color: #f8f8f8;
	height: 155px;
	width: 100%;
	text-align: center;
}

.subscription_confirmation #subscription_footer div#top_social {
	display: inline-block;
	padding-top: 30px;
	margin-bottom: 8px;
}

.subscription_confirmation #subscription_footer div#top_social span.ext_sl {
	display: inline-block;
	margin-right: 4px;
}

.subscription_confirmation #subscription_footer div#top_social span.ext_sl a {
	display: block;
}

.subscription_confirmation #subscription_footer div#top_social span.ext_sl a img {
	display: block;
}

.subscription_confirmation #subscription_footer div#copy {
	color: #747474;
	font-size: 12px;
	font-family: "proxima_novalight";
}

.site-footer .site-info .bottom_menu {
}

.site-footer .site-info .bottom_menu .menu-bottom_menu-container ul {
	list-style: outside none none;
	margin: 0;
	padding: 0;
}

.site-footer .site-info .bottom_menu .menu-bottom_menu-container ul li {
	display: inline-block;
	// background: url("images/menu_bottom_razd.png") no-repeat scroll center right transparent;
	padding: 0 8px 0 3px;
}

.site-footer .site-info .bottom_menu .menu-bottom_menu-container ul li.last {
	background: none;
	padding: 0 0 0 3px;
}

.site-footer .site-info .bottom_menu .menu-bottom_menu-container ul li a {
	color: #747474;
	font-family: "proxima_novalight";
	font-size: 0.85em;
	margin-top: 0.5em;
}

.site-footer .site-info .bottom_menu .menu-bottom_menu-container ul li a:hover {
	text-decoration: none;
}

/****************************(min-width: 1360px) and (max-width: 1979px)***************************************/

@media screen and (min-width: 1360px) and (max-width: 1979px) {
	#homeslider .slider-for .slide_item .wrap {
		width: 94%;
	}

	#homeslider .slider-for .slide_item .wrap h1 {
		bottom: 50%;
		font-size: 3em;
		width: 80%;
	}

	#homeslider .slider-for .slide_item .content_side div.wrap div.buttons {
		bottom: 24%;
	}

	#newsletter div.wrap {
		width: 1200px;
	}

	#newsletter div.wrap div.art {
		font-size: 3em;
		padding: 1em 0.8em 1em 0;
	}

	#newsletter div.wrap div.newsletter {
		margin-top: 1.8em;
	}

	#newsletter div.wrap div.newsletter div.head,
	#newsletter div.wrap div.singup_facebook div.head {
		font-size: 1.8em;
	}

	#newsletter div.wrap div.newsletter form input.newsletter-email {
		width: 21em;
	}

	#newsletter .btn span.left {
		width: 9em;
	}

	#newsletter span.or {
		margin: 3.5em 1em 0;
	}

	#newsletter div.wrap div.singup_facebook {
		margin-top: 1.8em;
	}

	#our_movies div.wrap div.head div.toggle span {
		font-size: 1.1em;
	}

	#talebeh .talent div.item div.leftside {
		max-width: 185px;
		width: 29%;
		margin-top: 0.8em;
	}

	#talebeh .talent div.item div.rightside {
		width: 68%;
	}

	#store div.wrap .wrap_store {
		margin-right: 1em;
		width: 40.5%;
	}

	#store div.wrap div.product-item div.rightside a.store_link {
		font-size: 0.92em;
		margin: 1.5em 0 1.5em 0.7em;
	}

	#store div.wrap div.store_form {
		width: 58%;
	}

	#store div.wrap div.store_form h1 {
		font-size: 2.1em;
	}

	#store div.wrap div.store_form div.form_side {
		height: 21.4em;
		padding: 1em 2.28em;
	}

	#store div.wrap div.store_form div.form_side .desc {
		font-size: 1.2em;
		margin: 0.2em 0 1em;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper input.inout {
		width: 49%;
	}

	button.btn.form {
		top: 0.5em;
	}

	#red_carpet div.wrap div.item div.img_side {
		float: left;
		width: 39%;
	}

	#red_carpet div.wrap div.item div.content_side {
		background-color: #f8f8f8;
		float: left;
		padding: 1em 1.4em 1.4em;
		width: 61%;
		min-height: 220px;
	}

	#partners {
		height: 18em;
	}

	#partners div.wrap div.head {
		font-size: 3em;
		width: 8em;
	}

	#partners div.wrap div.sponsor.s1 {
		width: 16em;
	}

	#partners div.wrap div.sponsor.s2 {
		width: 10em;
	}

	#partners div.wrap div.sponsor.s3 {
		width: 16em;
	}

	#partners div.wrap div.sponsor.s4 {
		width: 16em;
		margin-left: 2em;
		margin-top: 5.9em;
	}

	.site-footer {
		height: 25em;
	}

	.site-footer div.site-info {
		margin-top: 5em;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side h1 {
		font-size: 2.8em;
	}

	#movies_section div.content_side div.wrap div.right_side div.pg {
		right: -1em;
		top: -1em;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.leftside {
		margin-right: 1em;
		max-width: 185px;
		width: 29%;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside {
		width: 67%;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside .short_content {
		margin-bottom: 1em;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside .short_content p {
		margin-bottom: 1em;
	}

	#more_product div.wrap div.product-item div.rightside .short_content {
		margin-bottom: 1em;
	}

	.product_single #product_section div.content_side div.wrap div.right_side h1 {
		font-size: 2.8em;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.pg {
		right: -1em;
		top: -1em;
	}
}

/******************(min-width: 1280px) and (max-width: 1359px)***********************/

@media screen and (min-width: 1280px) and (max-width: 1359px) {
	#homeslider .slider-for .slide_item .wrap {
		width: 94%;
	}

	#homeslider .slider-for .slide_item .wrap h1 {
		bottom: 29%;
		font-size: 3em;
		width: 80%;
	}

	#homeslider .slider-for .slide_item .content_side div.wrap div.buttons {
		bottom: 20%;
	}

	#newsletter div.wrap div.art span#bld {
		display: block;
	}

	#newsletter div.wrap {
		width: 1200px;
	}

	#newsletter div.wrap div.art {
		font-size: 3em;
		padding: 1em 0.8em 1em 0;
		text-align: center;
	}

	#newsletter div.wrap span.group {
		display: block;
		float: left;
		margin: 0 auto 3em;
		position: relative;
	}

	#newsletter div.wrap div.newsletter {
		display: block;
		margin: 2em auto 0;
		position: relative;
		width: 440px;
	}

	#newsletter div.wrap div.newsletter div.head,
	#newsletter div.wrap div.singup_facebook div.head {
		font-size: 1.8em;
	}

	#newsletter div.wrap div.newsletter form input.newsletter-email {
		width: 21em;
	}

	#newsletter .btn span.left {
		width: 9em;
	}

	#newsletter span.or {
		margin: 3.5em 1em 0;
	}

	#newsletter div.wrap div.singup_facebook {
		margin-top: 1.8em;
	}

	#latest_article .wrap {
		margin-top: 3em;
	}

	#latest_article h1 {
		font-size: 3em;
	}

	#latest_article span#stay_connected {
		font-size: 1.2em;
		top: 1.2em;
	}

	#latest_article div.wrap div.borderline {
		margin: 2.5em 0 3.2em;
		width: 96%;
	}

	#our_movies div.wrap h1 {
		font-size: 3em;
	}

	#our_movies div.wrap div.head div.toggle span {
		font-size: 1.1em;
	}

	#our_movies div.wrap a.movie_itm div.item h3 {
		font-size: 1.3em;
	}

	#social_feed div.wrap h1 {
		font-size: 3em;
	}

	#talebeh .talent h1 {
		font-size: 3em;
	}

	#talebeh .talent div.item div.rightside #about {
		font-size: 1.2em;
	}

	#talebeh .talent div.item div.leftside {
		max-width: 185px;
		width: 29%;
	}

	#talebeh .talent div.item div.rightside {
		width: 68%;
	}

	#talebeh .behinds h1 {
		font-size: 3em;
	}

	#store div.wrap .wrap_store {
		margin-right: 1.5%;
		width: 100%;
	}

	#store div.wrap h1 {
		font-size: 3em;
	}

	#store div.wrap div.product-item div.rightside a.store_link {
		font-size: 0.9em;
		margin: 1.5em 0 0 0.5em;
	}

	#store div.wrap div.store_form {
		width: 48.5%;
	}

	#store div.wrap div.store_form h1 {
		font-size: 1.8em;
		line-height: 1.35em;
	}

	#store div.wrap div.store_form div.form_side {
		height: 18.7em;
		padding: 1em 1.5em;
	}

	#store div.wrap div.store_form div.form_side .desc {
		font-size: 1.2em;
		line-height: 1.2em;
		margin: 0.2em 0 0.5em;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper div.itm textarea.comment {
		width: 100%;
		height: 6em;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper input.inout {
		width: 49%;
		height: 3.2em;
		line-height: 3.2em;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper input.inout.email {
		margin-right: 0;
	}

	button.btn.form {
		left: 0em;
		top: 0.5em;
	}

	#idea_form .btn.form span.left {
		font-size: 0.8em;
		height: 3.5em;
		line-height: 3.5em;
		padding: 0 0.5em;
		width: 12em;
	}

	#idea_form .btn.form span.right {
		font-size: 0.8em;
		height: 3.5em;
		line-height: 3.5em;
	}

	#red_carpet div.wrap h1 {
		font-size: 3em;
	}

	#red_carpet div.wrap div.item div.content_side h3 {
		font-size: 1.4em;
	}

	#red_carpet div.wrap div.item div.img_side {
		float: left;
		width: 39%;
	}

	#red_carpet div.wrap div.item div.content_side {
		background-color: #f8f8f8;
		float: left;
		padding: 1em 1.4em 1.4em;
		width: 61%;
		min-height: 220px;
	}

	#red_carpet div.wrap div.item div.content_side div.link {
		margin-top: 1em;
	}

	#red_carpet div.wrap div.item div.content_side div.link a.more {
		font-size: 1em;
	}

	#partners {
		height: 16em;
	}

	#partners div.wrap div.head {
		font-size: 3em;
		width: 8em;
	}

	#partners div.wrap div.sponsor {
		margin-left: 2em;
	}

	#partners div.wrap div.sponsor.s1 {
		width: 14em;
	}

	#partners div.wrap div.sponsor.s2 {
		width: 9em;
	}

	#partners div.wrap div.sponsor.s3 {
		width: 14em;
	}

	#partners div.wrap div.sponsor.s4 {
		width: 14em;
		margin-left: 2em;
		margin-top: 6em;
	}

	.site-footer {
		height: 20em;
	}

	.site-footer div.site-info {
		margin-top: 4em;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side h1 {
		font-size: 2.8em;
	}

	#movies_section div.content_side div.wrap div.right_side div.pg {
		top: -1em;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.leftside {
		margin-right: 1em;
		max-width: 185px;
		width: 29%;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside {
		width: 67%;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside .short_content {
		margin-bottom: 1em;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside .short_content p {
		margin-bottom: 1em;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside #about {
		font-size: 1.2em;
	}

	#more_product div.wrap div.product-item div.rightside .short_content {
		margin-bottom: 1em;
	}

	.product_single #product_section div.content_side div.wrap div.right_side h1 {
		font-size: 2.8em;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.pg {
		top: -1em;
	}

	#more_product div.wrap h1 {
		font-size: 3em;
	}

	#more_product div.wrap div.product-item {
		margin-right: 1%;
		width: 49%;
	}

	#behind_section div.wrap div#bsl div.item h1,
	.behind #more_like_this div.wrap div#bcm h1 {
		font-size: 3em;
	}

	#product_side div.wrap div.product-item {
		margin-right: 1%;
		width: 49%;
	}
}

/*****************(min-width: 1024px) and (max-width: 1279px)**************************/

@media screen and (min-width: 1024px) and (max-width: 1279px) {
	#homeslider .slider-for .slide_item .wrap {
		width: 94%;
	}

	#homeslider .slider-for .slide_item .wrap h1 {
		bottom: 45%;
		font-size: 3em;
		line-height: 1.1em;
		width: 70%;
	}

	#homeslider .slider-for .slide_item .content_side div.wrap div.buttons {
		bottom: 20%;
	}

	#newsletter div.wrap div.art span#bld {
		display: block;
	}

	#newsletter div.wrap {
		width: 1000px;
	}

	#newsletter div.wrap div.art {
		font-size: 2.5em;
		padding: 1.3em 0.8em 1.3em 0;
		text-align: center;
	}

	#newsletter div.wrap span.group {
		display: block;
		margin: 0 auto;
		position: relative;
		width: 100%;
	}

	#newsletter div.wrap div.newsletter {
		display: block;
		margin: 2em auto 0;
		position: relative;
		width: 440px;
	}

	#newsletter div.wrap div.newsletter div.head,
	#newsletter div.wrap div.singup_facebook div.head {
		font-size: 1.5em;
	}

	#newsletter div.wrap div.newsletter form input.newsletter-email {
		width: 21em;
	}

	#newsletter .btn span.left {
		width: 9em;
	}

	#newsletter span.or {
		margin: 3.5em 1em 0;
	}

	#newsletter div.wrap div.singup_facebook {
		margin-top: 2em;
	}

	#latest_article .wrap {
		margin-top: 3em;
	}

	#latest_article h1 {
		font-size: 3em;
	}

	#latest_article span#stay_connected {
		font-size: 1.2em;
		top: 1.2em;
	}

	#latest_article div.wrap div.borderline {
		margin: 2.5em 0 3.2em;
		width: 96%;
	}

	#our_movies div.wrap h1 {
		font-size: 3em;
	}

	#our_movies div.wrap div.head div.toggle span {
		font-size: 1.1em;
	}

	#our_movies div.wrap .movie_itm div.item h3 {
		font-size: 1em;
	}

	#social_feed div.wrap h1 {
		font-size: 3em;
	}

	#talebeh .talent h1 {
		font-size: 3em;
	}

	#talebeh .talent div.item div.rightside #about {
		font-size: 1.2em;
	}

	#talebeh .talent div.item div.leftside {
		max-width: 185px;
		width: 29%;
	}

	#talebeh .talent div.item div.rightside {
		width: 68%;
	}

	#talebeh .behinds h1 {
		font-size: 3em;
	}

	#store div.wrap .wrap_store {
		margin-right: 1.5%;
		width: 100%;
	}

	#store div.wrap h1 {
		font-size: 3em;
	}

	#store div.wrap div.product-item div.rightside a.store_link {
		font-size: 0.8em;
		margin: 1.5em 0 0 0.5em;
	}

	#store div.wrap div.store_form {
		width: 48.5%;
	}

	#store div.wrap div.store_form h1 {
		font-size: 1.8em;
		line-height: 1.35em;
	}

	#store div.wrap div.store_form div.form_side {
		height: 18.7em;
		padding: 1em 1.5em;
	}

	#store div.wrap div.store_form div.form_side .desc {
		font-size: 1.2em;
		line-height: 1.2em;
		margin: 0.2em 0 0.5em;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper div.itm textarea.comment {
		width: 100%;
		height: 6em;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper input.inout {
		width: 17.2em;
		height: 3.2em;
		line-height: 3.2em;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper input.inout.email {
		margin-right: 0;
	}

	button.btn.form {
		left: 0em;
		top: 0.5em;
	}

	#idea_form .btn.form span.left {
		font-size: 0.8em;
		height: 3.5em;
		line-height: 3.5em;
		padding: 0 0.5em;
		width: 12em;
	}

	#idea_form .btn.form span.right {
		font-size: 0.8em;
		height: 3.5em;
		line-height: 3.5em;
	}

	#red_carpet div.wrap h1 {
		font-size: 3em;
	}

	#red_carpet div.wrap div.item div.content_side h3 {
		font-size: 1.4em;
	}

	#red_carpet div.wrap div.item div.img_side {
		float: left;
		width: 39%;
	}

	#red_carpet div.wrap div.item div.content_side {
		background-color: #f8f8f8;
		float: left;
		padding: 1em 1.4em 1.4em;
		width: 61%;
		min-height: 220px;
	}

	#red_carpet div.wrap div.item div.content_side div.link {
		margin-top: 1em;
	}

	#red_carpet div.wrap div.item div.content_side div.link a.more {
		font-size: 1em;
	}

	#partners {
		height: 16em;
	}

	#partners div.wrap div.head {
		font-size: 2.5em;
		width: 7.8em;
	}

	#partners div.wrap div.sponsor {
		margin-left: 2em;
	}

	#partners div.wrap div.sponsor {
		margin-left: 1em;
	}

	#partners div.wrap div.sponsor.s1 {
		width: 12em;
	}

	#partners div.wrap div.sponsor.s2 {
		width: 7em;
		margin-top: 4.4em;
	}

	#partners div.wrap div.sponsor.s3 {
		width: 12em;
		margin-top: 6.5em;
	}

	#partners div.wrap div.sponsor.s4 {
		width: 12em;
		margin-left: 1em;
		margin-top: 6em;
	}

	.site-footer {
		height: 20em;
	}

	.site-footer div.site-info {
		margin-top: 4em;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side h1 {
		font-size: 2.8em;
	}

	#movies_section div.content_side div.wrap div.right_side div.pg {
		top: -1em;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.leftside {
		margin-right: 1em;
		max-width: 185px;
		width: 29%;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside {
		width: 67%;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside .short_content {
		margin-bottom: 1em;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside .short_content p {
		margin-bottom: 1em;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside #about {
		font-size: 1.2em;
	}

	#more_product div.wrap div.product-item div.rightside .short_content {
		margin-bottom: 1em;
	}

	.product_single #product_section div.content_side div.wrap div.right_side h1 {
		font-size: 2.8em;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.pg {
		top: -1em;
	}

	#more_product div.wrap h1 {
		font-size: 3em;
	}

	#more_product div.wrap div.product-item {
		margin-right: 1%;
		width: 49%;
	}

	#behind_section div.wrap div#bsl div.item h1,
	.behind #more_like_this div.wrap div#bcm h1 {
		font-size: 3em;
	}

	#product_side div.wrap div.product-item {
		margin-right: 1%;
		width: 49%;
	}

	.movies_single #movies_section .content_side {
		top: 5em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.bs {
		display: block;
		float: left;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.treiler {
		display: block;
		margin-right: 1em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.buy {
		margin-right: 1em;
	}

	#movies_section .btn span.left.short {
		padding: 0 0.5em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.bs div.treiler a.btn {
		margin-bottom: 0.7em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side .stay_review span.review_click a,
	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.off_web a {
		font-size: 1em;
	}

	#red-carpet-event div.wrap div.content_side h1 {
		font-size: 3.4em;
		line-height: 1.2em;
		margin: 0.3em 0;
	}

	#red-carpet-event div.wrap div.form_side div.sform form div.submit-holder .btn.form {
		width: 100%;
	}

	#red-carpet-event div.wrap div.form_side div.sform form#rcs_form div#c_wrapper div.submit-holder .btn span.left {
		width: 80%;
	}

	#red-carpet-event div.wrap div.form_side div.sform form#rcs_form div#c_wrapper div.submit-holder .btn span.right {
		width: 20%;
	}

	#red-carpet-event div.wrap div.form_side div.sform form#rcs_form div#c_wrapper div.itm input.inout {
		height: 3.5em;
		line-height: 3.5em;
	}

	.single .site-content #primary.content-area .site-main .entry-header h1.entry-title {
		font-size: 3.3em;
		line-height: 1.2em;
	}

	#secondary.widget-area .widget h1.widget-title {
		margin-bottom: 0.8em;
		padding-bottom: 0.8em;
	}

	#overlay div#review-box div.wrap div.external form#commentform input {
		height: 3.8em;
		line-height: 3.8em;
		margin-right: 1em;
		padding: 0 1em 0 3.9em;
		width: 32%;
	}
}

/******************768***********************/

@media screen and (min-width: 768px) and (max-width: 1023px) {
	.main-navigation {
		font-size: 0.8em;
	}

	.main-navigation .menu_inside h1 {
		font-size: 4em;
		margin-bottom: 0.5em;
		margin-top: 1em;
	}

	.main-navigation .menu_inside img#menu_close {
		width: 40px;
	}

	#masthead.site-header .wrap,
	.archive .site-content .wrap,
	.error404 .site-content .wrap,
	.single .site-content .wrap,
	.page .site-content .wrap,
	#partners div.wrap,
	#product_side div.wrap {
		width: 92%;
	}

	#movies_section div.mobile_res,
	#product_section div.mobile_res {
		display: block;
	}

	.btn span.right,
	.btn span.left {
		box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.13);
		display: inline-block;
		height: 3em;
		line-height: 3em;
		padding: 0 1em;
		transition: background-position 100ms ease 0s;
		vertical-align: top;
	}

	.btn span.right {
		padding: 0;
		transition: background-position 100ms ease 0s;
	}

	.btn {
		margin-bottom: 0.6em;
	}

	#homeslider .slider-for .slide_item .content_side div.wrap div.buttons {
		bottom: 15%;
		right: 0;
		position: absolute;
	}

	#homeslider .slider-for .slide_item .wrap {
		width: 92%;
	}

	#homeslider .slider-for .slide_item div.img_ext {
		height: 337px;
	}

	#homeslider .slider-for .slide_item .wrap h1 {
		bottom: 44%;
		font-size: 2.5em;
		line-height: 1.1em;
		width: 65%;
	}

	#homeslider .slider-nav {
		width: 96%;
	}

	#homeslider .btn {
		display: block;
		width: 232px;
	}

	// #homeslider .slide_thumbnail_item.slick-center {
	// 	background: transparent url("images/slider/slide_thumb_activeline768.png") no-repeat scroll center top;
	// }

	#homeslider .slide_thumbnail_item {
		padding-top: 0.8em;
	}

	.slick-slider {
		margin-bottom: 5px;
	}

	#newsletter div.wrap {
		width: 90%;
	}

	#newsletter div.wrap div.art span#bld {
		display: inline-block;
	}

	#newsletter div.wrap div.art {
		font-size: 2.1em;
		padding: 1.55em 0 0;
		text-align: center;
		width: 100%;
	}

	#newsletter div.wrap span.group {
		display: block;
		float: left;
		margin: 0 auto 3em;
		position: relative;
		width: 100%;
	}

	#newsletter div.wrap div.newsletter {
		display: block;
		float: none;
		margin: 2em auto 0;
		position: relative;
		width: 530px;
	}

	#newsletter div.wrap div.newsletter div.head,
	#newsletter div.wrap div.singup_facebook div.head {
		font-size: 1.35em;
	}

	#newsletter div.wrap div.newsletter form input.newsletter-email {
		height: 3.7em;

		margin-top: 1px;
		width: 28em;
		// background: url("images/form_icon/2.png") no-repeat scroll 1.6em 1em #fff;
	}

	#newsletter .btn span.left {
		width: 9em;
	}

	#newsletter span.or {
		margin: 4.1em 1em 1em;
	}

	#newsletter div.wrap div.singup_facebook span#facebook_login {
		display: inline-block;
	}

	#newsletter div.wrap div.singup_facebook {
		clear: both;
		float: left;
		margin-bottom: 2.5em;
		margin-top: 1em;
	}

	#latest_article .item {
		float: left;
		margin-right: 8px;
		width: 32%;
	}

	#latest_article .wrap {
		margin-top: 3em;
	}

	#latest_article h1 {
		font-size: 3.5em;
	}

	#latest_article span#stay_connected {
		font-size: 1.2em;
		top: 1.3em;
		right: 2%;
		display: none;
	}

	#latest_article div.wrap div.borderline {
		margin: 2.5em 0 3.2em;
		width: 96%;
	}

	#our_movies div.wrap {
		margin-top: 3em;
		margin-bottom: 0.5em;
	}

	#our_movies div.wrap h1 {
		font-size: 3.5em;
	}

	#our_movies div.wrap div.head div.toggle {
		bottom: 0;
		float: right;
		position: relative;
		right: 0;
	}

	#our_movies div.wrap div.head div.toggle span {
		font-size: 1.1em;
	}

	#our_movies div.wrap .movie_itm div.item h3 {
		font-size: 1em;
	}

	#social_feed div.wrap h1 {
		font-size: 3em;
	}

	#talebeh .talent {
		float: left;
		margin-right: 0;
		width: 100%;
	}

	#talebeh .talent h1 {
		font-size: 3em;
	}

	#talebeh .talent div.item div.rightside #about {
		font-size: 1.2em;
	}

	#talebeh .talent div.item div.leftside {
		max-width: 185px;
		width: 29%;
	}

	#talebeh .talent div.item div.rightside {
		width: 68%;
	}

	#talebeh .behinds {
		float: left;
		width: 100%;
	}

	#talebeh .behinds h1 {
		font-size: 3em;
	}

	#store {
		margin-bottom: 3em;
	}

	#store div.wrap .wrap_store {
		margin-right: 0;
		width: 100%;
	}

	#store div.wrap div.product-item div.leftside {
		width: 25%;
	}

	#store div.wrap div.product-item div.rightside {
		width: 73%;
	}

	#store div.wrap h1 {
		font-size: 3em;
	}

	#store div.wrap div.product-item div.rightside a.store_link {
		font-size: 1em;
		margin: 0.75em 0 0 1.5em;
	}

	#store div.wrap div.product-item .borderline {
		margin: 1em 0 1em;
	}

	#store div.wrap div.store_form {
		width: 100%;
	}

	#store div.wrap div.store_form h1 {
		font-size: 1.8em;
		line-height: 1.35em;
	}

	#store div.wrap div.store_form div.form_side {
		height: 18.7em;
		padding: 1em 1.5em;
	}

	#store div.wrap div.store_form div.form_side .desc {
		font-size: 1.2em;
		line-height: 1.2em;
		margin: 0.2em 0 1.3em;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper div.itm {
		margin-bottom: 1.5em;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper div.itm textarea.comment {
		width: 100%;
		height: 8em;
		// background: url("images/form_icon/4.png") no-repeat scroll 1.5em 1.4em #fff;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper input.inout {
		height: 3.5em;
		line-height: 3.5em;
		margin-right: 1% !important;
		width: 33%;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper input.inout.email {
		margin-right: 1%;
	}

	button.btn.form {
		left: 0em;
		top: 0em;
	}

	#idea_form .btn.form span.left {
		font-size: 0.8em;
		height: 3.5em;
		line-height: 3.5em;
		padding: 0 0.5em;
		width: 12em;
	}

	#idea_form .btn.form span.right {
		font-size: 0.8em;
		height: 3.5em;
		line-height: 3.5em;
	}

	#red_carpet div.wrap div.item {
		width: 100%;
	}

	#red_carpet.all_page div.wrap div.item {
		display: block;
		margin-bottom: 1em;
	}

	#red_carpet div.wrap div.item.first {
		width: 100%;
		display: block;
	}

	#red_carpet div.wrap div.item.noimage {
		width: 100%;
	}

	#red_carpet div.wrap div.item div.content_side div.date {
		margin: 0.5em 0 0.6em;
	}

	#red_carpet div.wrap h1 {
		font-size: 3.5em;
	}

	#red_carpet div.wrap div.item div.content_side h3 {
		font-size: 1.5em;
		margin-bottom: 0.5em;
	}

	#red_carpet div.wrap div.item div.img_side {
		float: left;
		width: 39%;
	}

	#red_carpet div.wrap div.item div.content_side {
		background-color: #f8f8f8;
		float: left;
		padding: 1em 1.4em 1.4em;
		width: 61%;
		min-height: 220px;
	}

	#red_carpet div.wrap div.item div.content_side div.link {
		margin-top: 1em;
	}

	#red_carpet div.wrap div.item div.content_side div.link a.more {
		font-size: 1em;
	}

	#partners {
		height: 16em;
	}

	#partners div.wrap div.head {
		display: none;
	}

	#partners div.wrap div.sponsor {
		margin-left: 1em;
	}

	#partners div.wrap div.sponsor.s1 {
		width: 12.5em;
	}

	#partners div.wrap div.sponsor.s2 {
		width: 8em;
		margin-top: 4.2em;
	}

	#partners div.wrap div.sponsor.s3 {
		width: 12em;
	}

	#partners div.wrap div.sponsor.s4 {
		width: 12.5em;
		margin-left: 1em;
		margin-top: 5.9em;
	}

	.site-footer {
		height: 20em;
	}

	.site-footer div.site-info {
		margin-top: 4em;
	}

	.movies_single #movies_section .content_side {
		height: 400px;
		top: 0;
	}

	.movies_single #movies_section .content_side .wrap {
		width: 92%;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side div.top_content_left {
		margin-right: 0;
		width: 100%;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side div.top_content_left,
	.movies_single #movies_section div.content_side div.wrap div.right_side div.top_content_right {
		display: none;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side div.m_item.synoptis {
		margin-bottom: 0em;
	}

	.movies_single
		#movies_section
		div.content_side
		div.wrap
		div.right_side
		.top_content_left
		div.m_item
		span.article.synopsis {
		height: 130px;
	}

	#movies_section div.img_ext {
		height: 400px;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side h1 {
		font-size: 2.3em;
		line-height: 1.2em;
		margin-right: 0;
		position: relative;
		top: -20px;
	}

	.movies_single #movies_section div.content_side .wrap .image_side {
		position: relative;
		top: -20px;
	}

	#movies_section div.content_side div.wrap div.right_side div.pg {
		top: -1em;
	}

	#movies_section div.content_side div.wrap div.right_side div.pg span.obi img {
		width: 71%;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side .watch_online {
		margin-right: 1em;
		margin-top: 1.3em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side .watch_online span.watch_span a {
		font-size: 1em;
	}

	#movies_section div.content_side div.wrap div.ext div.image_side div.stay_review span.review_click a {
		font-size: 0.8em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.off_web {
		margin-right: 1em;
		margin-top: 1.3em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.off_web a {
		font-size: 1em;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.leftside {
		margin-right: 1em;
		max-width: 185px;
		width: 29%;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside {
		width: 67%;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside .short_content {
		margin-bottom: 1em;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside .short_content p {
		margin-bottom: 1em;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside #about {
		font-size: 1.2em;
	}

	#more_product div.wrap div.product-item div.rightside .short_content {
		margin-bottom: 2em;
	}

	.product_single #product_section .content_side {
		top: 6.5em;
	}

	.product_single #product_section div.content_side div.wrap div.right_side h1 {
		font-size: 2.3em;
		margin-right: 0;
		line-height: 1.2em;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.pg {
		top: -1em;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.pg span.obi img {
		width: 71%;
	}

	#more_product div.wrap h1 {
		font-size: 3em;
	}

	#more_product div.wrap div.product-item {
		margin-right: 0;
		width: 100%;
	}

	#more_product div.wrap div.product-item div.leftside {
		margin-right: 2%;
		width: 25%;
	}

	#more_product div.wrap div.product-item div.rightside {
		width: 73%;
	}

	#behind_section div.wrap div#bsl div.item h1,
	.behind #more_like_this div.wrap div#bcm h1 {
		font-size: 3em;
		line-height: 1.2em;
	}

	#product_side div.wrap div.product-item {
		margin-right: 0;
		width: 100%;
	}

	#product_side div.wrap div.product-item div.leftside {
		width: 25%;
	}

	#product_side div.wrap div.product-item div.rightside {
		width: 73%;
	}

	#movies_section div.mobile_res {
		border-top: 7px solid #ea593b;
		border-bottom: 7px solid #029e93;
		float: left;
		width: 100%;
	}

	#movies_section div.mobile_res div.wrap {
		margin-top: 2em;
	}

	#movies_section div.mobile_res div.wrap .left_side {
		float: left;
		margin-right: 3%;
		width: 48.5%;
	}

	#movies_section div.mobile_res div.wrap .right_side {
		float: left;
		width: 48.5%;
	}

	#movies_section div.mobile_res div.wrap .m_item {
		color: #a9a9a9;
		font-family: "proxima_novalight";
		font-size: 1.143em;
		margin-bottom: 1.8em;
		width: 100%;
	}

	#movies_section div.mobile_res div.wrap .m_item h4 {
		color: #fff;
		font-family: "proxima_novaextrabold";
		font-size: 1em;
		text-decoration: none;
		text-transform: uppercase;
	}

	.product_single div.img_ext {
		height: 400px;
	}

	.product_single .mobile_res {
		border-top: 7px solid #ea593b;
		border-bottom: 7px solid #029e93;
		float: left;
		width: 100%;
		background-color: #0c0c0c;
	}

	.product_single .mobile_res div.wrap {
		margin-top: 2em;
	}

	.product_single .mobile_res div.wrap div.m_item {
		color: #a9a9a9;
		font-family: "proxima_novalight";
		font-size: 1.143em;
		margin-bottom: 1.8em;
		width: 100%;
	}

	.product_single .mobile_res div.wrap div.m_item h4 {
		color: #fff;
		font-family: "proxima_novaextrabold";
		font-size: 1em;
		text-decoration: none;
		text-transform: uppercase;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.top_content_left {
		display: none;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.bottom_side a.btn {
		font-size: 0.9em;
	}

	#talent_highlight div.wrap div#bsl {
		padding-top: 4.8em;
		width: 100%;
	}

	#talent_highlight div.wrap div#bsl div.item h1 {
		display: block;
	}

	#talent_highlight div.wrap div#bsl div.item div.leftside {
		float: left;
		width: 100%;
	}

	#talent_highlight div.wrap div#bsl div.item div.rightside {
		margin-top: 2em;
		width: 100%;
	}

	#talent_highlight div.wrap div#bsl div.item div.leftside span.pop_up {
		width: 100%;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item {
		width: 100%;
	}

	#behind_section div.wrap div#bsl div.item div.leftside {
		width: 100%;
	}

	#behind_section div.wrap div#bsl div.item div.leftside a.pop_up {
		width: 100%;
	}

	#behind_section div.wrap div#bsl div.item div.rightside {
		margin-top: 2em;
		width: 100%;
	}

	.behind #more_like_this div.wrap div#bcm div.item {
		width: 100%;
	}

	.behind #more_like_this div.wrap div#bcm div.item div.rightside {
		width: 57%;
	}

	#red-carpet-event div.wrap {
		margin-top: 2em;
	}

	#red-carpet-event div.wrap div.content_side,
	#red-carpet-event div.wrap div.content_side.full {
		margin-right: 0;
		width: 100%;
	}

	#red-carpet-event div.wrap div.content_side h1 {
		font-size: 3.2em;
		line-height: 1.1em;
		margin: 0.3em 0;
	}

	#red-carpet-event div.wrap div.img_side {
		background-color: #f8f8f8;
		float: left;
		margin-right: 0;
		width: auto;
		max-width: 100%;
	}

	#red-carpet-event div.wrap div.form_side {
		float: left;
		width: 100%;
	}

	.single .site-content #primary.content-area .site-main .entry-header h1.entry-title {
		font-size: 2.8em;
		line-height: 1.1em;
	}

	#secondary.widget-area .widget h1.widget-title {
		font-size: 1.7em;
		margin-bottom: 0.5em;
		padding-bottom: 0.5em;
	}

	#secondary.widget-area .widget ul li {
		margin-bottom: 0.5em;
	}

	.archive .site-main .page-header h1.page-title {
		color: #029e93;
		font-size: 2.5em;
		font-style: italic;
	}

	.archive .site-main .page-header h1.page-title span {
		display: block;
		font-size: 1.5em;
		color: #272727;
		font-family: "proxima_nova_bold";
		font-weight: normal;
		font-style: normal;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.bs {
		float: left;
		width: 100%;
	}

	#overlay div#review-box {
		background-color: #fff;
		padding: 1.5em;
		position: absolute;
		width: 80%;
	}

	#overlay div#review-box a.review_box_close {
		// background: url("images/close_btn_rating.jpg") no-repeat scroll left top transparent;
		display: block;
		height: 32px;
		position: absolute;
		right: -5px;
		top: -5px;
		width: 31px;
	}

	#overlay div#review-box div.wrap div.external form#commentform h1 {
		color: #0c0c0c;
		font-family: "proxima_novablack";
		font-size: 3.57143em;
		text-transform: uppercase;
	}

	#overlay div#review-box div.wrap div.external form#commentform div.rateit {
		float: left;
		margin-right: 1.5em;
		margin-top: 1em;
	}

	#overlay div#review-box div.wrap div.external form#commentform div.rate_text {
		color: #717171;
		float: left;
		font-family: "proxima_nova_regular";
		font-size: 1.428em;
		margin: 0.5em 0 1.5em;
	}

	#overlay div#review-box div.wrap div.external form#commentform textarea#comment {
		// background: url("images/form_icon/4.png") no-repeat scroll 1.5em 1.5em #fff;
		border: 1px solid #cfcfcf;
		border-radius: 0;
		color: #747474;
		font-family: "proxima_nova_regular";
		height: 8.5em;
		padding: 1.3em 1em 0 3.9em;
		resize: none;
		width: 100%;
	}

	#overlay div#review-box div.wrap div.external form#commentform input {
		background-color: #fff;
		border: 1px solid #cfcfcf;
		border-radius: 0;
		color: #747474;
		float: left;
		font-family: "proxima_nova_regular";
		height: 3.8em;
		line-height: 3.8em;
		padding: 0 1em 0 3.9em;
		width: 49%;
	}

	#overlay div#review-box div.wrap div.external form#commentform input#author {
		// background: url("images/form_icon/1.png") no-repeat scroll 1.25em center #fff;
		margin-right: 2%;
	}

	#overlay div#review-box div.wrap div.external form#commentform input#email {
		// background: url("images/form_icon/2.png") no-repeat scroll 1.25em center #fff;
		margin-right: 0;
	}

	#overlay div#review-box div.wrap div.external form#commentform button.btn.form {
		left: 0em;
		top: 1em;
	}
}

@media screen and (min-width: 736px) and (max-width: 767px) {
	.main-navigation {
		font-size: 0.8em;
	}

	.main-navigation .menu_inside h1 {
		font-size: 4em;
		margin-bottom: 0.5em;
		margin-top: 1em;
	}

	.main-navigation .menu_inside img#menu_close {
		width: 36px;
		right: 1.5em;
		top: 1.5em;
	}

	#masthead.site-header .wrap,
	.archive .site-content .wrap,
	.error404 .site-content .wrap,
	.single .site-content .wrap,
	.page .site-content .wrap,
	#partners div.wrap,
	#product_side div.wrap {
		width: 92%;
	}

	#movies_section div.mobile_res,
	#product_section div.mobile_res {
		display: block;
	}

	.btn span.right,
	.btn span.left {
		box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.13);
		display: inline-block;
		height: 3em;
		line-height: 3em;
		padding: 0 1em;
		transition: background-position 100ms ease 0s;
		vertical-align: top;
	}

	.btn span.right {
		padding: 0;
		transition: background-position 100ms ease 0s;
	}

	.btn {
		margin-bottom: 0.6em;
		font-size: 1em;
	}

	#homeslider .slider-for .slide_item .content_side div.wrap div.buttons {
		bottom: 15%;
		right: 0;
		position: absolute;
	}

	#homeslider .slider-for .slide_item .wrap {
		width: 92%;
	}

	#homeslider .slider-for .slide_item div.img_ext {
		height: 323px;
	}

	#homeslider .slider-for .slide_item .wrap h1 {
		bottom: 52%;
		font-size: 2.4em;
		line-height: 1.1em;
		width: 65%;
	}

	#homeslider .btn {
		display: block;
		width: 232px;
	}

	.slick-slider {
		margin-bottom: 5px;
	}

	// #homeslider .slide_thumbnail_item.slick-center {
	// 	background: transparent url("images/slider/slide_thumb_activeline768.png") no-repeat scroll center top;
	// }

	#homeslider .slide_thumbnail_item {
		padding-top: 0.8em;
	}

	#homeslider .slider-nav {
		width: 96%;
	}

	#newsletter div.wrap {
		width: 90%;
	}

	#newsletter div.wrap div.art span#bld {
		display: inline-block;
	}

	#newsletter div.wrap div.art {
		font-size: 2.1em;
		padding: 1.55em 0 0;
		text-align: center;
		width: 100%;
	}

	#newsletter div.wrap span.group {
		display: block;
		float: left;
		margin: 0 auto 3em;
		position: relative;
		width: 100%;
	}

	#newsletter div.wrap div.newsletter {
		display: block;
		float: none;
		margin: 2em auto 0;
		position: relative;
		width: 530px;
	}

	#newsletter div.wrap div.newsletter div.head,
	#newsletter div.wrap div.singup_facebook div.head {
		font-size: 1.35em;
	}

	#newsletter div.wrap div.newsletter form input.newsletter-email {
		height: 3.7em;
		margin-top: 1px;
		width: 28em;
		// background: url("images/form_icon/2.png") no-repeat scroll 1.6em 1em #fff;
	}

	#newsletter .btn span.left {
		width: 9em;
	}

	#newsletter span.or {
		margin: 4.1em 1em 1em;
	}

	#newsletter div.wrap div.singup_facebook span#facebook_login {
		display: inline-block;
	}

	#newsletter div.wrap div.singup_facebook {
		clear: both;
		float: left;
		margin-bottom: 2.5em;
		margin-top: 1em;
	}

	#latest_article .item_3,
	#latest_article .item_4 {
		display: none;
	}

	#latest_article .item {
		float: left;
		margin-right: 8px;
		width: 32%;
	}

	#latest_article .wrap {
		margin-top: 3em;
	}

	#latest_article h1 {
		font-size: 3.5em;
	}

	#latest_article span#stay_connected {
		font-size: 1.2em;
		top: 1.3em;
		right: 2%;
		display: none;
	}

	#latest_article div.wrap div.borderline {
		margin: 2.5em 0 3.2em;
		width: 96%;
	}

	#our_movies div.wrap {
		margin-top: 3em;
		margin-bottom: 0.5em;
	}

	#our_movies div.wrap h1 {
		font-size: 3.5em;
	}

	#our_movies div.wrap div.head div.toggle {
		bottom: 0;
		float: right;
		position: relative;
		right: 0;
	}

	#our_movies div.wrap div.head div.toggle span {
		font-size: 1.1em;
	}

	#our_movies div.wrap .movie_itm div.item h3 {
		font-size: 1em;
	}

	#social_feed div.wrap h1 {
		font-size: 3em;
	}

	#talebeh .talent {
		float: left;
		margin-right: 0;
		width: 100%;
	}

	#talebeh .talent h1 {
		font-size: 3em;
	}

	#talebeh .talent div.item div.rightside #about {
		font-size: 1.2em;
	}

	#talebeh .talent div.item div.leftside {
		max-width: 185px;
		width: 29%;
	}

	#talebeh .talent div.item div.rightside {
		width: 68%;
	}

	#talebeh .behinds {
		float: left;
		width: 100%;
	}

	#talebeh .behinds h1 {
		font-size: 3em;
	}

	#store {
		margin-bottom: 3em;
	}

	#store div.wrap .wrap_store {
		margin-right: 0;
		width: 100%;
	}

	#store div.wrap div.product-item div.leftside {
		width: 25%;
	}

	#store div.wrap div.product-item div.rightside {
		width: 73%;
	}

	#store div.wrap h1 {
		font-size: 3em;
	}

	#store div.wrap div.product-item div.rightside a.store_link {
		font-size: 1em;
		margin: 0.75em 0 0 1.5em;
	}

	#store div.wrap div.product-item .borderline {
		margin: 1em 0 1em;
	}

	#store div.wrap div.store_form {
		width: 100%;
	}

	#store div.wrap div.store_form h1 {
		font-size: 1.8em;
		line-height: 1.35em;
	}

	#store div.wrap div.store_form div.form_side {
		height: 18.7em;
		padding: 1em 1.5em;
	}

	#store div.wrap div.store_form div.form_side .desc {
		font-size: 1.2em;
		line-height: 1.2em;
		margin: 0.2em 0 1.3em;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper div.itm {
		margin-bottom: 1.5em;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper div.itm textarea.comment {
		width: 100%;
		height: 8em;
		// background: url("images/form_icon/4.png") no-repeat scroll 1.5em 1.4em #fff;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper input.inout {
		height: 3.5em;
		line-height: 3.5em;
		margin-right: 1% !important;
		width: 33%;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper input.inout.email {
		margin-right: 1%;
	}

	button.btn.form {
		left: 0em;
		top: 0em;
	}

	#idea_form .btn.form span.left {
		font-size: 0.8em;
		height: 3.5em;
		line-height: 3.5em;
		padding: 0 0.5em;
		width: 12em;
	}

	#idea_form .btn.form span.right {
		font-size: 0.8em;
		height: 3.5em;
		line-height: 3.5em;
	}

	#red_carpet div.wrap div.item {
		width: 100%;
	}

	#red_carpet.all_page div.wrap div.item {
		width: 100%;
		display: block;
		margin-bottom: 1em;
	}

	#red_carpet div.wrap div.item.first {
		width: 100%;
		display: block;
	}

	#red_carpet div.wrap div.item.noimage {
		width: 100%;
	}

	#red_carpet div.wrap div.item div.content_side div.date {
		margin: 0.5em 0 0.6em;
	}

	#red_carpet div.wrap h1 {
		font-size: 3.5em;
	}

	#red_carpet div.wrap div.item div.content_side h3 {
		font-size: 1.5em;
		margin-bottom: 0.5em;
	}

	#red_carpet div.wrap div.item div.img_side {
		float: left;
		width: 39%;
	}

	#red_carpet div.wrap div.item div.content_side {
		background-color: #f8f8f8;
		float: left;
		padding: 1em 1.4em 1.4em;
		width: 61%;
		min-height: 220px;
	}

	#red_carpet div.wrap div.item div.content_side div.link {
		margin-top: 1em;
	}

	#red_carpet div.wrap div.item div.content_side div.link a.more {
		font-size: 1em;
	}

	#partners {
		height: 16em;
	}

	#partners div.wrap div.head {
		display: none;
	}

	#partners div.wrap div.sponsor {
		margin-left: 1em;
	}

	#partners div.wrap div.sponsor.s1 {
		width: 12em;
	}

	#partners div.wrap div.sponsor.s2 {
		width: 8em;
	}

	#partners div.wrap div.sponsor.s3 {
		width: 11em;
	}

	#partners div.wrap div.sponsor.s4 {
		width: 12em;
		margin-left: 1em;
		margin-top: 6em;
	}

	.site-footer {
		height: 20em;
	}

	.site-footer div.site-info {
		margin-top: 4em;
	}

	.movies_single #movies_section .content_side {
		height: 280px;
		top: 0;
	}

	.movies_single #movies_section .content_side .wrap {
		width: 92%;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side div.top_content_left {
		margin-right: 0;
		width: 100%;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side div.top_content_left,
	.movies_single #movies_section div.content_side div.wrap div.right_side div.top_content_right {
		display: none;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side div.m_item.synoptis {
		margin-bottom: 0em;
	}

	.movies_single
		#movies_section
		div.content_side
		div.wrap
		div.right_side
		.top_content_left
		div.m_item
		span.article.synopsis {
		height: 130px;
	}

	#movies_section div.img_ext {
		height: 400px;
	}

	.movies_single #movies_section div.content_side .wrap .image_side {
		position: relative;
		top: 2em;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side h1 {
		font-size: 2em;
		margin-right: 0px;
		line-height: 1.2em;
		margin-top: 0.8em;
	}

	#movies_section div.content_side div.wrap div.right_side div.pg {
		font-size: 0.9em;
		right: -2em;
		text-align: right;
		top: -2em;
		width: 14em;
	}

	#movies_section div.content_side div.wrap div.right_side div.pg span.obi {
		width: 42%;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side .watch_online {
		margin-right: 1em;
		margin-top: 1.3em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side .watch_online span.watch_span a {
		font-size: 1em;
	}

	#movies_section div.content_side div.wrap div.ext div.image_side div.stay_review span.review_click a {
		font-size: 0.8em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.off_web {
		margin-right: 1em;
		margin-top: 1.3em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.off_web a {
		font-size: 1em;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.leftside {
		margin-right: 1em;
		max-width: 185px;
		width: 29%;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside {
		width: 67%;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside .short_content {
		margin-bottom: 1em;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside .short_content p {
		margin-bottom: 1em;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside #about {
		font-size: 1.2em;
	}

	#more_product div.wrap div.product-item div.rightside .short_content {
		margin-bottom: 2em;
	}

	.product_single #product_section .content_side {
		top: 6.5em;
	}

	.product_single #product_section div.content_side div.wrap div.right_side h1 {
		font-size: 1.8em;
		margin-right: 0;
		line-height: 1.2em;
		margin-top: 0.8em;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.pg {
		font-size: 0.9em;
		right: -2em;
		text-align: right;
		top: -2em;
		width: 14em;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.pg span.obi {
		width: 42%;
	}

	.product_single #product_section div.content_side .wrap .image_side {
		margin-top: 0;
	}

	.product_single #product_section div.content_side .right_side .bottom_side a.btn {
		font-size: 0.8em;
	}

	#more_product div.wrap h1 {
		font-size: 3em;
	}

	#more_product div.wrap div.product-item {
		margin-right: 0;
		width: 100%;
	}

	#more_product div.wrap div.product-item div.leftside {
		margin-right: 2%;
		width: 25%;
	}

	#more_product div.wrap div.product-item div.rightside {
		width: 73%;
	}

	#behind_section div.wrap div#bsl div.item h1,
	.behind #more_like_this div.wrap div#bcm h1 {
		font-size: 3em;
		line-height: 1.2em;
	}

	#product_side div.wrap div.product-item {
		margin-right: 0;
		width: 100%;
	}

	#product_side div.wrap div.product-item div.leftside {
		width: 25%;
	}

	#product_side div.wrap div.product-item div.rightside {
		width: 73%;
	}

	#movies_section div.mobile_res {
		border-top: 7px solid #ea593b;
		border-bottom: 7px solid #029e93;
		float: left;
		width: 100%;
	}

	#movies_section div.mobile_res div.wrap {
		margin-top: 2em;
	}

	#movies_section div.mobile_res div.wrap .left_side {
		float: left;
		margin-right: 3%;
		width: 48.5%;
	}

	#movies_section div.mobile_res div.wrap .right_side {
		float: left;
		width: 48.5%;
	}

	#movies_section div.mobile_res div.wrap .m_item {
		color: #a9a9a9;
		font-family: "proxima_novalight";
		font-size: 1.143em;
		margin-bottom: 1.8em;
		width: 100%;
	}

	#movies_section div.mobile_res div.wrap .m_item h4 {
		color: #fff;
		font-family: "proxima_novaextrabold";
		font-size: 1em;
		text-decoration: none;
		text-transform: uppercase;
	}

	#movies_section div.mobile_res div.wrap .m_item .article {
		font-size: 0.9em;
	}

	.product_single div.img_ext {
		height: 400px;
	}

	.product_single .mobile_res {
		border-top: 7px solid #ea593b;
		border-bottom: 7px solid #029e93;
		float: left;
		width: 100%;
		background-color: #0c0c0c;
	}

	.product_single .mobile_res div.wrap {
		margin-top: 2em;
	}

	.product_single .mobile_res div.wrap div.m_item {
		color: #a9a9a9;
		font-family: "proxima_novalight";
		font-size: 1.143em;
		margin-bottom: 1.8em;
		width: 100%;
	}

	.product_single .mobile_res div.wrap div.m_item h4 {
		color: #fff;
		font-family: "proxima_novaextrabold";
		font-size: 1em;
		text-decoration: none;
		text-transform: uppercase;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.top_content_left {
		display: none;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.bottom_side a.btn {
		font-size: 0.8em;
	}

	#talent_highlight div.wrap div#bsl {
		padding-top: 4.8em;
		width: 100%;
	}

	#talent_highlight div.wrap div#bsl div.item h1 {
		display: block;
	}

	#talent_highlight div.wrap div#bsl div.item div.leftside {
		float: left;
		width: 100%;
	}

	#talent_highlight div.wrap div#bsl div.item div.rightside {
		margin-top: 2em;
		width: 100%;
	}

	#talent_highlight div.wrap div#bsl div.item div.leftside span.pop_up {
		width: 100%;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item {
		width: 100%;
	}

	#behind_section div.wrap div#bsl div.item div.leftside {
		width: 100%;
	}

	#behind_section div.wrap div#bsl div.item div.leftside a.pop_up {
		width: 100%;
	}

	#behind_section div.wrap div#bsl div.item div.rightside {
		margin-top: 2em;
		width: 100%;
	}

	.behind #more_like_this div.wrap div#bcm div.item {
		width: 100%;
	}

	.behind #more_like_this div.wrap div#bcm div.item div.rightside {
		width: 57%;
	}

	#red-carpet-event div.wrap {
		margin-top: 2em;
	}

	#red-carpet-event div.wrap div.img_side {
		background-color: #f8f8f8;
		float: left;
		margin-right: 0;
		width: auto;
		max-width: 100%;
	}

	#red-carpet-event div.wrap div.content_side,
	#red-carpet-event div.wrap div.content_side.full {
		margin-right: 0;
		width: 100%;
	}

	#red-carpet-event div.wrap div.content_side h1 {
		font-size: 3.2em;
		line-height: 1.1em;
		margin: 0.3em 0;
	}

	#red-carpet-event div.wrap div.form_side {
		float: left;
		width: 100%;
	}

	.single .site-content #primary.content-area .site-main .entry-header h1.entry-title {
		font-size: 2.8em;
		line-height: 1.1em;
	}

	#secondary.widget-area .widget h1.widget-title {
		font-size: 1.7em;
		margin-bottom: 0.5em;
		padding-bottom: 0.5em;
	}

	#secondary.widget-area .widget ul li {
		margin-bottom: 0.5em;
	}

	.archive .site-main .page-header h1.page-title {
		color: #029e93;
		font-size: 2.5em;
		font-style: italic;
	}

	.archive .site-main .page-header h1.page-title span {
		display: block;
		font-size: 1.5em;
		color: #272727;
		font-family: "proxima_nova_bold";
		font-weight: normal;
		font-style: normal;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.bs {
		float: left;
		width: 100%;
	}

	#overlay div#review-box {
		background-color: #fff;
		padding: 1.5em;
		position: absolute;
		width: 80%;
	}

	#overlay div#review-box a.review_box_close {
		// background: url("images/close_btn_rating.jpg") no-repeat scroll left top transparent;
		display: block;
		height: 32px;
		position: absolute;
		right: -5px;
		top: -5px;
		width: 31px;
	}

	#overlay div#review-box div.wrap div.external form#commentform h1 {
		color: #0c0c0c;
		font-family: "proxima_novablack";
		font-size: 3.57143em;
		text-transform: uppercase;
	}

	#overlay div#review-box div.wrap div.external form#commentform div.rateit {
		float: left;
		margin-right: 1.5em;
		margin-top: 1em;
	}

	#overlay div#review-box div.wrap div.external form#commentform div.rate_text {
		color: #717171;
		float: left;
		font-family: "proxima_nova_regular";
		font-size: 1.2em;
		margin: 0.5em 0 1.5em;
	}

	#overlay div#review-box div.wrap div.external form#commentform textarea#comment {
		// background: url("images/form_icon/4.png") no-repeat scroll 1.5em 1.5em #fff;
		border: 1px solid #cfcfcf;
		border-radius: 0;
		color: #747474;
		font-family: "proxima_nova_regular";
		height: 8.5em;
		padding: 1.3em 1em 0 3.9em;
		resize: none;
		width: 100%;
	}

	#overlay div#review-box div.wrap div.external form#commentform input {
		background-color: #fff;
		border: 1px solid #cfcfcf;
		border-radius: 0;
		color: #747474;
		float: left;
		font-family: "proxima_nova_regular";
		height: 3.8em;
		line-height: 3.8em;
		padding: 0 1em 0 3.9em;
		width: 49%;
	}

	#overlay div#review-box div.wrap div.external form#commentform input#author {
		// background: url("images/form_icon/1.png") no-repeat scroll 1.25em center #fff;
		margin-right: 2%;
	}

	#overlay div#review-box div.wrap div.external form#commentform input#email {
		// background: url("images/form_icon/2.png") no-repeat scroll 1.25em center #fff;
		margin-right: 0;
	}

	#overlay div#review-box div.wrap div.external form#commentform button.btn.form {
		left: 0em;
		top: 1em;
	}

	#video-box {
		background-color: #000;
		position: absolute;
		right: 0;
		top: 0;
		width: 38em;
		height: 26em;
	}
}

@media screen and (min-width: 667px) and (max-width: 735px) {
	.main-navigation {
		font-size: 0.8em;
	}

	.main-navigation .menu_inside h1 {
		font-size: 4em;
		margin-bottom: 0.5em;
		margin-top: 1em;
	}

	.main-navigation .menu_inside img#menu_close {
		width: 36px;
		right: 1.5em;
		top: 1.5em;
	}

	#masthead.site-header .wrap,
	.archive .site-content .wrap,
	.error404 .site-content .wrap,
	.single .site-content .wrap,
	.page .site-content .wrap,
	#partners div.wrap,
	#product_side div.wrap {
		width: 92%;
	}

	#movies_section div.mobile_res,
	#product_section div.mobile_res {
		display: block;
	}

	.btn span.right,
	.btn span.left {
		box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.13);
		display: inline-block;
		height: 3em;
		line-height: 3em;
		padding: 0 1em;
		transition: background-position 100ms ease 0s;
		vertical-align: top;
	}

	.btn span.right {
		padding: 0;
		transition: background-position 100ms ease 0s;
	}

	.btn {
		margin-bottom: 0.4em;
	}

	#homeslider .btn {
		width: 232px;
	}

	#homeslider .slider-for .slide_item .content_side div.wrap div.buttons {
		bottom: 8%;
		right: 0;
		position: absolute;
	}

	#homeslider .slider-for .slide_item .wrap {
		width: 92%;
	}

	#homeslider .slider-for .slide_item div.img_ext {
		height: 293px;
	}

	#homeslider .slider-for .slide_item .wrap h1 {
		bottom: 48%;
		font-size: 2.1em;
		line-height: 1.1em;
		width: 60%;
	}

	#homeslider .slider-nav {
		width: 96%;
	}

	// #homeslider .slide_thumbnail_item.slick-center {
	// 	background: transparent url("images/slider/slide_thumb_activeline768.png") no-repeat scroll center top;
	// }

	#homeslider .slide_thumbnail_item {
		padding-top: 0.8em;
	}

	.slick-slider {
		margin-bottom: 5px;
	}

	#newsletter div.wrap {
		width: 90%;
	}

	#newsletter div.wrap div.art span#bld {
		display: inline-block;
	}

	#newsletter div.wrap div.art {
		font-size: 2.1em;
		padding: 1.55em 0 0;
		text-align: center;
		width: 100%;
	}

	#newsletter div.wrap span.group {
		display: block;
		float: left;
		margin: 0 auto 3em;
		position: relative;
		width: 100%;
	}

	#newsletter div.wrap div.newsletter {
		display: block;
		float: none;
		margin: 2em auto 0;
		position: relative;
		width: 530px;
	}

	#newsletter div.wrap div.newsletter div.head,
	#newsletter div.wrap div.singup_facebook div.head {
		font-size: 1.35em;
	}

	#newsletter div.wrap div.newsletter form input.newsletter-email {
		height: 3.7em;

		margin-top: 1px;
		width: 28em;
		// background: url("images/form_icon/2.png") no-repeat scroll 1.6em 1em #fff;
	}

	#newsletter .btn span.left {
		width: 9em;
	}

	#newsletter span.or {
		margin: 4.1em 1em 1em;
	}

	#newsletter div.wrap div.singup_facebook span#facebook_login {
		display: inline-block;
	}

	#newsletter div.wrap div.singup_facebook {
		clear: both;
		float: left;
		margin-bottom: 2.5em;
		margin-top: 1em;
	}

	#latest_article .item {
		float: left;
		margin-right: 8px;
		width: 32%;
	}

	#latest_article .wrap {
		margin-top: 3em;
	}

	#latest_article h1 {
		font-size: 3.5em;
	}

	#latest_article span#stay_connected {
		font-size: 1.2em;
		top: 1.3em;
		right: 2%;
		display: none;
	}

	#latest_article div.wrap div.borderline {
		margin: 2.5em 0 3.2em;
		width: 96%;
	}

	#our_movies div.wrap {
		margin-top: 2em;
		margin-bottom: 0.5em;
	}

	#our_movies div.wrap h1 {
		font-size: 3em;
	}

	#our_movies div.wrap div.head div.toggle {
		bottom: 0;
		float: right;
		position: relative;
		right: 0;
	}

	#our_movies div.wrap div.head div.toggle span {
		font-size: 1.1em;
	}

	#our_movies div.wrap .movie_itm div.item h3 {
		font-size: 1em;
	}

	#social_feed div.wrap h1 {
		font-size: 3em;
	}

	#talebeh .talent {
		float: left;
		margin-right: 0;
		width: 100%;
	}

	#talebeh .talent h1 {
		font-size: 3em;
	}

	#talebeh .talent div.item div.rightside #about {
		font-size: 1.2em;
	}

	#talebeh .talent div.item div.leftside {
		max-width: 185px;
		width: 29%;
	}

	#talebeh .talent div.item div.rightside {
		width: 68%;
	}

	#talebeh .behinds {
		float: left;
		width: 100%;
	}

	#talebeh .behinds h1 {
		font-size: 3em;
	}

	#store {
		margin-bottom: 3em;
	}

	#store div.wrap .wrap_store {
		margin-right: 0;
		width: 100%;
	}

	#store div.wrap div.product-item div.leftside {
		width: 25%;
	}

	#store div.wrap div.product-item div.rightside {
		width: 73%;
	}

	#store div.wrap h1 {
		font-size: 3em;
	}

	#store div.wrap div.product-item div.rightside a.store_link {
		font-size: 1em;
		margin: 0.75em 0 0 1.5em;
	}

	#store div.wrap div.product-item .borderline {
		margin: 1em 0 1em;
	}

	#store div.wrap div.store_form {
		width: 100%;
	}

	#store div.wrap div.store_form h1 {
		font-size: 1.8em;
		line-height: 1.35em;
	}

	#store div.wrap div.store_form div.form_side {
		height: 18.7em;
		padding: 1em 1.5em;
	}

	#store div.wrap div.store_form div.form_side .desc {
		font-size: 1.2em;
		line-height: 1.2em;
		margin: 0.2em 0 1.3em;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper div.itm {
		margin-bottom: 1.5em;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper div.itm textarea.comment {
		width: 100%;
		height: 8em;
		// background: url("images/form_icon/4.png") no-repeat scroll 1.5em 1.4em #fff;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper input.inout {
		height: 3.5em;
		line-height: 3.5em;
		margin-right: 1% !important;
		width: 33%;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper input.inout.email {
		margin-right: 1%;
	}

	button.btn.form {
		left: 0em;
		top: 0em;
	}

	#idea_form .btn.form span.left {
		font-size: 0.8em;
		height: 3.5em;
		line-height: 3.5em;
		padding: 0 0.5em;
		width: 12em;
	}

	#idea_form .btn.form span.right {
		font-size: 0.8em;
		height: 3.5em;
		line-height: 3.5em;
	}

	#red_carpet div.wrap div.item {
		width: 100%;
	}

	#red_carpet.all_page div.wrap div.item {
		width: 100%;
		display: block;
		margin-bottom: 1em;
	}

	#red_carpet div.wrap div.item.first {
		width: 100%;
		display: block;
	}

	#red_carpet div.wrap div.item.noimage {
		width: 100%;
	}

	#red_carpet div.wrap div.item div.content_side div.date {
		margin: 0.5em 0 0.6em;
	}

	#red_carpet div.wrap h1 {
		font-size: 3.5em;
	}

	#red_carpet div.wrap div.item div.content_side h3 {
		font-size: 1.5em;
		margin-bottom: 0.5em;
	}

	#red_carpet div.wrap div.item div.img_side {
		float: left;
		width: 39%;
	}

	#red_carpet div.wrap div.item div.content_side {
		background-color: #f8f8f8;
		float: left;
		padding: 1em 1.4em 1.4em;
		width: 61%;
		min-height: 220px;
	}

	#red_carpet div.wrap div.item div.content_side div.link {
		margin-top: 1em;
	}

	#red_carpet div.wrap div.item div.content_side div.link a.more {
		font-size: 1em;
	}

	#partners {
		height: 16em;
	}

	#partners div.wrap div.head {
		display: none;
	}

	#partners div.wrap div.sponsor {
		margin-left: 1em;
	}

	#partners div.wrap div.sponsor.s1 {
		width: 10.8em;
	}

	#partners div.wrap div.sponsor.s2 {
		width: 7em;
	}

	#partners div.wrap div.sponsor.s3 {
		width: 10em;
	}

	#partners div.wrap div.sponsor.s4 {
		width: 10.8em;
		margin-left: 1em;
		margin-top: 6em;
	}

	.site-footer {
		height: 20em;
	}

	.site-footer div.site-info {
		margin-top: 4em;
	}

	.movies_single #movies_section .content_side {
		top: 0;
	}

	.movies_single #movies_section .content_side .wrap {
		width: 92%;
	}

	.movies_single #movies_section .content_side .wrap .ext {
		padding: 1em 1em 0.8em;
	}

	.movies_single #movies_section div.content_side .wrap .image_side .ext_img {
		position: relative;
		top: 0em;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side .topside {
		margin-bottom: 1em;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side div.top_content_left {
		margin-right: 0;
		width: 100%;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side div.top_content_left,
	.movies_single #movies_section div.content_side div.wrap div.right_side div.top_content_right {
		display: none;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side div.m_item.synoptis {
		margin-bottom: 0em;
	}

	.movies_single
		#movies_section
		div.content_side
		div.wrap
		div.right_side
		.top_content_left
		div.m_item
		span.article.synopsis {
		height: 130px;
	}

	#movies_section div.img_ext {
		height: 300px;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side h1 {
		font-size: 1.6em;
		line-height: 1.2em;
		margin-right: 0;
		margin-top: 1.8em;
	}

	#movies_section div.content_side div.wrap div.right_side div.pg span.obi {
		width: 38%;
	}

	#movies_section div.content_side div.wrap div.right_side div.pg {
		font-size: 0.85em;
		right: 0;
		text-align: right;
		top: 0em;
		width: 12em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side .watch_online {
		margin-right: 1em;
		margin-top: 1em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side .watch_online span.watch_span a {
		font-size: 0.8em;
	}

	#movies_section div.content_side div.wrap div.ext div.image_side div.stay_review span.review_click a {
		font-size: 0.7em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.off_web {
		margin-right: 1em;
		margin-top: 1em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.off_web a {
		font-size: 0.8em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side a.btn {
		font-size: 0.8em;
	}

	#movies_section div.ratings_sm {
		margin: 10px auto;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.leftside {
		margin-right: 1em;
		max-width: 185px;
		width: 29%;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside {
		width: 67%;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside .short_content {
		margin-bottom: 1em;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside .short_content p {
		margin-bottom: 1em;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside #about {
		font-size: 1.2em;
	}

	#more_product div.wrap div.product-item div.rightside .short_content {
		margin-bottom: 2em;
	}

	.product_single #product_section .content_side {
		top: 6.5em;
	}

	.product_single #product_section div.content_side .wrap .image_side .ext_img {
		position: relative;
		top: 2em;
	}

	.product_single #product_section .content_side .wrap .ext {
		padding: 1em 1em 0.8em;
	}

	.product_single #product_section div.content_side div.wrap div.right_side h1 {
		font-size: 1.6em;
		line-height: 1.2em;
		margin-right: 0;
		margin-top: 1.8em;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.pg span.obi {
		width: 38%;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.pg {
		font-size: 0.85em;
		right: 0;
		text-align: right;
		top: 0em;
		width: 12em;
	}

	.product_single #product_section a.btn {
		font-size: 0.8em;
		margin-right: 1em;
	}

	.product_single #product_section div.content_side .wrap .image_side {
		margin-top: 0;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.bottom_side div.treiler,
	.product_single #product_section div.content_side div.wrap div.right_side div.bottom_side div.buy {
		margin: 0;
	}

	#more_product div.wrap h1 {
		font-size: 3em;
	}

	#more_product div.wrap div.product-item {
		margin-right: 0;
		width: 100%;
	}

	#more_product div.wrap div.product-item div.leftside {
		margin-right: 2%;
		width: 25%;
	}

	#more_product div.wrap div.product-item div.rightside {
		width: 73%;
	}

	#behind_section div.wrap div#bsl div.item h1,
	.behind #more_like_this div.wrap div#bcm h1 {
		font-size: 3em;
		line-height: 1.2em;
	}

	#product_side div.wrap div.product-item {
		margin-right: 0;
		width: 100%;
	}

	#product_side div.wrap div.product-item div.leftside {
		width: 25%;
	}

	#product_side div.wrap div.product-item div.rightside {
		width: 73%;
	}

	#movies_section div.mobile_res {
		border-top: 7px solid #ea593b;
		border-bottom: 7px solid #029e93;
		float: left;
		width: 100%;
	}

	#movies_section div.mobile_res div.wrap {
		margin-top: 2em;
	}

	#movies_section div.mobile_res div.wrap .left_side {
		float: left;
		margin-right: 3%;
		width: 48.5%;
	}

	#movies_section div.mobile_res div.wrap .right_side {
		float: left;
		width: 48.5%;
	}

	#movies_section div.mobile_res div.wrap .m_item {
		color: #a9a9a9;
		font-family: "proxima_novalight";
		font-size: 1.143em;
		margin-bottom: 1.8em;
		width: 100%;
	}

	#movies_section div.mobile_res div.wrap .m_item .article {
		font-size: 0.9em;
	}

	#movies_section div.mobile_res div.wrap .m_item h4 {
		color: #fff;
		font-family: "proxima_novaextrabold";
		font-size: 1em;
		text-decoration: none;
		text-transform: uppercase;
	}

	.product_single div.img_ext {
		height: 300px;
	}

	.product_single .mobile_res {
		border-top: 7px solid #ea593b;
		border-bottom: 7px solid #029e93;
		float: left;
		width: 100%;
		background-color: #0c0c0c;
	}

	.product_single .mobile_res div.wrap {
		margin-top: 2em;
	}

	.product_single .mobile_res div.wrap div.m_item {
		color: #a9a9a9;
		font-family: "proxima_novalight";
		font-size: 1.143em;
		margin-bottom: 1.8em;
		width: 100%;
	}

	.product_single .mobile_res div.wrap div.m_item h4 {
		color: #fff;
		font-family: "proxima_novaextrabold";
		font-size: 1em;
		text-decoration: none;
		text-transform: uppercase;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.top_content_left {
		display: none;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.bottom_side a.btn {
		font-size: 0.8em;
	}

	#talent_highlight div.wrap div#bsl {
		padding-top: 4.8em;
		width: 100%;
	}

	#talent_highlight div.wrap div#bsl div.item h1 {
		display: block;
	}

	#talent_highlight div.wrap div#bsl div.item div.leftside {
		float: left;
		width: 100%;
	}

	#talent_highlight div.wrap div#bsl div.item div.rightside {
		margin-top: 2em;
		width: 100%;
	}

	#talent_highlight div.wrap div#bsl div.item div.leftside span.pop_up {
		width: 100%;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item {
		width: 100%;
	}

	#behind_section div.wrap div#bsl div.item div.leftside {
		width: 100%;
	}

	#behind_section div.wrap div#bsl div.item div.leftside a.pop_up {
		width: 100%;
	}

	#behind_section div.wrap div#bsl div.item div.rightside {
		margin-top: 2em;
		width: 100%;
	}

	.behind #more_like_this div.wrap div#bcm div.item {
		width: 100%;
	}

	.behind #more_like_this div.wrap div#bcm div.item div.rightside {
		width: 57%;
	}

	#red-carpet-event div.wrap {
		margin-top: 2em;
	}

	#red-carpet-event div.wrap div.img_side {
		background-color: #f8f8f8;
		float: left;
		margin-right: 0;
		width: auto;
		max-width: 100%;
	}

	#red-carpet-event div.wrap div.content_side,
	#red-carpet-event div.wrap div.content_side.full {
		margin-right: 0;
		width: 100%;
	}

	#red-carpet-event div.wrap div.content_side h1 {
		font-size: 3.2em;
		line-height: 1.1em;
		margin: 0.3em 0;
	}

	#red-carpet-event div.wrap div.form_side {
		float: left;
		width: 100%;
	}

	.single .site-content #primary.content-area .site-main .entry-header h1.entry-title {
		font-size: 2.4em;
		line-height: 1.1em;
	}

	#secondary.widget-area .widget h1.widget-title {
		font-size: 1.5em;
		margin-bottom: 0.5em;
		padding-bottom: 0.5em;
	}

	#secondary.widget-area .widget ul li {
		margin-bottom: 0.5em;
	}

	.archive .site-main .page-header h1.page-title {
		color: #029e93;
		font-size: 2.5em;
		font-style: italic;
	}

	.archive .site-main .page-header h1.page-title span {
		display: block;
		font-size: 1.5em;
		color: #272727;
		font-family: "proxima_nova_bold";
		font-weight: normal;
		font-style: normal;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.bs {
		float: left;
		width: 100%;
	}

	#overlay div#review-box {
		background-color: #fff;
		padding: 1.5em;
		position: absolute;
		width: 80%;
	}

	#overlay div#review-box a.review_box_close {
		// background: url("images/close_btn_rating.jpg") no-repeat scroll left top transparent;
		display: block;
		height: 32px;
		position: absolute;
		right: -5px;
		top: -5px;
		width: 31px;
	}

	#overlay div#review-box div.wrap div.external form#commentform h1 {
		color: #0c0c0c;
		font-family: "proxima_novablack";
		font-size: 3.57143em;
		text-transform: uppercase;
	}

	#overlay div#review-box div.wrap div.external form#commentform div.rateit {
		float: left;
		margin-right: 1.5em;
		margin-top: 1em;
	}

	#overlay div#review-box div.wrap div.external form#commentform div.rate_text {
		color: #717171;
		float: left;
		font-family: "proxima_nova_regular";
		font-size: 1.2em;
		margin: 0.5em 0 1.5em;
	}

	#overlay div#review-box div.wrap div.external form#commentform textarea#comment {
		// background: url("images/form_icon/4.png") no-repeat scroll 1.5em 1.5em #fff;
		border: 1px solid #cfcfcf;
		border-radius: 0;
		color: #747474;
		font-family: "proxima_nova_regular";
		height: 8.5em;
		padding: 1.3em 1em 0 3.9em;
		resize: none;
		width: 100%;
	}

	#overlay div#review-box div.wrap div.external form#commentform input {
		background-color: #fff;
		border: 1px solid #cfcfcf;
		border-radius: 0;
		color: #747474;
		float: left;
		font-family: "proxima_nova_regular";
		height: 3.8em;
		line-height: 3.8em;
		padding: 0 1em 0 3.9em;
		width: 49%;
	}

	#overlay div#review-box div.wrap div.external form#commentform input#author {
		// background: url("images/form_icon/1.png") no-repeat scroll 1.25em center #fff;
		margin-right: 2%;
	}

	#overlay div#review-box div.wrap div.external form#commentform input#email {
		// background: url("images/form_icon/2.png") no-repeat scroll 1.25em center #fff;
		margin-right: 0;
	}

	#overlay div#review-box div.wrap div.external form#commentform button.btn.form {
		left: 0em;
		top: 1em;
	}

	#video-box {
		background-color: #000;
		position: absolute;
		right: 0;
		top: 0;
		width: 32em;
		height: 21em;
	}
}

@media screen and (min-width: 640px) and (max-width: 666px) {
	.main-navigation {
		font-size: 0.8em;
	}

	.main-navigation .menu_inside h1 {
		font-size: 4em;
		margin-bottom: 0.5em;
		margin-top: 1em;
	}

	.main-navigation .menu_inside img#menu_close {
		width: 36px;
		right: 1.5em;
		top: 1.5em;
	}

	#masthead.site-header .wrap,
	.archive .site-content .wrap,
	.error404 .site-content .wrap,
	.single .site-content .wrap,
	.page .site-content .wrap,
	#partners div.wrap,
	#product_side div.wrap {
		width: 92%;
	}

	#movies_section div.mobile_res,
	#product_section div.mobile_res {
		display: block;
	}

	.btn span.right,
	.btn span.left {
		box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.13);
		display: inline-block;
		height: 3em;
		line-height: 3em;
		padding: 0 1em;
		transition: background-position 100ms ease 0s;
		vertical-align: top;
	}

	.btn span.right {
		padding: 0;
		transition: background-position 100ms ease 0s;
	}

	.btn {
		margin-bottom: 0.4em;
	}

	#homeslider .btn {
		width: 232px;
	}

	#homeslider .slider-for .slide_item .content_side div.wrap div.buttons {
		bottom: 8%;
		right: 0;
		position: absolute;
	}

	#homeslider .slider-for .slide_item .wrap {
		width: 92%;
	}

	#homeslider .slider-for .slide_item div.img_ext {
		height: 281px;
	}

	#homeslider .slider-for .slide_item .wrap h1 {
		bottom: 50%;
		font-size: 2.1em;
		line-height: 1.1em;
		width: 60%;
	}

	#homeslider .slider-nav {
		width: 96%;
	}

	.slick-slider {
		margin-bottom: 5px;
	}

	// #homeslider .slide_thumbnail_item.slick-center {
	// 	background: transparent url("images/slider/slide_thumb_activeline768.png") no-repeat scroll center top;
	// }

	#homeslider .slide_thumbnail_item {
		padding-top: 0.8em;
	}

	#newsletter div.wrap div.art span#bld {
		display: inline-block;
	}

	#newsletter div.wrap {
		width: 90%;
	}

	#newsletter div.wrap div.art {
		font-size: 2.1em;
		padding: 1.55em 0 0;
		text-align: center;
		width: 100%;
	}

	#newsletter div.wrap span.group {
		display: block;
		float: left;
		margin: 0 auto 3em;
		position: relative;
		width: 100%;
	}

	#newsletter div.wrap div.newsletter {
		display: block;
		float: none;
		margin: 2em auto 0;
		position: relative;
		width: 530px;
	}

	#newsletter div.wrap div.newsletter div.head,
	#newsletter div.wrap div.singup_facebook div.head {
		font-size: 1.35em;
	}

	#newsletter div.wrap div.newsletter form input.newsletter-email {
		height: 3.7em;
		margin-top: 1px;
		width: 28em;
		// background: url("images/form_icon/2.png") no-repeat scroll 1.6em 1em #fff;
	}

	#newsletter .btn span.left {
		width: 9em;
	}

	#newsletter span.or {
		margin: 4.1em 1em 1em;
	}

	#newsletter div.wrap div.singup_facebook span#facebook_login {
		display: inline-block;
	}

	#newsletter div.wrap div.singup_facebook {
		clear: both;
		float: left;
		margin-bottom: 2.5em;
		margin-top: 1em;
	}

	#latest_article .item {
		float: left;
		margin-right: 8px;
		width: 32%;
	}

	#latest_article .wrap {
		margin-top: 3em;
	}

	#latest_article h1 {
		font-size: 3.5em;
	}

	#latest_article span#stay_connected {
		font-size: 1.2em;
		top: 1.3em;
		right: 2%;
		display: none;
	}

	#latest_article div.wrap div.borderline {
		margin: 2.5em 0 3.2em;
		width: 96%;
	}

	#our_movies div.wrap {
		margin-top: 2em;
		margin-bottom: 0.5em;
	}

	#our_movies div.wrap h1 {
		font-size: 3em;
	}

	#our_movies div.wrap div.head div.toggle {
		bottom: 0;
		float: right;
		position: relative;
		right: 0;
	}

	#our_movies div.wrap div.head div.toggle span {
		font-size: 1.1em;
	}

	#our_movies div.wrap .movie_itm div.item h3 {
		font-size: 1em;
	}

	#social_feed div.wrap h1 {
		font-size: 3em;
	}

	#talebeh .talent {
		float: left;
		margin-right: 0;
		width: 100%;
	}

	#talebeh .talent h1 {
		font-size: 3em;
	}

	#talebeh .talent div.item div.rightside #about {
		font-size: 1.2em;
	}

	#talebeh .talent div.item div.leftside {
		max-width: 185px;
		width: 29%;
	}

	#talebeh .talent div.item div.rightside {
		width: 68%;
	}

	#talebeh .behinds {
		float: left;
		width: 100%;
	}

	#talebeh .behinds h1 {
		font-size: 3em;
	}

	#store {
		margin-bottom: 3em;
	}

	#store div.wrap .wrap_store {
		margin-right: 0;
		width: 100%;
	}

	#store div.wrap div.product-item div.leftside {
		width: 25%;
	}

	#store div.wrap div.product-item div.rightside {
		width: 73%;
	}

	#store div.wrap h1 {
		font-size: 3em;
	}

	#store div.wrap div.product-item div.rightside a.store_link {
		font-size: 1em;
		margin: 0.75em 0 0 1.5em;
	}

	#store div.wrap div.product-item .borderline {
		margin: 1em 0 1em;
	}

	#store div.wrap div.store_form {
		width: 100%;
	}

	#store div.wrap div.store_form h1 {
		font-size: 1.8em;
		line-height: 1.35em;
	}

	#store div.wrap div.store_form div.form_side {
		height: 18.7em;
		padding: 1em 1.5em;
	}

	#store div.wrap div.store_form div.form_side .desc {
		font-size: 1.2em;
		line-height: 1.2em;
		margin: 0.2em 0 1.3em;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper div.itm {
		margin-bottom: 1.5em;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper div.itm textarea.comment {
		width: 100%;
		height: 8em;
		// background: url("images/form_icon/4.png") no-repeat scroll 1.5em 1.4em #fff;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper input.inout {
		height: 3.5em;
		line-height: 3.5em;
		margin-right: 1% !important;
		width: 32%;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper input.inout.email {
		margin-right: 1%;
	}

	button.btn.form {
		left: 0em;
		top: 0em;
	}

	#idea_form .btn.form span.left {
		font-size: 0.8em;
		height: 3.5em;
		line-height: 3.5em;
		padding: 0 0.5em;
		width: 12em;
	}

	#idea_form .btn.form span.right {
		font-size: 0.8em;
		height: 3.5em;
		line-height: 3.5em;
	}

	#red_carpet div.wrap div.item {
		width: 100%;
	}

	#red_carpet.all_page div.wrap div.item {
		width: 100%;
		display: block;
		margin-bottom: 1em;
	}

	#red_carpet div.wrap a#all_events {
		font-size: 0.3em;
		right: 0.5em;
		top: 3em;
	}

	#red_carpet div.wrap div.item.first {
		width: 100%;
		display: block;
	}

	#red_carpet div.wrap div.item.noimage {
		width: 100%;
	}

	#red_carpet div.wrap div.item div.content_side div.date {
		margin: 0.5em 0 0.6em;
	}

	#red_carpet div.wrap h1 {
		font-size: 3.5em;
	}

	#red_carpet div.wrap div.item div.content_side h3 {
		font-size: 1.5em;
		margin-bottom: 0.5em;
	}

	#red_carpet div.wrap div.item div.img_side {
		float: left;
		width: 39%;
	}

	#red_carpet div.wrap div.item div.content_side {
		background-color: #f8f8f8;
		float: left;
		padding: 1em 1.4em 1.4em;
		width: 61%;
		min-height: 220px;
	}

	#red_carpet div.wrap div.item div.content_side div.link {
		margin-top: 1em;
	}

	#red_carpet div.wrap div.item div.content_side div.link a.more {
		font-size: 1em;
	}

	#partners {
		height: 12em;
	}

	#partners div.wrap div.head {
		display: none;
	}

	#partners div.wrap div.sponsor {
		margin-left: 1em;
	}

	#partners div.wrap div.sponsor.s1 {
		width: 9.7em;
		margin-top: 3.5em;
	}

	#partners div.wrap div.sponsor.s2 {
		width: 7em;
		margin-top: 2.5em;
	}

	#partners div.wrap div.sponsor.s3 {
		width: 9.7em;
		margin-top: 4.2em;
	}

	#partners div.wrap div.sponsor.s4 {
		width: 10.4em;
		margin-left: 1em;
		margin-top: 3.8em;
	}

	.site-footer {
		height: 20em;
	}

	.site-footer div.site-info {
		margin-top: 4em;
	}

	.movies_single #movies_section .content_side {
		top: 0;
	}

	.movies_single #movies_section .content_side .wrap {
		width: 92%;
	}

	.movies_single #movies_section .content_side .wrap .ext {
		padding: 1em 1em 0.8em;
	}

	.movies_single #movies_section div.content_side .wrap .image_side .ext_img {
		position: relative;
		top: 0em;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side .topside {
		margin-bottom: 1em;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side div.top_content_left {
		margin-right: 0;
		width: 100%;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side div.top_content_left,
	.movies_single #movies_section div.content_side div.wrap div.right_side div.top_content_right {
		display: none;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side div.m_item.synoptis {
		margin-bottom: 0em;
	}

	.movies_single
		#movies_section
		div.content_side
		div.wrap
		div.right_side
		.top_content_left
		div.m_item
		span.article.synopsis {
		height: 130px;
	}

	#movies_section div.img_ext {
		height: 300px;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side h1 {
		font-size: 1.6em;
		line-height: 1.2em;
		margin-right: 0;
		margin-top: 1.8em;
	}

	#movies_section div.content_side div.wrap div.right_side div.pg span.obi {
		width: 38%;
	}

	#movies_section div.content_side div.wrap div.right_side div.pg {
		font-size: 0.85em;
		right: 0;
		text-align: right;
		top: 0em;
		width: 12em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side .watch_online {
		margin-right: 1em;
		margin-top: 1em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side .watch_online span.watch_span a {
		font-size: 0.8em;
	}

	#movies_section div.content_side div.wrap div.ext div.image_side div.stay_review span.review_click a {
		font-size: 0.6em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.off_web {
		margin-right: 1em;
		margin-top: 1em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.off_web a {
		font-size: 0.8em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side a.btn {
		font-size: 0.8em;
	}

	#movies_section div.mobile_res div.wrap .m_item .article {
		font-size: 0.9em;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.leftside {
		margin-right: 1em;
		max-width: 185px;
		width: 29%;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside {
		width: 67%;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside .short_content {
		margin-bottom: 1em;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside .short_content p {
		margin-bottom: 1em;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside #about {
		font-size: 1.2em;
	}

	#more_product div.wrap div.product-item div.rightside .short_content {
		margin-bottom: 2em;
	}

	.product_single #product_section .content_side {
		top: 6.5em;
	}

	.product_single #product_section div.content_side .wrap .image_side .ext_img {
		position: relative;
		top: 2em;
	}

	.product_single #product_section .content_side .wrap .ext {
		padding: 1em 1em 0.8em;
	}

	.product_single #product_section div.content_side div.wrap div.right_side h1 {
		font-size: 1.6em;
		line-height: 1.2em;
		margin-right: 0;
		margin-top: 1.8em;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.pg span.obi {
		width: 38%;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.pg {
		font-size: 0.85em;
		right: 0;
		text-align: right;
		top: 0em;
		width: 12em;
	}

	.product_single #product_section a.btn {
		font-size: 0.8em;
		margin-right: 1em;
	}

	.product_single #product_section div.content_side .wrap .image_side {
		margin-top: 0;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.bottom_side div.treiler,
	.product_single #product_section div.content_side div.wrap div.right_side div.bottom_side div.buy {
		margin: 0;
	}

	#more_product div.wrap h1 {
		font-size: 3em;
	}

	#more_product div.wrap div.product-item {
		margin-right: 0;
		width: 100%;
	}

	#more_product div.wrap div.product-item div.leftside {
		margin-right: 2%;
		width: 25%;
	}

	#more_product div.wrap div.product-item div.rightside {
		width: 73%;
	}

	#behind_section div.wrap div#bsl div.item h1,
	.behind #more_like_this div.wrap div#bcm h1 {
		font-size: 3em;
		line-height: 1.2em;
	}

	#product_side div.wrap div.product-item {
		margin-right: 0;
		width: 100%;
	}

	#product_side div.wrap div.product-item div.leftside {
		width: 25%;
	}

	#product_side div.wrap div.product-item div.rightside {
		width: 73%;
	}

	#movies_section div.mobile_res {
		border-top: 7px solid #ea593b;
		border-bottom: 7px solid #029e93;
		float: left;
		width: 100%;
	}

	#movies_section div.mobile_res div.wrap {
		margin-top: 2em;
	}

	#movies_section div.mobile_res div.wrap .left_side {
		float: left;
		margin-right: 3%;
		width: 48.5%;
	}

	#movies_section div.mobile_res div.wrap .right_side {
		float: left;
		width: 48.5%;
	}

	#movies_section div.mobile_res div.wrap .m_item {
		color: #a9a9a9;
		font-family: "proxima_novalight";
		font-size: 1.143em;
		margin-bottom: 1.8em;
		width: 100%;
	}

	#movies_section div.mobile_res div.wrap .m_item h4 {
		color: #fff;
		font-family: "proxima_novaextrabold";
		font-size: 1em;
		text-decoration: none;
		text-transform: uppercase;
	}

	.product_single div.img_ext {
		height: 300px;
	}

	.product_single .mobile_res {
		border-top: 7px solid #ea593b;
		border-bottom: 7px solid #029e93;
		float: left;
		width: 100%;
		background-color: #0c0c0c;
	}

	.product_single .mobile_res div.wrap {
		margin-top: 2em;
	}

	.product_single .mobile_res div.wrap div.m_item {
		color: #a9a9a9;
		font-family: "proxima_novalight";
		font-size: 1.143em;
		margin-bottom: 1.8em;
		width: 100%;
	}

	.product_single .mobile_res div.wrap div.m_item h4 {
		color: #fff;
		font-family: "proxima_novaextrabold";
		font-size: 1em;
		text-decoration: none;
		text-transform: uppercase;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.top_content_left {
		display: none;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.bottom_side a.btn {
		font-size: 0.8em;
	}

	#talent_highlight div.wrap div#bsl {
		padding-top: 4.8em;
		width: 100%;
	}

	#talent_highlight div.wrap div#bsl div.item h1 {
		display: block;
	}

	#talent_highlight div.wrap div#bsl div.item div.leftside {
		float: left;
		width: 100%;
	}

	#talent_highlight div.wrap div#bsl div.item div.rightside {
		margin-top: 2em;
		width: 100%;
	}

	#talent_highlight div.wrap div#bsl div.item div.leftside span.pop_up {
		width: 100%;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item {
		width: 100%;
	}

	#behind_section div.wrap div#bsl div.item div.leftside {
		width: 100%;
	}

	#behind_section div.wrap div#bsl div.item div.leftside a.pop_up {
		width: 100%;
	}

	#behind_section div.wrap div#bsl div.item div.rightside {
		margin-top: 2em;
		width: 100%;
	}

	.behind #more_like_this div.wrap div#bcm div.item {
		width: 100%;
	}

	.behind #more_like_this div.wrap div#bcm div.item div.rightside {
		width: 57%;
	}

	#red-carpet-event div.wrap {
		margin-top: 2em;
	}

	#red-carpet-event div.wrap div.img_side {
		background-color: #f8f8f8;
		float: left;
		margin-right: 0;
		width: auto;
		max-width: 100%;
	}

	#red-carpet-event div.wrap div.content_side,
	#red-carpet-event div.wrap div.content_side.full {
		margin-right: 0;
		width: 100%;
	}

	#red-carpet-event div.wrap div.content_side h1 {
		font-size: 3.2em;
		line-height: 1.1em;
		margin: 0.3em 0;
	}

	#red-carpet-event div.wrap div.form_side {
		float: left;
		width: 100%;
	}

	.single .site-content #primary.content-area .site-main .entry-header h1.entry-title {
		font-size: 2.4em;
		line-height: 1.1em;
	}

	#secondary.widget-area .widget h1.widget-title {
		font-size: 1.5em;
		margin-bottom: 0.5em;
		padding-bottom: 0.5em;
	}

	#secondary.widget-area .widget ul li {
		margin-bottom: 0.5em;
	}

	.archive .site-main .page-header h1.page-title {
		color: #029e93;
		font-size: 2.5em;
		font-style: italic;
	}

	.archive .site-main .page-header h1.page-title span {
		display: block;
		font-size: 1.5em;
		color: #272727;
		font-family: "proxima_nova_bold";
		font-weight: normal;
		font-style: normal;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.bs {
		float: left;
		width: 100%;
	}

	#overlay div#review-box {
		background-color: #fff;
		padding: 1.5em;
		position: absolute;
		width: 80%;
	}

	#overlay div#review-box a.review_box_close {
		// background: url("images/close_btn_rating.jpg") no-repeat scroll left top transparent;
		display: block;
		height: 32px;
		position: absolute;
		right: -5px;
		top: -5px;
		width: 31px;
	}

	#overlay div#review-box div.wrap div.external form#commentform h1 {
		color: #0c0c0c;
		font-family: "proxima_novablack";
		font-size: 2.5em;
		text-transform: uppercase;
	}

	#overlay div#review-box div.wrap div.external form#commentform div.rateit {
		float: left;
		margin-right: 1.5em;
		margin-top: 1em;
	}

	#overlay div#review-box div.wrap div.external form#commentform div.rate_text {
		color: #717171;
		float: left;
		font-family: "proxima_nova_regular";
		font-size: 1em;
		margin: 1em 0 1.5em;
	}

	#overlay div#review-box div.wrap div.external form#commentform textarea#comment {
		// background: url("images/form_icon/4.png") no-repeat scroll 1.5em 1.5em #fff;
		border: 1px solid #cfcfcf;
		border-radius: 0;
		color: #747474;
		font-family: "proxima_nova_regular";
		height: 8.5em;
		padding: 1.3em 1em 0 3.9em;
		resize: none;
		width: 100%;
	}

	#overlay div#review-box div.wrap div.external form#commentform input {
		background-color: #fff;
		border: 1px solid #cfcfcf;
		border-radius: 0;
		color: #747474;
		float: left;
		font-family: "proxima_nova_regular";
		height: 3.8em;
		line-height: 3.8em;
		padding: 0 1em 0 3.9em;
		width: 49%;
	}

	#overlay div#review-box div.wrap div.external form#commentform input#author {
		// background: url("images/form_icon/1.png") no-repeat scroll 1.25em center #fff;
		margin-right: 2%;
	}

	#overlay div#review-box div.wrap div.external form#commentform input#email {
		// background: url("images/form_icon/2.png") no-repeat scroll 1.25em center #fff;
		margin-right: 0;
	}

	#overlay div#review-box div.wrap div.external form#commentform button.btn.form {
		left: 0em;
		top: 1em;
	}

	#video-box {
		background-color: #000;
		position: absolute;
		right: 0;
		top: 0;
		width: 30em;
		height: 20em;
	}
}

@media screen and (min-width: 568px) and (max-width: 639px) {
	.main-navigation {
		font-size: 0.76em;
	}

	.main-navigation .menu_inside h1 {
		font-size: 4em;
		margin-bottom: 0.5em;
		margin-top: 1em;
	}

	.main-navigation li {
		margin-bottom: 1.5em;
	}

	.main-navigation .menu_inside img#menu_close {
		width: 32px;
		right: 1.5em;
		top: 1.5em;
	}

	#masthead.site-header .wrap,
	.archive .site-content .wrap,
	.error404 .site-content .wrap,
	.single .site-content .wrap,
	.page .site-content .wrap,
	#partners div.wrap,
	#product_side div.wrap {
		width: 92%;
	}

	#masthead.site-header div.wrap div.social span#ttl {
		display: none;
	}

	#movies_section div.mobile_res,
	#product_section div.mobile_res {
		display: block;
	}

	.btn span.right,
	.btn span.left {
		box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.13);
		display: inline-block;
		height: 3em;
		line-height: 3em;
		padding: 0 1em;
		transition: background-position 100ms ease 0s;
		vertical-align: top;
	}

	.btn span.right {
		padding: 0;
		transition: background-position 100ms ease 0s;
	}

	.btn {
		margin-bottom: 0.6em;
		font-size: 0.9em;
	}

	#homeslider .slider-for .slide_item .content_side div.wrap div.buttons {
		bottom: 8%;
		right: 0;
		position: absolute;
	}

	#homeslider .slider-for .slide_item .wrap {
		width: 92%;
	}

	#homeslider .slider-for .slide_item div.img_ext {
		height: 249px;
	}

	#homeslider .slider-for .slide_item .wrap h1 {
		bottom: 48%;
		font-size: 2em;
		line-height: 1.1em;
		width: 60%;
	}

	#homeslider .slider-nav {
		width: 96%;
	}

	#homeslider .btn {
		width: 195px;
	}

	#homeslider .slide_thumbnail_item.slick-center {
		// background: transparent url("images/slider/slide_thumb_activeline768.png") no-repeat scroll center top;
	}

	#homeslider .slide_thumbnail_item {
		padding-top: 0.8em;
	}

	.slick-slider {
		margin-bottom: 5px;
	}

	#newsletter div.wrap {
		width: 90%;
	}

	#newsletter div.wrap div.art span#bld {
		display: inline-block;
	}

	#newsletter div.wrap div.art {
		font-size: 2.1em;
		padding: 1.55em 0 0;
		text-align: center;
		width: 100%;
	}

	#newsletter div.wrap span.group {
		display: block;
		float: left;
		margin: 0 auto 3em;
		position: relative;
		width: 100%;
	}

	#newsletter div.wrap div.newsletter {
		display: block;
		float: none;
		margin: 2em auto 0;
		position: relative;
		width: 440px;
	}

	#newsletter div.wrap div.newsletter div.head,
	#newsletter div.wrap div.singup_facebook div.head {
		font-size: 1.35em;
	}

	#newsletter div.wrap div.newsletter form input.newsletter-email {
		height: 3.1em;
		margin-top: 1px;
		width: 23em;
		// background: url("images/form_icon/2.png") no-repeat scroll 1.6em 1em #fff;
	}

	#newsletter .btn span.left {
		width: 9em;
	}

	#newsletter span.or {
		margin: 4.1em 1em 1em;
	}

	#newsletter div.wrap div.singup_facebook span#facebook_login {
		display: inline-block;
	}

	#newsletter div.wrap div.singup_facebook {
		clear: both;
		float: left;
		margin-bottom: 2.5em;
		margin-top: 1em;
	}

	#latest_article .item {
		float: left;
		margin-right: 0%;
		width: 100%;
	}

	#latest_article .wrap {
		margin-top: 3em;
	}

	#latest_article h1 {
		font-size: 3.5em;
	}

	#latest_article span#stay_connected {
		font-size: 1.2em;
		top: 1.3em;
		right: 2%;
		display: none;
	}

	#latest_article div.wrap div.borderline {
		margin: 2.5em 0 3.2em;
		width: 96%;
	}

	#our_movies div.wrap {
		margin-top: 3em;
		margin-bottom: 0.5em;
	}

	#our_movies div.wrap h1 {
		font-size: 3.5em;
	}

	#our_movies div.wrap div.head div.toggle {
		bottom: 0;
		float: right;
		position: relative;
		right: 0;
		width: 100%;
	}

	#our_movies div.wrap div.head div.toggle span {
		font-size: 1.1em;
		margin-right: 1%;
		margin-left: 0;
		padding: 0.95em 0;
		width: 24.25%;
		text-align: center;
	}

	#our_movies div.wrap div.head div.toggle span#upcoming {
		margin-right: 0%;
	}

	#our_movies div.wrap .movie_itm div.item h3 {
		font-size: 1em;
	}

	#social_feed div.wrap h1 {
		font-size: 3em;
	}

	#talebeh .talent {
		float: left;
		margin-right: 0;
		width: 100%;
	}

	#talebeh .talent h1 {
		font-size: 3em;
	}

	#talebeh .talent div.item div.rightside #about {
		font-size: 1.2em;
	}

	#talebeh .talent div.item div.leftside {
		max-width: 185px;
		width: 37%;
	}

	#talebeh .talent div.item div.rightside {
		width: 60%;
	}

	#talebeh .behinds {
		float: left;
		width: 100%;
	}

	#talebeh .behinds h1 {
		font-size: 3em;
	}

	#talebeh .behinds div#bcm div.item div.rightside article.short_content {
		display: none;
	}

	#store {
		margin-bottom: 3em;
	}

	#store div.wrap .wrap_store {
		margin-right: 0;
		width: 100%;
	}

	#store div.wrap div.product-item div.leftside {
		width: 30%;
	}

	#store div.wrap div.product-item div.rightside {
		width: 68%;
	}

	#store div.wrap h1 {
		font-size: 3em;
	}

	#store div.wrap div.product-item div.rightside a.store_link {
		font-size: 1em;
		margin: 0.75em 0 0 1.5em;
	}

	#store div.wrap div.product-item .borderline {
		margin: 1em 0 1em;
	}

	#store div.wrap div.store_form {
		width: 100%;
	}

	#store div.wrap div.store_form h1 {
		font-size: 1.8em;
		line-height: 1.35em;
	}

	#store div.wrap div.store_form div.form_side {
		height: 23em;
		padding: 1em 1.5em;
	}

	#store div.wrap div.store_form div.form_side .desc {
		font-size: 1.2em;
		line-height: 1.2em;
		margin: 0.2em 0 1.3em;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper div.itm {
		margin-bottom: 1.5em;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper div.itm textarea.comment {
		width: 100%;
		height: 8em;
		// background: url("images/form_icon/4.png") no-repeat scroll 1.5em 1.4em #fff;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper input.inout {
		height: 3.5em;
		line-height: 3.5em;
		margin-right: 2%;
		width: 49%;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper input.inout.email {
		margin-right: 0%;
	}

	#store div.wrap div.store_form div.form_side form#idea_form button.btn.form {
		top: 1em;
	}

	#idea_form .btn.form span.left {
		font-size: 0.8em;
		height: 3.5em;
		line-height: 3.5em;
		padding: 0 0.5em;
		width: 12em;
	}

	#idea_form .btn.form span.right {
		font-size: 0.8em;
		height: 3.5em;
		line-height: 3.5em;
	}

	#red_carpet {
		margin-bottom: 1em;
	}

	#red_carpet div.wrap div.item {
		width: 100%;
	}

	#red_carpet.all_page div.wrap div.item {
		width: 100%;
		display: block;
		margin-bottom: 1em;
	}

	#red_carpet div.wrap a#all_events {
		font-size: 0.3em;
		right: 0.5em;
		top: 3em;
	}

	#red_carpet div.wrap div.item.first {
		width: 100%;
		display: block;
	}

	#red_carpet div.wrap div.item.noimage {
		width: 100%;
	}

	#red_carpet div.wrap div.item div.content_side div.date {
		margin: 0.5em 0 0.6em;
	}

	#red_carpet div.wrap h1 {
		font-size: 3.5em;
	}

	#red_carpet div.wrap div.item div.content_side h3 {
		font-size: 1.5em;
		margin-bottom: 0.5em;
	}

	#red_carpet div.wrap div.item div.img_side {
		float: left;
		width: 39%;
	}

	#red_carpet div.wrap div.item div.content_side {
		background-color: #f8f8f8;
		float: left;
		padding: 1em 1.4em 1.4em;
		width: 61%;
		min-height: 220px;
	}

	#red_carpet div.wrap div.item div.content_side div.link {
		margin-top: 1em;
	}

	#red_carpet div.wrap div.item div.content_side div.link a.more {
		font-size: 1em;
	}

	#partners {
		height: 10em;
	}

	#partners div.wrap div.head {
		display: none;
	}

	#partners div.wrap div.sponsor {
		margin-left: 1em;
	}

	#partners div.wrap div.sponsor.s1 {
		width: 9em;
		margin-top: 3em;
	}

	#partners div.wrap div.sponsor.s2 {
		width: 6em;
		margin-top: 2.1em;
	}

	#partners div.wrap div.sponsor.s3 {
		width: 8em;
		margin-top: 3.6em;
	}

	#partners div.wrap div.sponsor.s4 {
		width: 9em;
		margin-left: 1em;
		margin-top: 3em;
	}

	.site-footer {
		height: 20em;
	}

	.site-footer div.site-info {
		margin-top: 4em;
	}

	/*********************************************/

	.movies_single #movies_section .content_side {
		top: 0;
	}

	.movies_single #movies_section .content_side .wrap {
		width: 92%;
	}

	.movies_single #movies_section .content_side .wrap .ext {
		padding: 1em 1em 0.8em;
	}

	.movies_single #movies_section div.content_side .wrap .image_side .ext_img {
		position: relative;
		top: 0em;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side .topside {
		margin-bottom: 1em;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side div.top_content_left {
		margin-right: 0;
		width: 100%;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side div.top_content_left,
	.movies_single #movies_section div.content_side div.wrap div.right_side div.top_content_right {
		display: none;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side div.m_item.synoptis {
		margin-bottom: 0em;
	}

	.movies_single
		#movies_section
		div.content_side
		div.wrap
		div.right_side
		.top_content_left
		div.m_item
		span.article.synopsis {
		height: 130px;
	}

	#movies_section div.img_ext {
		height: 300px;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side h1 {
		font-size: 1.4em;
		line-height: 1.2em;
		margin-right: 0;
		margin-top: 1.8em;
	}

	#movies_section div.content_side div.wrap div.right_side div.pg span.obi {
		width: 38%;
	}

	#movies_section div.content_side div.wrap div.right_side div.pg {
		font-size: 0.85em;
		right: 0;
		text-align: right;
		top: 0em;
		width: 12em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side .watch_online {
		margin-right: 1em;
		margin-top: 1em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side .watch_online span.watch_span a {
		font-size: 0.8em;
	}

	#movies_section div.content_side div.wrap div.ext div.image_side div.stay_review span.review_click a {
		font-size: 0.6em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.off_web {
		margin-right: 1em;
		margin-top: 1em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.off_web a {
		font-size: 0.8em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side a.btn {
		font-size: 0.8em;
	}

	#movies_section div.mobile_res div.wrap .m_item .article {
		font-size: 0.9em;
	}

	#movies_section div.ratings_sm {
		margin: 10px auto;
	}

	#movies_section div.ratings_sm .read_rew {
		font-size: 10px;
	}

	.product_single #product_section .content_side {
		top: 6.5em;
	}

	.product_single #product_section div.content_side .wrap .image_side .ext_img {
		position: relative;
		top: 2em;
	}

	.product_single #product_section .content_side .wrap .ext {
		padding: 1em 1em 0.8em;
	}

	.product_single #product_section div.content_side div.wrap div.right_side h1 {
		font-size: 1.4em;
		line-height: 1.2em;
		margin-right: 0;
		margin-top: 1.8em;
	}

	.product_single #product_section div.content_side div.wrap div.right_side .topside {
		margin-bottom: 1.4em;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.pg span.obi {
		width: 38%;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.pg {
		font-size: 0.85em;
		right: 0;
		text-align: right;
		top: 0em;
		width: 12em;
	}

	.product_single #product_section a.btn {
		font-size: 0.7em;
		margin-right: 1em;
	}

	.product_single #product_section div.content_side .wrap .image_side {
		margin-top: 0;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.bottom_side div.treiler,
	.product_single #product_section div.content_side div.wrap div.right_side div.bottom_side div.buy {
		margin: 0;
	}

	.product_single #product_section div.bottom_side div.price p.price span.heads {
		font-size: 1.2em;
	}

	.product_single #product_section div.bottom_side div.price p.price span.amount {
		font-size: 2em;
	}
	/**********************************************/

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.leftside {
		margin-right: 1em;
		max-width: 185px;
		width: 29%;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside {
		width: 67%;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside .short_content {
		margin-bottom: 1em;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside .short_content p {
		margin-bottom: 1em;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside #about {
		font-size: 1.2em;
	}

	#more_product div.wrap h1 {
		font-size: 3em;
	}

	#more_product div.wrap div.product-item {
		margin-right: 0;
		width: 100%;
	}

	#more_product div.wrap div.product-item div.leftside {
		margin-right: 2%;
		width: 25%;
	}

	#more_product div.wrap div.product-item div.rightside {
		width: 73%;
	}

	#behind_section div.wrap div#bsl div.item h1,
	.behind #more_like_this div.wrap div#bcm h1 {
		font-size: 3em;
		line-height: 1.2em;
	}

	#product_side div.wrap div.product-item {
		margin-right: 0;
		width: 100%;
	}

	#product_side div.wrap div.product-item div.leftside {
		width: 25%;
	}

	#product_side div.wrap div.product-item div.rightside {
		width: 73%;
	}

	#movies_section div.mobile_res {
		border-top: 7px solid #ea593b;
		border-bottom: 7px solid #029e93;
		float: left;
		width: 100%;
	}

	#movies_section div.mobile_res div.wrap {
		margin-top: 2em;
	}

	#movies_section div.mobile_res div.wrap .left_side {
		float: left;
		margin-right: 3%;
		width: 48.5%;
	}

	#movies_section div.mobile_res div.wrap .right_side {
		float: left;
		width: 48.5%;
	}

	#movies_section div.mobile_res div.wrap .m_item {
		color: #a9a9a9;
		font-family: "proxima_novalight";
		font-size: 1.143em;
		margin-bottom: 1.8em;
		width: 100%;
	}

	#movies_section div.mobile_res div.wrap .m_item h4 {
		color: #fff;
		font-family: "proxima_novaextrabold";
		font-size: 1em;
		text-decoration: none;
		text-transform: uppercase;
	}

	.product_single div.img_ext {
		height: 400px;
	}

	.product_single .mobile_res {
		border-top: 7px solid #ea593b;
		border-bottom: 7px solid #029e93;
		float: left;
		width: 100%;
		background-color: #0c0c0c;
	}

	.product_single .mobile_res div.wrap {
		margin-top: 2em;
	}

	.product_single .mobile_res div.wrap div.m_item {
		color: #a9a9a9;
		font-family: "proxima_novalight";
		font-size: 1.143em;
		margin-bottom: 1.8em;
		width: 100%;
	}

	.product_single .mobile_res div.wrap div.m_item h4 {
		color: #fff;
		font-family: "proxima_novaextrabold";
		font-size: 1em;
		text-decoration: none;
		text-transform: uppercase;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.top_content_left {
		display: none;
	}

	.product_single #product_section div.bottom_side div.price {
		margin-right: 2em;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.bottom_side a.btn {
		font-size: 0.7em;
	}

	#talent_highlight div.wrap div#bsl {
		padding-top: 4.8em;
		width: 100%;
	}

	#talent_highlight div.wrap div#bsl div.item h1 {
		display: block;
	}

	#talent_highlight div.wrap div#bsl div.item div.leftside {
		float: left;
		width: 100%;
	}

	#talent_highlight div.wrap div#bsl div.item div.rightside {
		margin-top: 2em;
		width: 100%;
	}

	#talent_highlight div.wrap div#bsl div.item div.leftside span.pop_up {
		width: 100%;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item {
		width: 100%;
	}

	#behind_section div.wrap div#bsl div.item div.leftside {
		width: 100%;
	}

	#behind_section div.wrap div#bsl div.item div.leftside a.pop_up {
		width: 100%;
	}

	#behind_section div.wrap div#bsl div.item div.rightside {
		margin-top: 2em;
		width: 100%;
	}

	.behind #more_like_this div.wrap div#bcm div.item {
		width: 100%;
	}

	.behind #more_like_this div.wrap div#bcm div.item div.rightside {
		width: 57%;
	}

	.behind #more_like_this div.wrap div#bcm div.item div.rightside .short_content {
		display: none;
	}

	#red-carpet-event div.wrap {
		margin-top: 2em;
	}

	#red-carpet-event div.wrap div.img_side {
		background-color: #f8f8f8;
		float: left;
		margin-right: 0;
		width: auto;
		max-width: 100%;
	}

	#red-carpet-event div.wrap div.content_side,
	#red-carpet-event div.wrap div.content_side.full {
		margin-right: 0;
		width: 100%;
	}

	#red-carpet-event div.wrap div.content_side h1 {
		font-size: 3.2em;
		line-height: 1.1em;
		margin: 0.3em 0;
	}

	#red-carpet-event div.wrap div.form_side {
		float: left;
		width: 100%;
	}

	#primary.content-area {
		float: left;
		margin-right: 0;
		width: 100% !important;
	}

	#secondary.widget-area {
		float: left;
		margin-top: 2.05em;
		width: 100% !important;
	}

	.single .site-content #primary.content-area .site-main .entry-header h1.entry-title {
		font-size: 2.4em;
		line-height: 1.1em;
	}

	#secondary.widget-area .widget h1.widget-title {
		font-size: 1.5em;
		margin-bottom: 0.5em;
		padding-bottom: 0.5em;
	}

	#secondary.widget-area .widget ul li {
		margin-bottom: 0.5em;
	}

	.archive .site-main .page-header h1.page-title {
		color: #029e93;
		font-size: 2em;
		font-style: italic;
	}

	.archive .site-main .page-header h1.page-title span {
		display: block;
		font-size: 1.5em;
		color: #272727;
		font-family: "proxima_nova_bold";
		font-weight: normal;
		font-style: normal;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.bs {
		float: left;
		width: 100%;
	}

	#overlay div#review-box {
		background-color: #fff;
		padding: 1.5em;
		position: absolute;
		width: 80%;
	}

	#overlay div#review-box a.review_box_close {
		// background: url("images/close_btn_rating.jpg") no-repeat scroll left top transparent;
		display: block;
		height: 32px;
		position: absolute;
		right: -5px;
		top: -5px;
		width: 31px;
	}

	#overlay div#review-box div.wrap div.external form#commentform h1 {
		color: #0c0c0c;
		font-family: "proxima_novablack";
		font-size: 2.5em;
		text-transform: uppercase;
	}

	#overlay div#review-box div.wrap div.external form#commentform div.rateit {
		float: left;
		margin-right: 1.5em;
		margin-top: 1em;
	}

	#overlay div#review-box div.wrap div.external form#commentform div.rate_text {
		color: #717171;
		float: left;
		font-family: "proxima_nova_regular";
		font-size: 0.8em;
		margin: 1.5em 0 1.5em;
	}

	#overlay div#review-box div.wrap div.external form#commentform p.comment-form-comment,
	#overlay div#review-box div.wrap div.external form#commentform p.comment-form-author,
	#overlay div#review-box div.wrap div.external form#commentform p.comment-form-email {
		margin-bottom: 0.5em;
	}

	#overlay div#review-box div.wrap div.external form#commentform textarea#comment {
		// background: url("images/form_icon/4.png") no-repeat scroll 1.5em 1.5em #fff;
		border: 1px solid #cfcfcf;
		border-radius: 0;
		color: #747474;
		font-family: "proxima_nova_regular";
		height: 6em;
		padding: 1.3em 1em 0 3.9em;
		resize: none;
		width: 100%;
	}

	#overlay div#review-box div.wrap div.external form#commentform input {
		background-color: #fff;
		border: 1px solid #cfcfcf;
		border-radius: 0;
		color: #747474;
		float: left;
		font-family: "proxima_nova_regular";
		height: 3.8em;
		line-height: 3.8em;
		padding: 0 1em 0 3.9em;
		width: 49%;
	}

	#overlay div#review-box div.wrap div.external form#commentform input#author {
		// background: url("images/form_icon/1.png") no-repeat scroll 1.25em center #fff;
		margin-right: 2%;
	}

	#overlay div#review-box div.wrap div.external form#commentform input#email {
		// background: url("images/form_icon/2.png") no-repeat scroll 1.25em center #fff;
		margin-right: 0;
	}

	#overlay div#review-box div.wrap div.external form#commentform button.btn.form {
		left: 0em;
		top: 1em;
		font-size: 0.8em;
	}

	#video-box {
		background-color: #000;
		position: absolute;
		right: 0;
		top: 0;
		width: 30em;
		height: 20em;
	}
}

@media screen and (min-width: 480px) and (max-width: 567px) {
	.main-navigation {
		font-size: 0.72em;
	}

	.main-navigation .menu_inside h1 {
		font-size: 4em;
		margin-bottom: 0.2em;
		margin-top: 1em;
	}

	.main-navigation li {
		margin-bottom: 1.2em;
	}

	.main-navigation .menu_inside img#menu_close {
		width: 32px;
		right: 1.5em;
		top: 1.5em;
	}

	#masthead.site-header .wrap,
	.archive .site-content .wrap,
	.error404 .site-content .wrap,
	.single .site-content .wrap,
	.page .site-content .wrap,
	#partners div.wrap,
	#product_side div.wrap {
		width: 92%;
	}

	#masthead.site-header {
		height: 6.8em;
	}

	#masthead.site-header div.wrap div.site-branding {
		left: 4em;
		top: 0.5em;
		width: 8.5em;
	}
	#masthead.site-header div.wrap div.social {
		height: 6.5em;
		display: table;
	}

	#masthead.site-header div.wrap div.menu-button {
		top: 2em;
		width: 3em;
	}

	#masthead.site-header div.wrap div.social span#ttl {
		display: none;
	}

	#movies_section div.mobile_res,
	#product_section div.mobile_res {
		display: block;
		font-size: 0.9em;
		line-height: 1.4em;
	}

	.btn span.right,
	.btn span.left {
		box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.13);
		display: inline-block;
		height: 3em;
		line-height: 3em;
		padding: 0 1em;
		transition: background-position 100ms ease 0s;
		vertical-align: top;
	}

	.btn span.right {
		padding: 0;
		transition: background-position 100ms ease 0s;
	}

	.btn {
		margin-bottom: 0.4em;
		font-size: 0.9em;
	}

	#homeslider .slider-for .slide_item .content_side div.wrap div.buttons {
		bottom: 6%;
		right: 0;
		position: absolute;
	}

	#homeslider .slider-for .slide_item .wrap {
		width: 92%;
	}

	#homeslider .slider-for .slide_item div.img_ext {
		height: 211px;
	}

	#homeslider .btn {
		width: 195px;
	}

	#homeslider .slider-for .slide_item .wrap h1 {
		bottom: 48%;
		font-size: 1.7em;
		line-height: 1.1em;
		width: 54%;
	}

	// #homeslider .slide_thumbnail_item.slick-center {
	// 	background: transparent url("images/slider/slide_thumb_activeline768.png") no-repeat scroll center top;
	// }

	#homeslider .slide_thumbnail_item {
		padding-top: 0.8em;
	}

	.slick-slider {
		margin-bottom: 5px;
	}

	#homeslider .slider-nav {
		width: 96%;
	}

	#newsletter div.wrap {
		width: 90%;
	}

	#newsletter div.wrap div.art span#bld {
		display: inline-block;
	}

	#newsletter div.wrap div.art {
		font-size: 2.1em;
		padding: 1.55em 0 0;
		text-align: center;
		width: 100%;
	}

	#newsletter div.wrap span.group {
		display: block;
		float: left;
		margin: 0 auto 3em;
		position: relative;
		width: 100%;
	}

	#newsletter div.wrap div.newsletter {
		display: block;
		float: none;
		margin: 2em auto 0;
		position: relative;
		width: auto;
	}

	#newsletter div.wrap div.newsletter div.head,
	#newsletter div.wrap div.singup_facebook div.head {
		font-size: 1.35em;
	}

	#newsletter div.wrap div.newsletter form input.newsletter-email {
		// background: url("images/form_icon/2.png") no-repeat scroll 1.6em 0.8em #fff;
		height: 3.1em;
		margin-top: 1px;
		width: 18em;
	}

	#newsletter .btn span.left {
		width: 9em;
	}

	#newsletter span.or {
		margin: 3.9em 1em 1em;
		font-size: 1.3em;
	}

	#newsletter div.wrap div.singup_facebook span#facebook_login {
		display: inline-block;
	}

	#newsletter div.wrap div.singup_facebook {
		clear: both;
		float: left;
		margin-bottom: 2.5em;
		margin-top: 1em;
	}

	#latest_article {
		margin-bottom: 2em;
	}

	#latest_article .item {
		float: left;
		margin-right: 0%;
		width: 100%;
	}

	#latest_article .wrap {
		margin-top: 3em;
	}

	#latest_article h1 {
		font-size: 2.8571em;
	}

	#latest_article span#stay_connected {
		font-size: 1.2em;
		top: 1.3em;
		right: 2%;
		display: none;
	}

	#latest_article div.wrap div.borderline {
		margin: 2.5em 0 3.2em;
		width: 96%;
		display: none;
	}

	#our_movies div.wrap {
		margin-top: 3em;
		margin-bottom: 0.5em;
	}

	#our_movies div.wrap h1 {
		font-size: 2.8571em;
	}

	#our_movies div.wrap div.head div.toggle {
		bottom: 0;
		float: right;
		position: relative;
		right: 0;
		width: 100%;
	}

	#our_movies div.wrap div.head div.toggle span {
		font-size: 1.1em;
		margin-right: 1%;
		margin-left: 0;
		padding: 0.95em 0;
		width: 24.25%;
		text-align: center;
	}

	#our_movies div.wrap div.head div.toggle span#upcoming {
		margin-right: 0%;
	}

	#our_movies div.wrap .movie_itm div.item h3 {
		font-size: 1em;
	}

	#social_feed div.wrap h1 {
		font-size: 2.8571em;
	}

	#talebeh .talent {
		float: left;
		margin-right: 0;
		width: 100%;
	}

	#talebeh .talent h1 {
		font-size: 2.8571em;
	}

	#talebeh .talent div.item div.rightside #about {
		font-size: 1em;
	}

	#talebeh .talent div.item div.leftside {
		max-width: 185px;
		width: 36%;
	}

	#talebeh .talent div.item div.rightside {
		width: 60%;
	}

	#talebeh .behinds {
		float: left;
		width: 100%;
	}

	#talebeh .behinds h1 {
		font-size: 2.8571em;
	}

	#talebeh .behinds div#bcm div.item div.rightside article.short_content {
		display: none;
	}

	#store {
		margin-bottom: 3em;
	}

	#store div.wrap .wrap_store {
		margin-right: 0;
		width: 100%;
	}

	#store div.wrap div.product-item div.leftside {
		width: 28%;
	}

	#store div.wrap div.product-item div.rightside {
		width: 70%;
		padding: 0.8em;
	}

	#store div.wrap h1 {
		font-size: 2.8571em;
	}

	#store div.wrap div.product-item div.rightside a.store_link {
		font-size: 0.8em;
		margin: 1.3em 0 0 0.8em;
	}

	#store div.wrap div.product-item .borderline {
		margin: 1em 0 1em;
	}

	#store div.wrap div.store_form {
		width: 100%;
	}

	#store div.wrap div.store_form h1 {
		font-size: 1.8em;
		line-height: 1.35em;
	}

	#store div.wrap div.store_form div.form_side {
		height: 23em;
		padding: 1em 1.5em;
	}

	#store div.wrap div.store_form div.form_side .desc {
		font-size: 1.2em;
		line-height: 1.2em;
		margin: 0.2em 0 1.3em;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper div.itm {
		margin-bottom: 1.5em;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper div.itm textarea.comment {
		width: 100%;
		height: 8em;
		// background: url("images/form_icon/4.png") no-repeat scroll 1.5em 1.4em #fff;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper input.inout {
		height: 3.5em;
		line-height: 3.5em;
		margin-right: 2%;
		width: 49%;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper input.inout.email {
		margin-right: 0%;
	}

	#store div.wrap div.store_form div.form_side form#idea_form button.btn.form {
		top: 1em;
	}

	#idea_form .btn.form span.left {
		font-size: 0.8em;
		height: 3.5em;
		line-height: 3.5em;
		padding: 0 0.5em;
		width: 12em;
	}

	#idea_form .btn.form span.right {
		font-size: 0.8em;
		height: 3.5em;
		line-height: 3.5em;
	}

	#red_carpet {
		margin-bottom: 1em;
	}

	#red_carpet div.wrap div.item {
		width: 100%;
	}

	#red_carpet.all_page div.wrap div.item {
		width: 100%;
		display: block;
		margin-bottom: 1em;
	}

	#red_carpet div.wrap a#all_events {
		font-size: 0.3em;
		right: 0.5em;
		top: 3em;
	}

	#red_carpet div.wrap div.item.first {
		width: 100%;
		display: block;
	}

	#red_carpet div.wrap div.item.noimage {
		width: 100%;
	}

	#red_carpet div.wrap div.item div.content_side div.date {
		margin: 0.5em 0 0.6em;
	}

	#red_carpet div.wrap h1 {
		font-size: 2.8em;
	}

	#red_carpet div.wrap div.item div.content_side h3 {
		font-size: 1.5em;
		line-height: 1.2em;
		margin-bottom: 0.5em;
	}

	#red_carpet div.wrap div.item div.img_side {
		float: left;
		width: 39%;
	}

	#red_carpet div.wrap div.item div.content_side {
		background-color: #f8f8f8;
		float: left;
		padding: 1em 1.4em 1.4em;
		width: 61%;
		min-height: 220px;
	}

	#red_carpet div.wrap div.item div.content_side div.link {
		margin-top: 1em;
	}

	#red_carpet div.wrap div.item div.content_side div.link a.more {
		font-size: 1em;
	}

	#partners {
		height: 8em;
	}

	#partners div.wrap div.head {
		display: none;
	}

	#partners div.wrap div.sponsor {
		margin-left: 1em;
	}

	#partners div.wrap div.sponsor.s1 {
		width: 7em;
		margin-top: 2.5em;
	}

	#partners div.wrap div.sponsor.s2 {
		width: 5em;
		margin-top: 1.7em;
	}

	#partners div.wrap div.sponsor.s3 {
		width: 7em;
		margin-top: 3em;
	}

	#partners div.wrap div.sponsor.s4 {
		width: 8em;
		margin-top: 2.5em;
		margin-left: 1em;
	}

	.site-footer {
		height: 20em;
	}

	.site-footer div.site-info {
		margin-top: 4em;
	}

	/*********************************************/

	.movies_single #movies_section .content_side {
		top: 0;
	}

	.movies_single #movies_section .content_side .wrap {
		width: 92%;
	}

	.movies_single #movies_section .content_side .wrap .ext {
		padding: 1em 1em 0.8em;
	}

	.movies_single #movies_section div.content_side .wrap .image_side .ext_img {
		position: relative;
		top: 0em;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side .topside {
		margin-bottom: 1em;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side div.top_content_left {
		margin-right: 0;
		width: 100%;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side div.top_content_left,
	.movies_single #movies_section div.content_side div.wrap div.right_side div.top_content_right {
		display: none;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side div.m_item.synoptis {
		margin-bottom: 0em;
	}

	.movies_single
		#movies_section
		div.content_side
		div.wrap
		div.right_side
		.top_content_left
		div.m_item
		span.article.synopsis {
		height: 130px;
	}

	#movies_section div.img_ext {
		height: 300px;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side h1 {
		font-size: 1.3em;
		line-height: 1.2em;
		margin-right: 0;
		margin-top: 1.2em;
	}

	#movies_section div.content_side div.wrap div.right_side div.pg span.obi {
		width: 30%;
	}

	#movies_section div.content_side div.wrap div.right_side div.pg {
		font-size: 0.7em;
		right: 0;
		text-align: right;
		top: 0em;
		width: 12em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side .watch_online {
		margin-right: 1em;
		margin-top: 1em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side .watch_online span.watch_span a {
		font-size: 0.5em;
	}

	#movies_section div.content_side div.wrap div.ext div.image_side div.stay_review span.review_click a {
		font-size: 0.5em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.off_web {
		margin-right: 1em;
		margin-top: 1em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.off_web a {
		font-size: 0.5em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side a.btn {
		font-size: 0.7em;
	}

	#movies_section div.mobile_res div.wrap .m_item .article {
		font-size: 0.8em;
	}

	#movies_section div.ratings_sm {
		margin: 10px auto;
	}

	#movies_section div.ratings_sm .read_rew {
		font-size: 10px;
	}

	.product_single #product_section .content_side {
		top: 6.5em;
	}

	.product_single #product_section div.content_side .wrap .image_side .ext_img {
		position: relative;
		top: 2em;
	}

	.product_single #product_section .content_side .wrap .ext {
		padding: 1em 1em 0.8em;
	}

	.product_single #product_section div.content_side div.wrap div.right_side h1 {
		font-size: 1.3em;
		line-height: 1.2em;
		margin-right: 0;
		margin-top: 1.2em;
	}

	.product_single #product_section div.content_side div.wrap div.right_side .topside {
		margin-bottom: 1em;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.pg span.obi {
		width: 30%;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.pg {
		font-size: 0.7em;
		right: 0;
		text-align: right;
		top: 0em;
		width: 12em;
	}

	.product_single #product_section a.btn {
		font-size: 0.7em;
		margin-right: 1em;
	}

	.product_single #product_section div.content_side .wrap .image_side {
		margin-top: 0;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.bottom_side div.treiler,
	.product_single #product_section div.content_side div.wrap div.right_side div.bottom_side div.buy {
		margin: 0;
	}

	.product_single #product_section div.bottom_side div.price p.price span.heads {
		font-size: 1.2em;
	}

	.product_single #product_section div.bottom_side div.price p.price span.amount {
		font-size: 2em;
	}
	/**********************************************/

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.leftside {
		margin-right: 1em;
		max-width: 185px;
		width: 29%;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside {
		width: 67%;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside .short_content {
		margin-bottom: 1em;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside .short_content p {
		margin-bottom: 1em;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside #about {
		font-size: 1.2em;
	}

	#more_product div.wrap div.product-item div.rightside .short_content {
		margin-bottom: 2em;
	}

	#more_product div.wrap h1 {
		font-size: 3em;
	}

	#more_product div.wrap div.product-item {
		margin-right: 0;
		width: 100%;
	}

	#more_product div.wrap div.product-item div.leftside {
		margin-right: 2%;
		width: 25%;
	}

	#more_product div.wrap div.product-item div.rightside {
		width: 73%;
	}

	#behind_section div.wrap div#bsl div.item h1,
	.behind #more_like_this div.wrap div#bcm h1 {
		font-size: 3em;
		line-height: 1.2em;
	}

	#behind_section div.wrap div#bsl {
		padding-top: 3em;
	}

	#product_side div.wrap div.product-item {
		margin-right: 0;
		width: 100%;
	}

	#product_side div.wrap div.product-item div.leftside {
		width: 25%;
	}

	#product_side div.wrap div.product-item div.rightside {
		width: 73%;
	}

	#movies_section div.mobile_res {
		border-bottom: 7px solid #029e93;
		border-top: 7px solid #ea593b;
		float: left;
		font-size: 0.9em;
		line-height: 1.5em;
		width: 100%;
	}

	#movies_section div.mobile_res div.wrap {
		margin-top: 2em;
	}

	#movies_section div.mobile_res div.wrap .left_side {
		float: left;
		margin-right: 3%;
		width: 48.5%;
	}

	#movies_section div.mobile_res div.wrap .right_side {
		float: left;
		width: 48.5%;
	}

	#movies_section div.mobile_res div.wrap .m_item {
		color: #a9a9a9;
		font-family: "proxima_novalight";
		font-size: 1.143em;
		margin-bottom: 1.8em;
		width: 100%;
	}

	#movies_section div.mobile_res div.wrap .m_item h4 {
		color: #fff;
		font-family: "proxima_novaextrabold";
		font-size: 1em;
		text-decoration: none;
		text-transform: uppercase;
	}

	.product_single div.img_ext {
		height: 330px;
	}

	.product_single .mobile_res {
		border-top: 7px solid #ea593b;
		border-bottom: 7px solid #029e93;
		float: left;
		width: 100%;
		background-color: #0c0c0c;
	}

	.product_single .mobile_res div.wrap {
		margin-top: 2em;
	}

	.product_single .mobile_res div.wrap div.m_item {
		color: #a9a9a9;
		font-family: "proxima_novalight";
		font-size: 1.143em;
		margin-bottom: 1.8em;
		width: 100%;
	}

	.product_single .mobile_res div.wrap div.m_item h4 {
		color: #fff;
		font-family: "proxima_novaextrabold";
		font-size: 1em;
		text-decoration: none;
		text-transform: uppercase;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.top_content_left {
		display: none;
	}

	.product_single #product_section div.bottom_side div.price {
		margin-right: 1.5em;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.bottom_side a.btn {
		font-size: 0.56em;
	}

	#talent_highlight div.wrap div#bsl {
		padding-top: 3em;
		width: 100%;
	}

	#talent_highlight div.wrap div#bsl div.item h1 {
		display: block;
	}

	#talent_highlight div.wrap div#bsl div.item div.leftside {
		float: left;
		width: 100%;
	}

	#talent_highlight div.wrap div#bsl div.item div.rightside {
		margin-top: 2em;
		width: 100%;
	}

	#talent_highlight div.wrap div#bsl div.item div.leftside span.pop_up {
		width: 100%;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item {
		width: 100%;
	}

	#behind_section div.wrap div#bsl div.item div.leftside {
		width: 100%;
	}

	#behind_section div.wrap div#bsl div.item div.leftside a.pop_up {
		width: 100%;
	}

	#behind_section div.wrap div#bsl div.item div.rightside {
		margin-top: 2em;
		width: 100%;
	}

	.behind #more_like_this div.wrap div#bcm div.item {
		width: 100%;
	}

	.behind #more_like_this div.wrap div#bcm div.item div.rightside {
		width: 57%;
	}

	.behind #more_like_this div.wrap div#bcm div.item div.rightside .short_content {
		display: none;
	}

	#red-carpet-event div.wrap {
		margin-top: 2em;
	}

	#red-carpet-event div.wrap div.img_side {
		background-color: #f8f8f8;
		float: left;
		margin-right: 0;
		width: auto;
		max-width: 100%;
	}

	#red-carpet-event div.wrap div.content_side,
	#red-carpet-event div.wrap div.content_side.full {
		margin-right: 0;
		width: 100%;
	}

	#red-carpet-event div.wrap div.content_side h1 {
		font-size: 2.5em;
		line-height: 1.1em;
		margin: 0.3em 0;
	}

	#red-carpet-event div.wrap div.form_side {
		float: left;
		width: 100%;
	}

	#primary.content-area {
		float: left;
		margin-right: 0;
		width: 100% !important;
	}

	#secondary.widget-area {
		float: left;
		margin-top: 2.05em;
		width: 100% !important;
	}

	.single .site-content #primary.content-area .site-main .entry-header h1.entry-title {
		font-size: 2.4em;
		line-height: 1.1em;
	}

	#secondary.widget-area .widget h1.widget-title {
		font-size: 1.5em;
		margin-bottom: 0.5em;
		padding-bottom: 0.5em;
	}

	#secondary.widget-area .widget ul li {
		margin-bottom: 0.5em;
	}

	.archive .site-main .page-header h1.page-title {
		color: #029e93;
		font-size: 2.5em;
		font-style: italic;
	}

	.archive .site-main .page-header h1.page-title span {
		display: block;
		font-size: 1.5em;
		color: #272727;
		font-family: "proxima_nova_bold";
		font-weight: normal;
		font-style: normal;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.bs {
		float: left;
		width: 100%;
	}

	#overlay div#review-box {
		background-color: #fff;
		padding: 1.5em;
		position: absolute;
		width: 80%;
	}

	#overlay div#review-box a.review_box_close {
		// background: url("images/close_btn_rating.jpg") no-repeat scroll left top transparent;
		display: block;
		height: 32px;
		position: absolute;
		right: -5px;
		top: -5px;
		width: 31px;
	}

	#overlay div#review-box div.wrap div.external form#commentform h1 {
		color: #0c0c0c;
		font-family: "proxima_novablack";
		font-size: 2.5em;
		text-transform: uppercase;
	}

	#overlay div#review-box div.wrap div.external form#commentform div.rateit {
		float: left;
		margin-right: 1.5em;
		margin-top: 1em;
	}

	#overlay div#review-box div.wrap div.external form#commentform div.rate_text {
		color: #717171;
		float: left;
		font-family: "proxima_nova_regular";
		font-size: 0.7em;
		margin: 1.7em 0 1.5em;
	}

	#overlay div#review-box div.wrap div.external form#commentform p.comment-form-comment,
	#overlay div#review-box div.wrap div.external form#commentform p.comment-form-author,
	#overlay div#review-box div.wrap div.external form#commentform p.comment-form-email {
		margin-bottom: 0.5em;
	}

	#overlay div#review-box div.wrap div.external form#commentform textarea#comment {
		// background: url("images/form_icon/4.png") no-repeat scroll 1.5em 1.5em #fff;
		border: 1px solid #cfcfcf;
		border-radius: 0;
		color: #747474;
		font-family: "proxima_nova_regular";
		height: 6em;
		padding: 1.3em 1em 0 3.9em;
		resize: none;
		width: 100%;
	}

	#overlay div#review-box div.wrap div.external form#commentform input {
		background-color: #fff;
		border: 1px solid #cfcfcf;
		border-radius: 0;
		color: #747474;
		float: left;
		font-family: "proxima_nova_regular";
		height: 3em;
		line-height: 3em;
		padding: 0 1em 0 3.9em;
		width: 49%;
	}

	#overlay div#review-box div.wrap div.external form#commentform input#author {
		// background: url("images/form_icon/1.png") no-repeat scroll 1.25em center #fff;
		margin-right: 2%;
	}

	#overlay div#review-box div.wrap div.external form#commentform input#email {
		// background: url("images/form_icon/2.png") no-repeat scroll 1.25em center #fff;
		margin-right: 0;
	}

	#overlay div#review-box div.wrap div.external form#commentform button.btn.form {
		left: 0em;
		top: 1em;
		font-size: 0.8em;
	}

	#video-box {
		background-color: #000;
		position: absolute;
		right: 0;
		top: 0;
		width: 26em;
		height: 18em;
	}
}

@media screen and (min-width: 414px) and (max-width: 479px) {
	.main-navigation {
		font-size: 0.7em;
	}

	.main-navigation .menu_inside h1 {
		font-size: 4em;
		margin-bottom: 0.2em;
		margin-top: 1em;
	}

	.main-navigation li {
		margin-bottom: 1.2em;
	}

	.main-navigation .menu_inside img#menu_close {
		width: 30px;
		right: 1.2em;
		top: 1.2em;
	}

	#masthead.site-header .wrap,
	.archive .site-content .wrap,
	.error404 .site-content .wrap,
	.single .site-content .wrap,
	.page .site-content .wrap,
	#partners div.wrap,
	#product_side div.wrap {
		width: 92%;
	}

	#masthead.site-header {
		height: 6.8em;
	}

	#masthead.site-header div.wrap div.site-branding {
		left: 4em;
		top: 0.5em;
		width: 8.5em;
	}
	#masthead.site-header div.wrap div.social {
		height: 6.5em;
		display: table;
	}

	#masthead.site-header div.wrap div.menu-button {
		top: 2em;
		width: 3em;
	}

	#masthead.site-header div.wrap div.social span#ttl {
		display: none;
	}

	#movies_section div.mobile_res,
	#product_section div.mobile_res {
		display: block;
		font-size: 0.8em;
	}

	.btn span.right,
	.btn span.left {
		box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.13);
		display: inline-block;
		height: 3em;
		line-height: 3em;
		padding: 0 1em;
		transition: background-position 100ms ease 0s;
		vertical-align: top;
	}

	.btn span.right {
		padding: 0;
		transition: background-position 100ms ease 0s;
	}

	.btn {
		margin-bottom: 0.6em;
		font-size: 0.9em;
	}

	#homeslider .slider-for .slide_item .content_side div.wrap div.buttons {
		bottom: 6%;
		right: 0;
		position: absolute;
	}

	#homeslider .btn {
		width: 154px;
		font-size: 0.7em;
	}

	#homeslider .slider-for .slide_item .wrap {
		width: 92%;
	}

	#homeslider .slider-for .slide_item div.img_ext {
		height: 182px;
	}

	#homeslider .slider-for .slide_item .wrap h1 {
		bottom: 48%;
		font-size: 1.3em;
		line-height: 1.1em;
		width: 55%;
	}

	#homeslider .slider-nav {
		width: 96%;
	}

	// #homeslider .slide_thumbnail_item.slick-center {
	// 	background: transparent url("images/slider/slide_thumb_activeline768.png") no-repeat scroll center top;
	// }

	#homeslider .slide_thumbnail_item {
		padding-top: 0.8em;
	}

	.slick-slider {
		margin-bottom: 5px;
	}

	#newsletter div.wrap {
		width: 90%;
	}

	#newsletter div.wrap div.art span#bld {
		display: inline-block;
	}

	#newsletter div.wrap div.art {
		font-size: 2.1em;
		padding: 1.55em 0 0;
		text-align: center;
		width: 100%;
	}

	#newsletter div.wrap span.group {
		display: block;
		float: left;
		margin: 0 auto 3em;
		position: relative;
		width: 100%;
	}

	#newsletter div.wrap div.newsletter {
		display: block;
		float: none;
		margin: 2em auto 0;
		position: relative;
		width: auto;
	}

	#newsletter div.wrap div.newsletter div.head,
	#newsletter div.wrap div.singup_facebook div.head {
		font-size: 1.35em;
	}

	#newsletter div.wrap div.newsletter form input.newsletter-email {
		// background: url("images/form_icon/2.png") no-repeat scroll 1.6em 0.8em #fff;
		height: 3.1em;
		margin-top: 1px;
		width: 16.5em;
		padding: 0 1em 0 4em;
	}

	#newsletter .btn span.left {
		width: 9em;
	}

	#newsletter span.or {
		font-size: 1.3em;
		margin: 1.5em 0;
		text-align: center;
		width: 100%;
	}

	#newsletter div.wrap div.singup_facebook span#facebook_login {
		display: inline-block;
	}

	#newsletter div.wrap div.singup_facebook {
		clear: both;
		float: left;
		margin-bottom: 2.5em;
		margin-top: 0em;
	}

	#latest_article {
		margin-bottom: 2em;
	}

	#latest_article .item {
		float: left;
		margin-right: 0%;
		width: 100%;
	}

	#latest_article .wrap {
		margin-top: 3em;
	}

	#latest_article h1 {
		font-size: 2.8571em;
	}

	#latest_article span#stay_connected {
		font-size: 1.2em;
		top: 1.3em;
		right: 2%;
		display: none;
	}

	#latest_article div.wrap div.borderline {
		margin: 2.5em 0 3.2em;
		width: 96%;
		display: none;
	}

	#our_movies div.wrap {
		margin-top: 2em;
		margin-bottom: 0.5em;
	}

	#our_movies div.wrap h1 {
		font-size: 2.8571em;
	}

	#our_movies div.wrap div.head div.toggle {
		bottom: 0;
		float: right;
		position: relative;
		right: 0;
		width: 100%;
	}

	#our_movies div.wrap div.head div.toggle span {
		font-size: 1.1em;
		margin-right: 1%;
		margin-left: 0;
		padding: 0.95em 0;
		width: 24.25%;
		text-align: center;
	}

	#our_movies div.wrap div.head div.toggle span#upcoming {
		margin-right: 0%;
	}

	#our_movies div.wrap .movie_itm div.item h3 {
		font-size: 1em;
	}

	#social_feed div.wrap h1 {
		font-size: 2.8571em;
	}

	#talebeh .talent {
		float: left;
		margin-right: 0;
		width: 100%;
	}

	#talebeh .talent h1 {
		font-size: 2.5em;
	}

	#talebeh .talent div.item div.rightside #about {
		font-size: 1em;
	}

	#talebeh .talent div.item div.leftside {
		max-width: 185px;
		width: 36%;
	}

	#talebeh .talent div.item div.rightside {
		width: 60%;
	}

	#talebeh .behinds {
		float: left;
		width: 100%;
	}

	#talebeh .behinds h1 {
		font-size: 2.5em;
	}

	#talebeh .behinds div#bcm div.item div.rightside article.short_content {
		display: none;
	}

	#talebeh .behinds div.item div.rightside h3 {
		font-size: 1.5em;
		line-height: 1.2em;
		margin-bottom: 0.5em;
	}

	#store {
		margin-bottom: 3em;
	}

	#store div.wrap .wrap_store {
		margin-right: 0;
		width: 100%;
	}

	#store div.wrap div.product-item div.leftside {
		width: 28%;
	}

	#store div.wrap div.product-item div.rightside {
		width: 70%;
		padding: 0.8em;
	}

	#store div.wrap h1 {
		font-size: 2.8571em;
	}

	#store div.wrap div.product-item div.rightside a.store_link {
		font-size: 0.85em;
		margin: 0 0 0 6em;
	}

	#store div.wrap div.product-item .borderline {
		margin: 1em 0 1em;
	}

	#store div.wrap div.store_form {
		width: 100%;
	}

	#store div.wrap div.store_form h1 {
		font-size: 1.8em;
		line-height: 1.35em;
	}

	#store div.wrap div.store_form div.form_side {
		height: 23em;
		padding: 1em 1.5em;
	}

	#store div.wrap div.store_form div.form_side .desc {
		font-size: 1.2em;
		line-height: 1.2em;
		margin: 0.2em 0 1.3em;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper div.itm {
		margin-bottom: 1.5em;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper div.itm textarea.comment {
		width: 100%;
		height: 8em;
		// background: url("images/form_icon/4.png") no-repeat scroll 1.5em 1.4em #fff;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper input.inout {
		height: 3.5em;
		line-height: 3.5em;
		margin-right: 2%;
		width: 49%;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper input.inout.email {
		margin-right: 0%;
	}

	#store div.wrap div.store_form div.form_side form#idea_form button.btn.form {
		top: 1em;
	}

	#idea_form .btn.form span.left {
		font-size: 0.8em;
		height: 3.5em;
		line-height: 3.5em;
		padding: 0 0.5em;
		width: 12em;
	}

	#idea_form .btn.form span.right {
		font-size: 0.8em;
		height: 3.5em;
		line-height: 3.5em;
	}

	#red_carpet {
		margin-bottom: 1em;
	}

	#red_carpet div.wrap div.item {
		width: 100%;
	}

	#red_carpet.all_page div.wrap div.item {
		width: 100%;
		display: block;
		margin-bottom: 1em;
	}

	#red_carpet div.wrap a#all_events {
		font-size: 0.3em;
		right: 0.5em;
		top: 3em;
	}

	#red_carpet div.wrap div.item.first {
		width: 100%;
		display: block;
	}

	#red_carpet div.wrap div.item.noimage {
		width: 100%;
	}

	#red_carpet div.wrap div.item div.content_side div.date {
		margin: 0.5em 0 0.6em;
	}

	#red_carpet div.wrap h1 {
		font-size: 2.5em;
	}

	#red_carpet div.wrap div.item div.content_side h3 {
		font-size: 1.5em;
		margin-bottom: 0.5em;
	}

	#red_carpet div.wrap div.item div.img_side {
		float: left;
		width: 39%;
	}

	#red_carpet div.wrap div.item div.content_side {
		background-color: #f8f8f8;
		float: left;
		padding: 1em 1.4em 1.4em;
		width: 61%;
		min-height: 220px;
	}

	#red_carpet div.wrap div.item div.content_side div.link {
		margin-top: 1em;
	}

	#red_carpet div.wrap div.item div.content_side div.link a.more {
		font-size: 1em;
	}

	#partners {
		height: 8em;
	}

	#partners div.wrap div.head {
		display: none;
	}

	#partners div.wrap div.sponsor {
		margin-left: 0.8em;
	}

	#partners div.wrap div.sponsor.s1 {
		width: 6.5em;
		margin-top: 2.5em;
	}

	#partners div.wrap div.sponsor.s2 {
		width: 4.5em;
		margin-top: 1.7em;
	}

	#partners div.wrap div.sponsor.s3 {
		width: 6.5em;
		margin-top: 3em;
	}

	#partners div.wrap div.sponsor.s4 {
		width: 6.5em;
		margin-top: 2.5em;
		margin-left: 0.8em;
	}

	.site-footer {
		height: 20em;
	}

	.site-footer div.site-info {
		margin-top: 4em;
	}

	/*********************************************/

	.movies_single #movies_section .content_side {
		top: 0;
	}

	.movies_single #movies_section .content_side .wrap {
		width: 92%;
	}

	.movies_single #movies_section .content_side .wrap .ext {
		padding: 1em 1em 0.8em;
	}

	.movies_single #movies_section div.content_side .wrap .image_side .ext_img {
		position: relative;
		top: 0em;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side .topside {
		margin-bottom: 0.5em;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side div.top_content_left {
		margin-right: 0;
		width: 100%;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side div.top_content_left,
	.movies_single #movies_section div.content_side div.wrap div.right_side div.top_content_right {
		display: none;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side div.m_item.synoptis {
		margin-bottom: 0em;
	}

	.movies_single
		#movies_section
		div.content_side
		div.wrap
		div.right_side
		.top_content_left
		div.m_item
		span.article.synopsis {
		height: 130px;
	}

	#movies_section div.img_ext {
		height: 300px;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side h1 {
		font-size: 1.3em;
		line-height: 1.2em;
		margin-right: 0;
		margin-top: 0;
	}

	#movies_section div.content_side div.wrap div.right_side div.pg span.obi {
		width: 30%;
	}

	#movies_section div.content_side div.wrap div.right_side div.pg {
		display: none;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side .watch_online {
		margin-right: 1em;
		margin-top: 1em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side .watch_online span.watch_span a {
		font-size: 0.5em;
	}

	#movies_section div.content_side div.wrap div.ext div.image_side div.stay_review span.review_click a {
		font-size: 0.5em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.off_web {
		margin-right: 1em;
		margin-top: 1em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.off_web a {
		font-size: 0.5em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side a.btn {
		font-size: 0.55em;
	}

	#movies_section div.mobile_res div.wrap .m_item .article {
		font-size: 0.8em;
	}

	#movies_section div.ratings_sm {
		margin: 10px auto;
	}

	#movies_section div.ratings_sm .read_rew {
		font-size: 10px;
	}

	.product_single #product_section .content_side {
		top: 6.5em;
	}

	.product_single #product_section div.content_side .wrap .image_side .ext_img {
		position: relative;
		top: 1em;
	}

	.product_single #product_section .content_side .wrap .ext {
		padding: 1em 1em 0.8em;
	}

	.product_single #product_section div.content_side div.wrap div.right_side h1 {
		font-size: 1.1em;
		line-height: 1.2em;
		margin-right: 0;
		margin-top: 0em;
	}

	.product_single #product_section div.content_side div.wrap div.right_side .topside {
		margin-bottom: 1em;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.pg span.obi {
		width: 30%;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.pg {
		display: none;
	}

	.product_single #product_section a.btn {
		font-size: 0.6em;
		margin-right: 0.6em;
	}

	.product_single #product_section div.content_side .wrap .image_side {
		margin-top: 0;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.bottom_side div.treiler,
	.product_single #product_section div.content_side div.wrap div.right_side div.bottom_side div.buy {
		margin: 0;
		line-height: 1em;
	}

	.product_single #product_section div.bottom_side div.price p.price span.heads {
		font-size: 1em;
	}

	.product_single #product_section div.bottom_side div.price p.price span.amount {
		font-size: 1.5em;
	}
	/**********************************************/

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.leftside {
		margin-right: 1em;
		max-width: 185px;
		width: 29%;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside {
		width: 67%;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside .short_content {
		margin-bottom: 1em;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside .short_content p {
		margin-bottom: 1em;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside #about {
		font-size: 1.2em;
	}

	.talent_highlight #more_like_this div.wrap div#bcm h1 {
		font-size: 3em;
		margin-bottom: 1.1em;
		padding-bottom: 0.2em;
	}

	.behind #more_like_this div.wrap div#bcm div.item div.rightside h3 {
		font-size: 1.5em;
		line-height: 1.2em;
		margin-bottom: 0.5em;
	}

	.single .site-content .wrap {
		margin-top: 3em;
	}

	#more_product div.wrap div.product-item div.rightside .short_content {
		margin-bottom: 2em;
	}

	#more_product div.wrap h1 {
		font-size: 3em;
	}

	#more_product div.wrap div.product-item {
		margin-right: 0;
		width: 100%;
	}

	#more_product div.wrap div.product-item div.leftside {
		margin-right: 2%;
		width: 25%;
	}

	#more_product div.wrap div.product-item div.rightside {
		width: 73%;
	}

	#behind_section div.wrap div#bsl div.item h1,
	.behind #more_like_this div.wrap div#bcm h1 {
		font-size: 2.5em;
		line-height: 1.2em;
	}

	#behind_section div.wrap div#bsl {
		padding-top: 3em;
	}

	#product_side div.wrap div.product-item {
		margin-right: 0;
		width: 100%;
	}

	#product_side div.wrap div.product-item div.leftside {
		width: 25%;
	}

	#product_side div.wrap div.product-item div.rightside {
		width: 73%;
	}

	#movies_section div.mobile_res {
		border-top: 7px solid #ea593b;
		border-bottom: 7px solid #029e93;
		float: left;
		width: 100%;
	}

	#movies_section div.mobile_res div.wrap {
		margin-top: 2em;
	}

	#movies_section div.mobile_res div.wrap .left_side {
		float: left;
		margin-right: 3%;
		width: 48.5%;
	}

	#movies_section div.mobile_res div.wrap .right_side {
		float: left;
		width: 48.5%;
	}

	#movies_section div.mobile_res div.wrap .m_item {
		color: #a9a9a9;
		font-family: "proxima_novalight";
		font-size: 1.143em;
		margin-bottom: 1.8em;
		width: 100%;
	}

	#movies_section div.mobile_res div.wrap .m_item h4 {
		color: #fff;
		font-family: "proxima_novaextrabold";
		font-size: 1em;
		text-decoration: none;
		text-transform: uppercase;
	}

	.product_single div.img_ext {
		height: 330px;
	}

	.product_single .mobile_res {
		border-top: 7px solid #ea593b;
		border-bottom: 7px solid #029e93;
		float: left;
		width: 100%;
		background-color: #0c0c0c;
	}

	.product_single .mobile_res div.wrap {
		margin-top: 2em;
	}

	.product_single .mobile_res div.wrap div.m_item {
		color: #a9a9a9;
		font-family: "proxima_novalight";
		font-size: 1.143em;
		margin-bottom: 1.8em;
		width: 100%;
	}

	.product_single .mobile_res div.wrap div.m_item h4 {
		color: #fff;
		font-family: "proxima_novaextrabold";
		font-size: 1em;
		text-decoration: none;
		text-transform: uppercase;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.top_content_left {
		display: none;
	}

	.product_single #product_section div.bottom_side div.price {
		margin-right: 1.5em;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.bottom_side a.btn {
		font-size: 0.56em;
	}

	#talent_highlight div.wrap div#bsl {
		padding-top: 3em;
		width: 100%;
	}

	#talent_highlight div.wrap div#bsl div.item h1 {
		display: block;
	}

	#talent_highlight div.wrap div#bsl div.item div.leftside {
		float: left;
		width: 100%;
	}

	#talent_highlight div.wrap div#bsl div.item div.rightside {
		margin-top: 2em;
		width: 100%;
	}

	#talent_highlight div.wrap div#bsl div.item div.leftside span.pop_up {
		width: 100%;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item {
		width: 100%;
	}

	#behind_section div.wrap div#bsl div.item div.leftside {
		width: 100%;
	}

	#behind_section div.wrap div#bsl div.item div.leftside a.pop_up {
		width: 100%;
	}

	#behind_section div.wrap div#bsl div.item div.rightside {
		margin-top: 2em;
		width: 100%;
	}

	.behind #more_like_this div.wrap div#bcm div.item {
		width: 100%;
	}

	.behind #more_like_this div.wrap div#bcm div.item div.rightside {
		width: 57%;
	}

	.behind #more_like_this div.wrap div#bcm div.item div.rightside .short_content {
		display: none;
	}

	#red-carpet-event div.wrap {
		margin-top: 2em;
	}

	#red-carpet-event div.wrap div.img_side {
		background-color: #f8f8f8;
		float: left;
		margin-right: 0;
		width: auto;
		max-width: 100%;
	}

	#red-carpet-event div.wrap div.content_side,
	#red-carpet-event div.wrap div.content_side.full {
		margin-right: 0;
		width: 100%;
	}

	#red-carpet-event div.wrap div.content_side h1 {
		font-size: 2.5em;
		line-height: 1.1em;
		margin: 0.3em 0;
	}

	#red-carpet-event div.wrap div.form_side {
		float: left;
		width: 100%;
	}

	#primary.content-area {
		float: left;
		margin-right: 0;
		width: 100% !important;
	}

	#secondary.widget-area {
		float: left;
		margin-top: 2.05em;
		width: 100% !important;
	}

	.single .site-content #primary.content-area .site-main .entry-header h1.entry-title {
		font-size: 2.4em;
		line-height: 1.1em;
	}

	#secondary.widget-area .widget h1.widget-title {
		font-size: 1.5em;
		margin-bottom: 0.5em;
		padding-bottom: 0.5em;
	}

	#secondary.widget-area .widget ul li {
		margin-bottom: 0.5em;
	}

	.archive .site-main .page-header h1.page-title {
		color: #029e93;
		font-size: 2.2em;
		font-style: italic;
		line-height: 1.3em;
	}

	.archive .site-main .page-header h1.page-title span {
		display: block;
		font-size: 1.5em;
		color: #272727;
		font-family: "proxima_nova_bold";
		font-weight: normal;
		font-style: normal;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.bs {
		float: left;
		width: 100%;
	}

	.news_archive div.wrap #primary .entry-header h2.entry-title {
		font-size: 2.2em;
		line-height: 1.1em;
	}

	#overlay div#review-box {
		background-color: #fff;
		padding: 1.5em;
		position: absolute;
		width: 80%;
	}

	#overlay div#review-box a.review_box_close {
		// background: url("images/close_btn_rating.jpg") no-repeat scroll left top transparent;
		display: block;
		height: 32px;
		position: absolute;
		right: -5px;
		top: -5px;
		width: 31px;
	}

	#overlay div#review-box div.wrap div.external form#commentform h1 {
		color: #0c0c0c;
		font-family: "proxima_novablack";
		font-size: 2.2em;
		text-transform: uppercase;
	}

	#overlay div#review-box div.wrap div.external form#commentform div.rateit {
		float: left;
		margin-right: 1.5em;
		margin-top: 0em;
	}

	#overlay div#review-box div.wrap div.external form#commentform div.rate_text {
		color: #717171;
		float: left;
		font-family: "proxima_nova_regular";
		font-size: 0.8em;
		margin: 0em 0 1.5em;
	}

	#overlay div#review-box div.wrap div.external form#commentform p.comment-form-comment,
	#overlay div#review-box div.wrap div.external form#commentform p.comment-form-author,
	#overlay div#review-box div.wrap div.external form#commentform p.comment-form-email {
		margin-bottom: 0.5em;
	}

	#overlay div#review-box div.wrap div.external form#commentform textarea#comment {
		// background: url("images/form_icon/4.png") no-repeat scroll 1em 1em #fff;
		border: 1px solid #cfcfcf;
		border-radius: 0;
		color: #747474;
		font-family: "proxima_nova_regular";
		height: 5em;
		padding: 1em 1em 0 2.9em;
		resize: none;
		width: 100%;
	}

	#overlay div#review-box div.wrap div.external form#commentform input {
		background-color: #fff;
		border: 1px solid #cfcfcf;
		border-radius: 0;
		color: #747474;
		float: left;
		font-family: "proxima_nova_regular";
		height: 2em;
		line-height: 2em;
		padding: 0 1em 0 2.9em;
		width: 49%;
	}

	#overlay div#review-box div.wrap div.external form#commentform input#author {
		// background: url("images/form_icon/1.png") no-repeat scroll 1em center #fff;
		margin-right: 2%;
	}

	#overlay div#review-box div.wrap div.external form#commentform input#email {
		// background: url("images/form_icon/2.png") no-repeat scroll 1em center #fff;
		margin-right: 0;
	}

	#overlay div#review-box div.wrap div.external form#commentform button.btn.form {
		left: 0em;
		top: 1em;
		font-size: 0.65em;
	}

	#video-box {
		background-color: #000;
		position: absolute;
		right: 0;
		top: 0;
		width: 24em;
		height: 16em;
	}

	.video_box_close {
		// background: url("images/ico_close_btn2.png") no-repeat scroll left top transparent;
		display: block;
		height: 18px;
		position: absolute;
		right: -25px;
		top: 0;
		width: 17px;
	}
}

@media screen and (min-width: 400px) and (max-width: 413px) {
	.main-navigation {
		font-size: 0.7em;
	}

	.main-navigation .menu_inside h1 {
		font-size: 4em;
		margin-bottom: 0.2em;
		margin-top: 1em;
	}

	.main-navigation li {
		margin-bottom: 1.2em;
	}

	.main-navigation .menu_inside img#menu_close {
		width: 30px;
		right: 1.2em;
		top: 1.2em;
	}

	#masthead.site-header .wrap,
	.archive .site-content .wrap,
	.error404 .site-content .wrap,
	.single .site-content .wrap,
	.page .site-content .wrap,
	#partners div.wrap,
	#product_side div.wrap {
		width: 92%;
	}

	#masthead.site-header {
		height: 6.8em;
	}

	#masthead.site-header div.wrap div.site-branding {
		left: 4em;
		top: 0.5em;
		width: 8.5em;
	}
	#masthead.site-header div.wrap div.social {
		height: 6.8em;
		display: table;
	}

	#masthead.site-header div.wrap div.menu-button {
		top: 2em;
		width: 3em;
	}

	#masthead.site-header div.wrap div.social span#ttl {
		display: none;
	}

	#movies_section div.mobile_res,
	#product_section div.mobile_res {
		display: block;
	}

	.btn span.right,
	.btn span.left {
		box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.13);
		display: inline-block;
		height: 3em;
		line-height: 3em;
		padding: 0 1.2em;
		transition: background-position 100ms ease 0s;
		vertical-align: top;
	}

	.btn span.right {
		padding: 0;
		transition: background-position 100ms ease 0s;
	}

	.btn {
		margin-bottom: 0.6em;
		font-size: 0.8em;
	}

	#homeslider .btn {
		width: 154px;
		font-size: 0.7em;
	}

	#homeslider .slider-for .slide_item .content_side div.wrap div.buttons {
		bottom: 6%;
		right: 0;
		position: absolute;
	}

	#homeslider .slider-for .slide_item .wrap {
		width: 92%;
	}

	#homeslider .slider-for .slide_item div.img_ext {
		height: 176px;
	}

	#homeslider .slider-for .slide_item .wrap h1 {
		bottom: 53%;
		font-size: 1.2em;
		width: 54%;
		line-height: 1.1em;
	}

	#homeslider .slider-nav {
		width: 96%;
	}

	// #homeslider .slide_thumbnail_item.slick-center {
	// 	background: transparent url("images/slider/slide_thumb_activeline768.png") no-repeat scroll center top;
	// }

	#homeslider .slide_thumbnail_item {
		padding-top: 0.8em;
	}

	.slick-slider {
		margin-bottom: 5px;
	}

	#newsletter div.wrap {
		width: 90%;
	}

	#newsletter div.wrap div.art span#bld {
		display: inline-block;
	}

	#newsletter div.wrap div.art {
		font-size: 2.1em;
		padding: 1.55em 0 0;
		text-align: center;
		width: 100%;
	}

	#newsletter div.wrap span.group {
		display: block;
		float: left;
		margin: 0 auto 3em;
		position: relative;
		width: 100%;
	}

	#newsletter div.wrap div.newsletter {
		display: block;
		float: none;
		margin: 2em auto 0;
		position: relative;
		width: auto;
	}

	#newsletter div.wrap div.newsletter div.head,
	#newsletter div.wrap div.singup_facebook div.head {
		font-size: 1.35em;
	}

	#newsletter div.wrap div.newsletter form input.newsletter-email {
		// background: url("images/form_icon/2.png") no-repeat scroll 1.6em 0.8em #fff;
		height: 3.1em;
		margin-top: 1px;
		width: 100%;
		padding: 0 1em 0 4em;
		margin-bottom: 0.6em;
	}

	#newsletter div.wrap div.newsletter form button.newsletter-submit {
		width: 100%;
	}

	#newsletter .btn span.left,
	#newsletter .btn.fbb span.left {
		width: 89%;
	}

	#newsletter .btn span.right {
		width: 11%;
	}

	#newsletter span.or {
		display: none;
	}

	#newsletter div.wrap div.singup_facebook span#facebook_login {
		display: inline-block;
		width: 100%;
	}

	#newsletter div.wrap div.singup_facebook {
		clear: both;
		float: left;
		margin-bottom: 2em;
		margin-top: 1.5em;
		width: 100%;
	}

	#latest_article {
		margin-bottom: 0;
	}

	#latest_article .item {
		float: left;
		margin-right: 0%;
		width: 100%;
	}

	#latest_article .wrap {
		margin-top: 3em;
	}

	#latest_article h1 {
		font-size: 2.8571em;
	}

	#latest_article span#stay_connected {
		font-size: 1.2em;
		top: 1.3em;
		right: 2%;
		display: none;
	}

	#latest_article div.wrap div.borderline {
		margin: 2.5em 0 3.2em;
		width: 96%;
		display: none;
	}

	#our_movies div.wrap {
		margin-top: 2em;
		margin-bottom: 0.5em;
	}

	#our_movies div.wrap h1 {
		font-size: 2.8571em;
	}

	#our_movies div.wrap div.head div.toggle {
		bottom: 0;
		float: right;
		position: relative;
		right: 0;
		width: 100%;
	}

	#our_movies div.wrap div.head div.toggle span {
		font-size: 1.1em;
		margin-right: 1%;
		margin-left: 0;
		padding: 0.95em 0;
		width: 24.25%;
		text-align: center;
	}

	#our_movies div.wrap div.head div.toggle span#upcoming {
		margin-right: 0%;
	}

	#our_movies div.wrap .movie_itm div.item h3 {
		font-size: 1em;
	}

	#social_feed div.wrap h1 {
		font-size: 2.8571em;
	}

	#talebeh .talent {
		float: left;
		margin-right: 0;
		width: 100%;
	}

	#talebeh .talent h1 {
		font-size: 2.5em;
	}

	#talebeh .talent div.item div.rightside #about {
		font-size: 1em;
	}

	#talebeh .talent div.item div.leftside {
		max-width: 185px;
		width: 36%;
	}

	#talebeh .talent div.item div.rightside {
		width: 60%;
	}

	#talebeh .behinds {
		float: left;
		width: 100%;
	}

	#talebeh .behinds h1 {
		font-size: 2.5em;
	}

	#talebeh .behinds div#bcm div.item div.rightside article.short_content {
		display: none;
	}

	#talebeh .behinds div.item div.rightside h3 {
		font-size: 1.5em;
		line-height: 1.2em;
		margin-bottom: 0.5em;
	}

	#store {
		margin-bottom: 1em;
	}

	#store div.wrap .wrap_store {
		margin-right: 0;
		width: 100%;
	}

	#store div.wrap div.product-item div.leftside {
		width: 28%;
	}

	#store div.wrap div.product-item div.rightside {
		width: 70%;
		padding: 0.8em;
	}

	#store div.wrap h1 {
		font-size: 2.8571em;
	}

	#store div.wrap div.product-item div.rightside a.store_link {
		font-size: 0.85em;
		margin: 0 0 0 6em;
	}

	#store div.wrap div.product-item .borderline {
		margin: 1em 0 1em;
	}

	#store div.wrap div.store_form {
		display: none;
	}

	#store div.wrap div.store_form h1 {
		font-size: 1.8em;
		line-height: 1.35em;
	}

	#store div.wrap div.store_form div.form_side {
		height: 23em;
		padding: 1em 1.5em;
	}

	#store div.wrap div.store_form div.form_side .desc {
		font-size: 1.2em;
		line-height: 1.2em;
		margin: 0.2em 0 1.3em;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper div.itm {
		margin-bottom: 1.5em;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper div.itm textarea.comment {
		width: 100%;
		height: 8em;
		// background: url("images/form_icon/4.png") no-repeat scroll 1.5em 1.4em #fff;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper input.inout {
		height: 3.5em;
		line-height: 3.5em;
		margin-right: 2%;
		width: 49%;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper input.inout.email {
		margin-right: 0%;
	}

	#store div.wrap div.store_form div.form_side form#idea_form button.btn.form {
		top: 1em;
	}

	#idea_form .btn.form span.left {
		font-size: 0.8em;
		height: 3.5em;
		line-height: 3.5em;
		padding: 0 0.5em;
		width: 12em;
	}

	#idea_form .btn.form span.right {
		font-size: 0.8em;
		height: 3.5em;
		line-height: 3.5em;
	}

	#red_carpet div.wrap div.item {
		width: 100%;
	}

	#red_carpet.all_page div.wrap div.item {
		width: 100%;
		display: block;
		margin-bottom: 1em;
	}

	#red_carpet div.wrap a#all_events {
		font-size: 0.3em;
		right: 0.5em;
		top: 3em;
	}

	#red_carpet {
		margin-bottom: 1em;
	}

	#red_carpet div.wrap div.item.first {
		width: 100%;
		display: block;
	}

	#red_carpet div.wrap div.item.noimage {
		width: 100%;
	}

	#red_carpet div.wrap div.item div.content_side div.date {
		margin: 0.5em 0 0.6em;
	}

	#red_carpet div.wrap h1 {
		font-size: 2.5em;
	}

	#red_carpet div.wrap div.item div.content_side h3 {
		font-size: 1.5em;
		margin-bottom: 0.5em;
	}

	#red_carpet div.wrap div.item div.img_side {
		float: left;
		width: 39%;
	}

	#red_carpet div.wrap div.item div.content_side {
		background-color: #f8f8f8;
		float: left;
		padding: 1em 1.4em 1.4em;
		width: 61%;
		min-height: 220px;
	}

	#red_carpet div.wrap div.item div.content_side div.link {
		margin-top: 1em;
	}

	#red_carpet div.wrap div.item div.content_side div.link a.more {
		font-size: 1em;
	}

	#partners {
		height: 8em;
	}

	#partners div.wrap div.head {
		display: none;
	}

	#partners div.wrap div.sponsor {
		margin-left: 0.6em;
	}

	#partners div.wrap div.sponsor.s1 {
		width: 6.3em;
		margin-top: 2.5em;
	}

	#partners div.wrap div.sponsor.s2 {
		width: 4.3em;
		margin-top: 1.7em;
	}

	#partners div.wrap div.sponsor.s3 {
		width: 6.3em;
		margin-top: 3em;
	}

	#partners div.wrap div.sponsor.s4 {
		width: 6.3em;
		margin-top: 2.5em;
		margin-left: 0.6em;
	}

	.site-footer {
		height: 20em;
	}

	.site-footer div.site-info {
		margin-top: 4em;
	}

	/*********************************************/

	.movies_single #movies_section .content_side {
		top: 0;
	}

	.movies_single #movies_section .content_side .wrap {
		width: 92%;
	}

	.movies_single #movies_section .content_side .wrap .ext {
		padding: 1em 1em 0.8em;
	}

	.movies_single #movies_section div.content_side .wrap .image_side .ext_img {
		position: relative;
		top: 0em;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side .topside {
		margin-bottom: 0.5em;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side div.top_content_left {
		margin-right: 0;
		width: 100%;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side div.top_content_left,
	.movies_single #movies_section div.content_side div.wrap div.right_side div.top_content_right {
		display: none;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side div.m_item.synoptis {
		margin-bottom: 0em;
	}

	.movies_single
		#movies_section
		div.content_side
		div.wrap
		div.right_side
		.top_content_left
		div.m_item
		span.article.synopsis {
		height: 130px;
	}

	#movies_section div.img_ext {
		height: 300px;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side h1 {
		font-size: 1.3em;
		line-height: 1.2em;
		margin-right: 0;
		margin-top: 0;
	}

	#movies_section div.content_side div.wrap div.right_side div.pg span.obi {
		width: 30%;
	}

	#movies_section div.content_side div.wrap div.right_side div.pg {
		display: none;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side .watch_online {
		margin-right: 1em;
		margin-top: 0.5em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side .watch_online span.watch_span a {
		font-size: 0.5em;
	}

	#movies_section div.content_side div.wrap div.ext div.image_side div.stay_review span.review_click a {
		font-size: 0.5em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.off_web {
		margin-right: 1em;
		margin-top: 0.5em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.off_web a {
		font-size: 0.5em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side a.btn {
		font-size: 0.6em;
	}

	#movies_section div.mobile_res div.wrap .m_item .article {
		font-size: 0.8em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.buy {
		margin-right: 0;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.social {
		margin-top: 0.5em;
	}

	#movies_section div.ratings_sm {
		margin: 10px auto;
	}

	#movies_section div.ratings_sm .read_rew {
		font-size: 10px;
	}

	.product_single #product_section .content_side {
		top: 6.5em;
	}

	.product_single #product_section div.content_side .wrap .image_side .ext_img {
		position: relative;
		top: 1em;
	}

	.product_single #product_section .content_side .wrap .ext {
		padding: 1em 1em 0.8em;
	}

	.product_single #product_section div.content_side div.wrap div.right_side h1 {
		font-size: 1.1em;
		line-height: 1.2em;
		margin-right: 0;
		margin-top: 0em;
	}

	.product_single #product_section div.content_side div.wrap div.right_side .topside {
		margin-bottom: 1em;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.pg span.obi {
		width: 30%;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.pg {
		display: none;
	}

	.product_single #product_section a.btn {
		font-size: 0.6em;
		margin-right: 0em;
	}

	.product_single #product_section div.content_side .wrap .image_side {
		margin-top: 0;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.bottom_side div.treiler,
	.product_single #product_section div.content_side div.wrap div.right_side div.bottom_side div.buy {
		margin: 0;
		line-height: 1em;
	}

	.product_single #product_section div.bottom_side div.price {
		margin-right: 1.5em;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.bottom_side a.btn {
		font-size: 0.56em;
	}

	.product_single #product_section div.bottom_side div.price p.price span.heads {
		font-size: 1em;
	}

	.product_single #product_section div.bottom_side div.price p.price span.amount {
		font-size: 1.5em;
	}
	/**********************************************/

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.leftside {
		margin-right: 1em;
		max-width: 185px;
		width: 29%;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside {
		width: 67%;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside .short_content {
		margin-bottom: 1em;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside .short_content p {
		margin-bottom: 1em;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside #about {
		font-size: 1.2em;
	}

	.talent_highlight #more_like_this div.wrap div#bcm h1 {
		font-size: 3em;
		margin-bottom: 1.1em;
		padding-bottom: 0.2em;
	}

	.behind #more_like_this div.wrap div#bcm div.item div.rightside h3 {
		font-size: 1.5em;
		line-height: 1.2em;
		margin-bottom: 0.5em;
	}

	.single .site-content .wrap {
		margin-top: 3em;
	}

	#more_product div.wrap div.product-item div.rightside .short_content {
		margin-bottom: 1.2em;
	}

	#more_product div.wrap h1 {
		font-size: 3em;
	}

	#more_product div.wrap div.product-item {
		margin-right: 0;
		width: 100%;
	}

	#more_product div.wrap div.product-item div.leftside {
		margin-right: 2%;
		width: 25%;
	}

	#more_product div.wrap div.product-item div.rightside {
		width: 73%;
	}

	#behind_section {
		padding-bottom: 1em;
	}

	#behind_section div.wrap div#bsl div.item h1,
	.behind #more_like_this div.wrap div#bcm h1 {
		font-size: 2em;
		line-height: 1.2em;
	}

	#behind_section div.wrap div#bsl {
		padding-top: 3em;
	}

	#behind_section div.wrap div#bsl {
		width: 100%;
	}

	#talent_highlight div.wrap div#bsl div.item h1 {
		font-size: 2.5em;
	}

	#talent_highlight div.wrap div#bsl div.item div.topside span#about {
		font-size: 1.2em;
		top: -0.5em;
	}

	.talent_highlight #more_like_this div.wrap div#bcm {
		width: 100%;
	}

	#product_side div.wrap div.product-item {
		margin-right: 0;
		width: 100%;
	}

	#product_side div.wrap div.product-item div.leftside {
		width: 25%;
	}

	#product_side div.wrap div.product-item div.rightside {
		width: 73%;
	}

	#movies_section div.mobile_res {
		border-top: 7px solid #ea593b;
		border-bottom: 7px solid #029e93;
		float: left;
		width: 100%;
	}

	#movies_section div.mobile_res div.wrap {
		margin-top: 2em;
	}

	#movies_section div.mobile_res div.wrap .left_side {
		float: left;
		margin-right: 3%;
		width: 48.5%;
	}

	#movies_section div.mobile_res div.wrap .right_side {
		float: left;
		width: 48.5%;
	}

	#movies_section div.mobile_res div.wrap .m_item {
		color: #a9a9a9;
		font-family: "proxima_novalight";
		font-size: 0.9em;
		margin-bottom: 1.8em;
		width: 100%;
	}

	#movies_section div.mobile_res div.wrap .m_item h4 {
		color: #fff;
		font-family: "proxima_novaextrabold";
		font-size: 1em;
		text-decoration: none;
		text-transform: uppercase;
	}

	.product_single div.img_ext {
		height: 330px;
	}

	.product_single .mobile_res {
		border-top: 7px solid #ea593b;
		border-bottom: 7px solid #029e93;
		float: left;
		width: 100%;
		background-color: #0c0c0c;
	}

	.product_single .mobile_res div.wrap {
		margin-top: 2em;
	}

	.product_single .mobile_res div.wrap div.m_item {
		color: #a9a9a9;
		font-family: "proxima_novalight";
		font-size: 0.9em;
		margin-bottom: 1.8em;
		width: 100%;
	}

	.product_single .mobile_res div.wrap div.m_item h4 {
		color: #fff;
		font-family: "proxima_novaextrabold";
		font-size: 1em;
		text-decoration: none;
		text-transform: uppercase;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.top_content_left {
		display: none;
	}

	#talent_highlight div.wrap div#bsl {
		padding-top: 3em;
		width: 100%;
	}

	#talent_highlight div.wrap div#bsl div.item h1 {
		display: block;
	}

	#talent_highlight div.wrap div#bsl div.item div.leftside {
		float: left;
		width: 100%;
	}

	#talent_highlight div.wrap div#bsl div.item div.rightside {
		margin-top: 2em;
		width: 100%;
	}

	#talent_highlight div.wrap div#bsl div.item div.leftside span.pop_up {
		width: 100%;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item {
		width: 100%;
	}

	#behind_section div.wrap div#bsl div.item div.leftside {
		width: 100%;
	}

	#behind_section div.wrap div#bsl div.item div.leftside a.pop_up {
		width: 100%;
	}

	#behind_section div.wrap div#bsl div.item div.rightside {
		margin-top: 2em;
		width: 100%;
	}

	.behind #more_like_this div.wrap div#bcm div.item {
		width: 100%;
	}

	.behind #more_like_this div.wrap div#bcm div.item div.rightside {
		width: 57%;
	}

	.behind #more_like_this div.wrap div#bcm div.item div.rightside .short_content {
		display: none;
	}

	#red-carpet-event div.wrap {
		margin-top: 2em;
	}

	#red-carpet-event div.wrap div.img_side {
		background-color: #f8f8f8;
		float: left;
		margin-right: 0;
		width: auto;
		max-width: 100%;
	}

	#red-carpet-event div.wrap div.content_side,
	#red-carpet-event div.wrap div.content_side.full {
		margin-right: 0;
		width: 100%;
	}

	#red-carpet-event div.wrap div.content_side h1 {
		font-size: 2.5em;
		line-height: 1.1em;
		margin: 0.3em 0;
	}

	#red-carpet-event div.wrap div.form_side {
		float: left;
		width: 100%;
	}

	#primary.content-area {
		float: left;
		margin-right: 0;
		width: 100% !important;
	}

	#secondary.widget-area {
		float: left;
		margin-top: 2.05em;
		width: 100% !important;
	}

	.single .site-content #primary.content-area .site-main .entry-header h1.entry-title {
		font-size: 2.4em;
		line-height: 1.1em;
	}

	#secondary.widget-area .widget h1.widget-title {
		font-size: 1.5em;
		margin-bottom: 0.5em;
		padding-bottom: 0.5em;
	}

	#secondary.widget-area .widget ul li {
		margin-bottom: 0.5em;
	}

	.archive .site-main .page-header h1.page-title {
		color: #029e93;
		font-size: 2.2em;
		font-style: italic;
		line-height: 1.3em;
	}

	.archive .site-main .page-header h1.page-title span {
		display: block;
		font-size: 1.5em;
		color: #272727;
		font-family: "proxima_nova_bold";
		font-weight: normal;
		font-style: normal;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.bs {
		float: left;
		width: 100%;
	}

	.news_archive div.wrap #primary .entry-header h2.entry-title {
		font-size: 2.2em;
		line-height: 1.1em;
	}

	#overlay div#review-box {
		background-color: #fff;
		padding: 1.5em;
		position: absolute;
		width: 80%;
	}

	#overlay div#review-box a.review_box_close {
		// background: url("images/close_btn_rating.jpg") no-repeat scroll left top transparent;
		display: block;
		height: 32px;
		position: absolute;
		right: -5px;
		top: -5px;
		width: 31px;
	}

	#overlay div#review-box div.wrap div.external form#commentform h1 {
		color: #0c0c0c;
		font-family: "proxima_novablack";
		font-size: 2.2em;
		text-transform: uppercase;
	}

	#overlay div#review-box div.wrap div.external form#commentform div.rateit {
		float: left;
		margin-right: 1.5em;
		margin-top: 0em;
	}

	#overlay div#review-box div.wrap div.external form#commentform div.rate_text {
		color: #717171;
		float: left;
		font-family: "proxima_nova_regular";
		font-size: 0.8em;
		margin: 0em 0 1.5em;
	}

	#overlay div#review-box div.wrap div.external form#commentform p.comment-form-comment,
	#overlay div#review-box div.wrap div.external form#commentform p.comment-form-author,
	#overlay div#review-box div.wrap div.external form#commentform p.comment-form-email {
		margin-bottom: 0.5em;
	}

	#overlay div#review-box div.wrap div.external form#commentform textarea#comment {
		// background: url("images/form_icon/4.png") no-repeat scroll 1em 1em #fff;
		border: 1px solid #cfcfcf;
		border-radius: 0;
		color: #747474;
		font-family: "proxima_nova_regular";
		height: 5em;
		padding: 1em 1em 0 2.9em;
		resize: none;
		width: 100%;
	}

	#overlay div#review-box div.wrap div.external form#commentform input {
		background-color: #fff;
		border: 1px solid #cfcfcf;
		border-radius: 0;
		color: #747474;
		float: left;
		font-family: "proxima_nova_regular";
		height: 2em;
		line-height: 2em;
		padding: 0 1em 0 2.9em;
		width: 49%;
	}

	#overlay div#review-box div.wrap div.external form#commentform input#author {
		// background: url("images/form_icon/1.png") no-repeat scroll 1em center #fff;
		margin-right: 2%;
	}

	#overlay div#review-box div.wrap div.external form#commentform input#email {
		// background: url("images/form_icon/2.png") no-repeat scroll 1em center #fff;
		margin-right: 0;
	}

	#overlay div#review-box div.wrap div.external form#commentform button.btn.form {
		left: 0em;
		top: 1em;
		font-size: 0.65em;
	}

	#video-box {
		background-color: #000;
		position: absolute;
		right: 0;
		top: 0;
		width: 22em;
		height: 15em;
	}

	.video_box_close {
		// background: url("images/ico_close_btn2.png") no-repeat scroll left top transparent;
		display: block;
		height: 18px;
		position: absolute;
		right: -25px;
		top: 0;
		width: 17px;
	}
}

@media screen and (min-width: 375px) and (max-width: 399px) {
	.main-navigation {
		font-size: 0.65em;
	}

	.main-navigation .menu_inside h1 {
		font-size: 4em;
		margin-bottom: 0.2em;
		margin-top: 1em;
	}

	.main-navigation li {
		margin-bottom: 1em;
	}

	.main-navigation .menu_inside img#menu_close {
		width: 25px;
		right: 1em;
		top: 1em;
	}

	#masthead.site-header .wrap,
	.archive .site-content .wrap,
	.error404 .site-content .wrap,
	.single .site-content .wrap,
	.page .site-content .wrap,
	#partners div.wrap,
	#product_side div.wrap {
		width: 92%;
	}

	#masthead.site-header div.wrap {
		text-align: center;
	}

	#masthead.site-header {
		height: 6.8em;
	}

	#masthead.site-header div.wrap div.site-branding {
		left: 32%;
		top: 0.5em;
		width: 8.5em;
	}
	#masthead.site-header div.wrap div.social {
		display: none;
	}

	#masthead.site-header div.wrap div.menu-button {
		top: 2em;
		width: 3em;
		float: left;
	}

	#masthead.site-header div.wrap div.social span#ttl {
		display: none;
	}

	#movies_section div.mobile_res,
	#product_section div.mobile_res {
		display: block;
	}

	.btn span.right,
	.btn span.left {
		box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.13);
		display: inline-block;
		height: 3em;
		line-height: 3em;
		padding: 0 1em;
		transition: background-position 100ms ease 0s;
		vertical-align: top;
	}

	.btn span.right {
		padding: 0;
		transition: background-position 100ms ease 0s;
	}

	.btn {
		margin-bottom: 0.6em;
		font-size: 0.7em;
	}

	#homeslider .slider-for .slide_item .content_side div.wrap div.buttons {
		bottom: 5%;
		right: 0;
		position: absolute;
	}

	#homeslider .btn {
		display: block;
		width: 155px;
	}

	#homeslider .slider-for .slide_item .wrap {
		width: 92%;
	}

	#homeslider .slider-for .slide_item div.img_ext {
		height: 165px;
	}

	#homeslider .slider-for .slide_item .wrap h1 {
		bottom: 55%;
		font-size: 1.12em;
		line-height: 1.1em;
		width: 52%;
	}

	#homeslider .slider-nav {
		width: 96%;
	}

	// #homeslider .slide_thumbnail_item.slick-center {
	// 	background: transparent url("images/slider/slide_thumb_activeline768.png") no-repeat scroll center top;
	// }

	#homeslider .slide_thumbnail_item {
		padding-top: 0.8em;
	}

	.slick-slider {
		margin-bottom: 5px;
	}

	#newsletter div.wrap {
		width: 90%;
	}

	#newsletter div.wrap div.art span#bld {
		display: inline-block;
	}

	#newsletter div.wrap div.art {
		font-size: 2.1em;
		padding: 1.55em 0 0;
		text-align: center;
		width: 100%;
	}

	#newsletter div.wrap span.group {
		display: block;
		float: left;
		margin: 0 auto 3em;
		position: relative;
		width: 100%;
	}

	#newsletter div.wrap div.newsletter {
		display: block;
		float: none;
		margin: 2em auto 0;
		position: relative;
		width: auto;
	}

	#newsletter div.wrap div.newsletter div.head,
	#newsletter div.wrap div.singup_facebook div.head {
		font-size: 1.35em;
	}

	#newsletter div.wrap div.newsletter form input.newsletter-email {
		// background: url("images/form_icon/2.png") no-repeat scroll 1.6em 0.8em #fff;
		height: 3.1em;
		margin-top: 1px;
		width: 100%;
		padding: 0 1em 0 4em;
		margin-bottom: 0.6em;
	}

	#newsletter div.wrap div.newsletter form button.newsletter-submit {
		width: 100%;
	}

	#newsletter .btn span.left,
	#newsletter .btn.fbb span.left {
		width: 89%;
	}

	#newsletter .btn span.right {
		width: 11%;
	}

	#newsletter span.or {
		display: none;
	}

	#newsletter div.wrap div.singup_facebook span#facebook_login {
		display: inline-block;
		width: 100%;
	}

	#newsletter div.wrap div.singup_facebook {
		clear: both;
		float: left;
		margin-bottom: 2em;
		margin-top: 1.5em;
		width: 100%;
	}

	#newsletter div.wrap div.singup_facebook div.head {
		margin-bottom: 0;
	}

	// .btn span.right.fb span {
	// 	background-image: url("images/fb_letter2.png");
	// }

	#latest_article {
		margin-bottom: 0;
	}

	#latest_article .item {
		float: left;
		margin-right: 0%;
		width: 100%;
	}

	#latest_article .wrap {
		margin-top: 2em;
	}

	#latest_article h1 {
		font-size: 2.3em;
	}

	#latest_article span#stay_connected {
		font-size: 1.2em;
		top: 1.3em;
		right: 2%;
		display: none;
	}

	#latest_article div.wrap div.borderline {
		margin: 2.5em 0 3.2em;
		width: 96%;
		display: none;
	}

	#our_movies div.wrap {
		margin-top: 2em;
		margin-bottom: 0.5em;
	}

	#our_movies div.wrap h1 {
		font-size: 2.3em;
	}

	#our_movies div.wrap div.head div.toggle {
		bottom: 0;
		float: right;
		position: relative;
		right: 0;
		width: 100%;
	}

	#our_movies div.wrap div.head div.toggle span {
		font-size: 0.9em;
		margin-right: 1%;
		margin-left: 0;
		padding: 0.95em 0;
		width: 24.25%;
		text-align: center;
	}

	#our_movies div.wrap div.head div.toggle span#upcoming {
		margin-right: 0%;
	}

	#our_movies div.wrap .movie_itm div.item h3 {
		font-size: 1em;
	}

	#social_feed div.wrap h1 {
		font-size: 2.3em;
	}

	#talebeh .wrap {
		margin-top: 2em;
	}

	#talebeh .talent {
		float: left;
		margin-right: 0;
		width: 100%;
	}

	#talebeh .talent h1 {
		font-size: 2.3em;
	}

	#talebeh .talent div.item div.rightside #about {
		font-size: 0.9em;
		line-height: 1.2em;
	}

	#talebeh .talent div.item div.leftside {
		max-width: 185px;
		width: 36%;
		margin-right: 4%;
	}

	#talebeh .talent div.item div.rightside {
		width: 60%;
	}

	#talebeh .talent div.item div.rightside h3 {
		font-size: 1.8em;
		line-height: 1.2em;
		margin-bottom: 0.2em;
	}

	#talebeh .talent div.item div.rightside .short_content {
		margin-bottom: 0.8em;
	}

	#talebeh .behinds {
		float: left;
		width: 100%;
	}

	#talebeh .behinds h1 {
		font-size: 2.3em;
	}

	#talebeh .behinds div#bcm div.item div.rightside article.short_content {
		display: none;
	}

	#talebeh .behinds div.item div.rightside h3 {
		font-size: 1.5em;
		line-height: 1.2em;
		margin-bottom: 0.5em;
	}

	#store {
		margin-bottom: 1em;
	}

	#store div.wrap .wrap_store {
		margin-right: 0;
		width: 100%;
	}

	#store div.wrap div.product-item div.leftside {
		width: 28%;
		height: auto;
	}

	#store div.wrap div.product-item div.rightside {
		width: 70%;
		padding: 0.8em;
		height: auto;
	}

	#store div.wrap h1 {
		font-size: 2.3em;
	}

	#store div.wrap div.product-item div.rightside a.store_link {
		font-size: 0.85em;
		margin: 0;
	}

	#store div.wrap div.product-item .borderline {
		margin: 1em 0 1em;
	}

	#store div.wrap div.store_form {
		display: none;
	}

	#store div.wrap div.store_form h1 {
		font-size: 1.8em;
		line-height: 1.35em;
	}

	#store div.wrap div.store_form div.form_side {
		height: 23em;
		padding: 1em 1.5em;
	}

	#store div.wrap div.store_form div.form_side .desc {
		font-size: 1.2em;
		line-height: 1.2em;
		margin: 0.2em 0 1.3em;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper div.itm {
		margin-bottom: 1.5em;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper div.itm textarea.comment {
		width: 100%;
		height: 8em;
		// background: url("images/form_icon/4.png") no-repeat scroll 1.5em 1.4em #fff;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper input.inout {
		height: 3.5em;
		line-height: 3.5em;
		margin-right: 2%;
		width: 49%;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper input.inout.email {
		margin-right: 0%;
	}

	#store div.wrap div.store_form div.form_side form#idea_form button.btn.form {
		top: 1em;
	}

	#store div.wrap div.product-item div.rightside h3 {
		font-size: 1.5em;
		line-height: 1.1em;
		margin-bottom: 0.2em;
	}

	#store div.wrap div.product-item div.rightside .short_content {
		margin-bottom: 1em;
	}

	#idea_form .btn.form span.left {
		font-size: 0.8em;
		height: 3.5em;
		line-height: 3.5em;
		padding: 0 0.5em;
		width: 12em;
	}

	#idea_form .btn.form span.right {
		font-size: 0.8em;
		height: 3.5em;
		line-height: 3.5em;
	}

	#red_carpet {
		margin-bottom: 1em;
	}

	#red_carpet div.wrap div.item {
		width: 100%;
	}

	#red_carpet.all_page div.wrap div.item {
		width: 100%;
		display: block;
		margin-bottom: 1em;
	}

	#red_carpet div.wrap a#all_events {
		font-size: 0.3em;
		right: 0.5em;
		top: 3em;
	}

	#red_carpet div.wrap div.item.first {
		width: 100%;
		display: block;
	}

	#red_carpet div.wrap div.item.noimage {
		width: 100%;
	}

	#red_carpet div.wrap div.item div.content_side div.date {
		margin: 0.5em 0 0.6em;
	}

	#red_carpet div.wrap h1 {
		font-size: 2.3em;
	}

	#red_carpet div.wrap div.item div.content_side h3 {
		font-size: 1.5em;
		margin-bottom: 0.5em;
		line-height: 1.2em;
	}

	#red_carpet div.wrap div.item div.img_side {
		float: left;
		width: 39%;
	}

	#red_carpet div.wrap div.item div.content_side {
		background-color: #f8f8f8;
		float: left;
		padding: 1em 1.4em 1.4em;
		width: 61%;
		min-height: 220px;
	}

	#red_carpet div.wrap div.item div.content_side div.link {
		margin-top: 1em;
	}

	#red_carpet div.wrap div.item div.content_side div.link a.more {
		font-size: 1em;
	}

	#partners {
		height: 8em;
	}

	#partners div.wrap div.head {
		display: none;
	}

	#partners div.wrap div.sponsor {
		margin-left: 0.6em;
	}

	#partners div.wrap div.sponsor.s1 {
		width: 5.8em;
		margin-top: 2.5em;
	}

	#partners div.wrap div.sponsor.s2 {
		width: 4em;
		margin-top: 1.7em;
	}

	#partners div.wrap div.sponsor.s3 {
		width: 5.8em;
		margin-top: 3em;
	}

	#partners div.wrap div.sponsor.s4 {
		width: 5.8em;
		margin-top: 2.5em;
		margin-left: 0.6em;
	}

	.site-footer {
		height: 20em;
	}

	.site-footer div.site-info {
		margin-top: 4em;
	}

	/*********************************************/

	.movies_single #movies_section .content_side {
		top: 0;
	}

	.movies_single #movies_section .content_side .wrap {
		width: 92%;
	}

	.movies_single #movies_section .content_side .wrap .ext {
		padding: 1em 1em 0.8em;
	}

	.movies_single #movies_section div.content_side .wrap .image_side .ext_img {
		position: relative;
		top: 0em;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side .topside {
		margin-bottom: 0.5em;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side div.top_content_left {
		margin-right: 0;
		width: 100%;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side div.top_content_left,
	.movies_single #movies_section div.content_side div.wrap div.right_side div.top_content_right {
		display: none;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side div.m_item.synoptis {
		margin-bottom: 0em;
	}

	.movies_single
		#movies_section
		div.content_side
		div.wrap
		div.right_side
		.top_content_left
		div.m_item
		span.article.synopsis {
		height: 130px;
	}

	#movies_section div.img_ext {
		height: 300px;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side h1 {
		font-size: 1.3em;
		line-height: 1.2em;
		margin-right: 0;
		margin-top: 0;
	}

	#movies_section div.content_side div.wrap div.right_side div.pg span.obi {
		width: 30%;
	}

	#movies_section div.content_side div.wrap div.right_side div.pg {
		display: none;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side .watch_online {
		margin-right: 1em;
		margin-top: 0.5em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side .watch_online span.watch_span a {
		font-size: 0.5em;
	}

	#movies_section div.content_side div.wrap div.ext div.image_side div.stay_review span.review_click a {
		font-size: 0.5em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.off_web {
		margin-right: 1em;
		margin-top: 0.5em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.off_web a {
		font-size: 0.5em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side a.btn {
		font-size: 0.5em;
	}

	#movies_section div.mobile_res div.wrap .m_item .article {
		font-size: 0.8em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.buy {
		margin-right: 0;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.social {
		margin-top: 0.85em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.social a {
		width: 16px;
		height: 16px;
		margin-right: 5px;
	}

	// #movies_section div.content_side div.wrap div.right_side div.bottom_side div.social a.fb {
	// 	background: transparent url("images/social_movie1.png") no-repeat scroll 0 0;
	// }

	// #movies_section div.content_side div.wrap div.right_side div.bottom_side div.social a.twt {
	// 	background: transparent url("images/social_movie1.png") no-repeat scroll -21px 0;
	// }

	// #movies_section div.content_side div.wrap div.right_side div.bottom_side div.social a.vimeo {
	// 	background: transparent url("images/social_movie1.png") no-repeat scroll -42px 0;
	// }

	#movies_section div.ratings_sm {
		margin: 10px auto;
	}

	#movies_section div.ratings_sm .read_rew {
		font-size: 10px;
	}

	.product_single #product_section .content_side {
		top: 6.5em;
	}

	.product_single #product_section div.content_side .wrap .image_side .ext_img {
		position: relative;
		top: 1em;
	}

	.product_single #product_section .content_side .wrap .ext {
		padding: 1em 1em 0.8em;
	}

	.product_single #product_section div.content_side div.wrap div.right_side h1 {
		font-size: 1.1em;
		line-height: 1.2em;
		margin-right: 0;
		margin-top: 0em;
	}

	.product_single #product_section div.content_side div.wrap div.right_side .topside {
		margin-bottom: 1em;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.pg span.obi {
		width: 30%;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.pg {
		display: none;
	}

	.product_single #product_section a.btn {
		font-size: 0.5em;
		margin-right: 0em;
	}

	.product_single #product_section div.content_side .wrap .image_side {
		margin-top: 0;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.bottom_side div.treiler,
	.product_single #product_section div.content_side div.wrap div.right_side div.bottom_side div.buy {
		margin: 0;
		line-height: 1em;
	}

	.product_single #product_section div.bottom_side div.price {
		margin-right: 1em;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.bottom_side a.btn {
		font-size: 0.56em;
	}

	.product_single #product_section div.bottom_side div.price p.price span.heads {
		font-size: 1em;
	}

	.product_single #product_section div.bottom_side div.price p.price span.amount {
		font-size: 1.5em;
	}
	/**********************************************/

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.leftside {
		margin-right: 3%;
		max-width: 185px;
		width: 29%;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside {
		width: 67%;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside .short_content {
		margin-bottom: 1em;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside .short_content p {
		margin-bottom: 1em;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside #about {
		font-size: 1em;
		line-height: 1.2em;
	}

	.talent_highlight #more_like_this div.wrap div#bcm h1 {
		font-size: 2.5em;
		margin-bottom: 1.1em;
		padding-bottom: 0.2em;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside h3 {
		font-size: 1.8em;
		line-height: 1.2em;
		margin-bottom: 0.2em;
	}

	.behind #more_like_this div.wrap div#bcm div.item div.rightside h3 {
		font-size: 1.3em;
		line-height: 1.2em;
		margin-bottom: 0.5em;
	}

	.single .site-content .wrap {
		margin-top: 3em;
	}

	#more_product div.wrap div.product-item div.rightside .short_content {
		margin-bottom: 2em;
	}

	#more_product div.wrap h1 {
		font-size: 2.5em;
	}

	#more_product div.wrap div.product-item {
		margin-right: 0;
		width: 100%;
	}

	#more_product div.wrap div.product-item div.leftside {
		margin-right: 2%;
		width: 25%;
	}

	#more_product div.wrap div.product-item div.rightside {
		width: 73%;
	}

	#more_product div.wrap div.product-item div.rightside h3 {
		font-size: 1.4em;
		line-height: 1.1em;
		margin-bottom: 0.2em;
	}

	#more_product div.wrap div.product-item div.rightside div.price p.price {
		margin-bottom: 1em;
	}

	#behind_section {
		padding-bottom: 1em;
	}

	#behind_section div.wrap div#bsl div.item h1,
	.behind #more_like_this div.wrap div#bcm h1 {
		font-size: 1.8em;
		line-height: 1.2em;
	}

	#behind_section div.wrap div#bsl {
		padding-top: 3em;
	}

	#behind_section div.wrap div#bsl {
		width: 100%;
	}

	#talent_highlight div.wrap div#bsl div.item h1 {
		font-size: 2.5em;
	}

	#talent_highlight div.wrap div#bsl div.item div.topside span#about {
		font-size: 1.2em;
		top: -0.5em;
	}

	.talent_highlight #more_like_this div.wrap div#bcm {
		width: 100%;
	}

	#product_side div.wrap div.product-item {
		margin-right: 0;
		width: 100%;
	}

	#product_side div.wrap div.product-item div.leftside {
		width: 25%;
	}

	#product_side div.wrap div.product-item div.rightside {
		width: 73%;
	}

	#movies_section div.mobile_res {
		border-top: 7px solid #ea593b;
		border-bottom: 7px solid #029e93;
		float: left;
		width: 100%;
	}

	#movies_section div.mobile_res div.wrap {
		margin-top: 2em;
	}

	#movies_section div.mobile_res div.wrap .left_side {
		float: left;
		margin-right: 3%;
		width: 48.5%;
	}

	#movies_section div.mobile_res div.wrap .right_side {
		float: left;
		width: 48.5%;
	}

	#movies_section div.mobile_res div.wrap .m_item {
		color: #a9a9a9;
		font-family: "proxima_novalight";
		font-size: 0.9em;
		margin-bottom: 1.8em;
		width: 100%;
	}

	#movies_section div.mobile_res div.wrap .m_item h4 {
		color: #fff;
		font-family: "proxima_novaextrabold";
		font-size: 1em;
		text-decoration: none;
		text-transform: uppercase;
	}

	.product_single div.img_ext {
		height: 330px;
	}

	.product_single .mobile_res {
		border-top: 7px solid #ea593b;
		border-bottom: 7px solid #029e93;
		float: left;
		width: 100%;
		background-color: #0c0c0c;
	}

	.product_single .mobile_res div.wrap {
		margin-top: 2em;
	}

	.product_single .mobile_res div.wrap div.m_item {
		color: #a9a9a9;
		font-family: "proxima_novalight";
		font-size: 0.9em;
		margin-bottom: 1.8em;
		width: 100%;
	}

	.product_single .mobile_res div.wrap div.m_item h4 {
		color: #fff;
		font-family: "proxima_novaextrabold";
		font-size: 1em;
		text-decoration: none;
		text-transform: uppercase;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.top_content_left {
		display: none;
	}

	#talent_highlight div.wrap div#bsl {
		padding-top: 3em;
		width: 100%;
	}

	#talent_highlight div.wrap div#bsl div.item h1 {
		display: block;
	}

	#talent_highlight div.wrap div#bsl div.item div.leftside {
		float: left;
		width: 100%;
	}

	#talent_highlight div.wrap div#bsl div.item div.rightside {
		margin-top: 2em;
		width: 100%;
	}

	#talent_highlight div.wrap div#bsl div.item div.leftside span.pop_up {
		width: 100%;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item {
		width: 100%;
	}

	#behind_section div.wrap div#bsl div.item div.leftside {
		width: 100%;
	}

	#behind_section div.wrap div#bsl div.item div.leftside a.pop_up {
		width: 100%;
	}

	#behind_section div.wrap div#bsl div.item div.rightside {
		margin-top: 2em;
		width: 100%;
	}

	.behind #more_like_this div.wrap div#bcm div.item {
		width: 100%;
	}

	.behind #more_like_this div.wrap div#bcm div.item div.rightside {
		width: 57%;
	}

	.behind #more_like_this div.wrap div#bcm div.item div.rightside .short_content {
		display: none;
	}

	#red-carpet-event {
		padding-bottom: 1em;
		padding-top: 1em;
	}

	#red-carpet-event div.wrap {
		margin-top: 2em;
	}

	#red-carpet-event div.wrap div.img_side {
		background-color: #f8f8f8;
		float: left;
		margin-right: 0;
		width: auto;
		max-width: 100%;
	}

	#red-carpet-event div.wrap div.content_side,
	#red-carpet-event div.wrap div.content_side.full {
		margin-right: 0;
		width: 100%;
	}

	#red-carpet-event div.wrap div.content_side h1 {
		font-size: 2em;
		line-height: 1.1em;
		margin: 0.3em 0;
	}

	#red-carpet-event div.wrap div.form_side {
		display: none;
	}

	#primary.content-area {
		float: left;
		margin-right: 0;
		width: 100% !important;
	}

	#secondary.widget-area {
		float: left;
		margin-top: 2.05em;
		width: 100% !important;
	}

	.single .site-content #primary.content-area .site-main .entry-header h1.entry-title {
		font-size: 2.4em;
		line-height: 1.1em;
	}

	#secondary.widget-area .widget h1.widget-title {
		font-size: 1.5em;
		margin-bottom: 0.5em;
		padding-bottom: 0.5em;
	}

	#secondary.widget-area .widget ul li {
		margin-bottom: 0.5em;
	}

	.archive .site-main .page-header h1.page-title {
		color: #029e93;
		font-size: 2em;
		font-style: italic;
		line-height: 1.3em;
	}

	.archive .site-main .page-header h1.page-title span {
		display: block;
		font-size: 1.2em;
		color: #272727;
		font-family: "proxima_nova_bold";
		font-weight: normal;
		font-style: normal;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.bs {
		float: left;
		width: 100%;
	}

	.news_archive div.wrap #primary .entry-header h2.entry-title {
		font-size: 2em;
		line-height: 1.1em;
	}

	#overlay div#review-box {
		background-color: #fff;
		padding: 1.5em;
		position: absolute;
		width: 80%;
	}

	#overlay div#review-box a.review_box_close {
		// background: url("images/close_btn_rating.jpg") no-repeat scroll left top transparent;
		display: block;
		height: 32px;
		position: absolute;
		right: -5px;
		top: -5px;
		width: 31px;
	}

	#overlay div#review-box div.wrap div.external form#commentform h1 {
		color: #0c0c0c;
		font-family: "proxima_novablack";
		font-size: 2em;
		text-transform: uppercase;
	}

	#overlay div#review-box div.wrap div.external form#commentform div.rateit {
		float: left;
		margin-right: 1.5em;
		margin-top: 0em;
	}

	#overlay div#review-box div.wrap div.external form#commentform div.rate_text {
		color: #717171;
		float: left;
		font-family: "proxima_nova_regular";
		font-size: 0.8em;
		margin: 0em 0 1.5em;
	}

	#overlay div#review-box div.wrap div.external form#commentform p.comment-form-comment,
	#overlay div#review-box div.wrap div.external form#commentform p.comment-form-author,
	#overlay div#review-box div.wrap div.external form#commentform p.comment-form-email {
		margin-bottom: 0.5em;
	}

	#overlay div#review-box div.wrap div.external form#commentform textarea#comment {
		// background: url("images/form_icon/4.png") no-repeat scroll 1em 1em #fff;
		border: 1px solid #cfcfcf;
		border-radius: 0;
		color: #747474;
		font-family: "proxima_nova_regular";
		height: 5em;
		padding: 1em 1em 0 2.9em;
		resize: none;
		width: 100%;
	}

	#overlay div#review-box div.wrap div.external form#commentform input {
		background-color: #fff;
		border: 1px solid #cfcfcf;
		border-radius: 0;
		color: #747474;
		float: left;
		font-family: "proxima_nova_regular";
		height: 2em;
		line-height: 2em;
		padding: 0 1em 0 2.9em;
		width: 49%;
	}

	#overlay div#review-box div.wrap div.external form#commentform input#author {
		// background: url("images/form_icon/1.png") no-repeat scroll 1em center #fff;
		margin-right: 2%;
	}

	#overlay div#review-box div.wrap div.external form#commentform input#email {
		// background: url("images/form_icon/2.png") no-repeat scroll 1em center #fff;
		margin-right: 0;
	}

	#overlay div#review-box div.wrap div.external form#commentform button.btn.form {
		left: 0em;
		top: 1em;
		font-size: 0.61em;
	}

	#video-box {
		background-color: #000;
		position: absolute;
		right: 0;
		top: 0;
		width: 21em;
		height: 14em;
	}

	.video_box_close {
		// background: url("images/ico_close_btn2.png") no-repeat scroll left top transparent;
		display: block;
		height: 18px;
		position: absolute;
		right: -25px;
		top: 0;
		width: 17px;
	}
}

@media screen and (min-width: 360px) and (max-width: 374px) {
	.main-navigation {
		font-size: 0.65em;
	}

	.main-navigation .menu_inside h1 {
		font-size: 4em;
		margin-bottom: 0.2em;
		margin-top: 1em;
	}

	.main-navigation li {
		margin-bottom: 1em;
	}

	.main-navigation .menu_inside img#menu_close {
		width: 25px;
		right: 1em;
		top: 1em;
	}

	#masthead.site-header .wrap,
	.archive .site-content .wrap,
	.error404 .site-content .wrap,
	.single .site-content .wrap,
	.page .site-content .wrap,
	#partners div.wrap,
	#product_side div.wrap {
		width: 92%;
	}

	#masthead.site-header div.wrap {
		text-align: center;
	}

	#masthead.site-header {
		height: 6.8em;
	}

	#masthead.site-header div.wrap div.site-branding {
		left: 32%;
		top: 0.5em;
		width: 8.5em;
	}
	#masthead.site-header div.wrap div.social {
		display: none;
	}

	#masthead.site-header div.wrap div.menu-button {
		top: 2em;
		width: 3em;
		float: left;
	}

	#masthead.site-header div.wrap div.social span#ttl {
		display: none;
	}

	#movies_section div.mobile_res,
	#product_section div.mobile_res {
		display: block;
	}

	.btn span.right,
	.btn span.left {
		box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.13);
		display: inline-block;
		height: 3em;
		line-height: 3em;
		padding: 0 1em;
		transition: background-position 100ms ease 0s;
		vertical-align: top;
	}

	.btn span.right {
		padding: 0;
		transition: background-position 100ms ease 0s;
	}

	.btn {
		margin-bottom: 0.6em;
		font-size: 0.7em;
	}

	#homeslider .slider-for .slide_item .content_side div.wrap div.buttons {
		bottom: 10%;
		right: 0;
		position: absolute;
	}

	#homeslider .slider-for .slide_item .wrap {
		width: 92%;
	}

	#homeslider .slider-for .slide_item div.img_ext {
		height: 158px;
	}

	#homeslider .slider-for .slide_item .wrap h1 {
		bottom: 54%;
		font-size: 1em;
		width: 50%;
		line-height: 1.1em;
	}

	#homeslider .slider-nav {
		width: 96%;
	}

	#homeslider .btn {
		display: block;
		font-size: 0.6em;
		width: 130px;
	}

	// #homeslider .slide_thumbnail_item.slick-center {
	// 	background: transparent url("images/slider/slide_thumb_activeline768.png") no-repeat scroll center top;
	// }

	#homeslider .slide_thumbnail_item {
		padding-top: 0.8em;
	}

	.slick-slider {
		margin-bottom: 5px;
	}

	#newsletter div.wrap {
		width: 90%;
	}

	#newsletter div.wrap div.art span#bld {
		display: inline-block;
	}

	#newsletter div.wrap div.art {
		font-size: 2.1em;
		padding: 1.55em 0 0;
		text-align: center;
		width: 100%;
	}

	#newsletter div.wrap span.group {
		display: block;
		float: left;
		margin: 0 auto 3em;
		position: relative;
		width: 100%;
	}

	#newsletter div.wrap div.newsletter {
		display: block;
		float: none;
		margin: 2em auto 0;
		position: relative;
		width: auto;
	}

	#newsletter div.wrap div.newsletter div.head,
	#newsletter div.wrap div.singup_facebook div.head {
		font-size: 1.35em;
	}

	#newsletter div.wrap div.newsletter form input.newsletter-email {
		// background: url("images/form_icon/2.png") no-repeat scroll 1.6em 0.8em #fff;
		height: 3.1em;
		margin-top: 1px;
		width: 100%;
		padding: 0 1em 0 4em;
		margin-bottom: 0.6em;
	}

	#newsletter div.wrap div.newsletter form button.newsletter-submit {
		width: 100%;
	}

	#newsletter .btn span.left,
	#newsletter .btn.fbb span.left {
		width: 89%;
	}

	#newsletter .btn span.right {
		width: 11%;
	}

	#newsletter span.or {
		display: none;
	}

	#newsletter div.wrap div.singup_facebook span#facebook_login {
		display: inline-block;
		width: 100%;
	}

	#newsletter div.wrap div.singup_facebook {
		clear: both;
		float: left;
		margin-bottom: 2em;
		margin-top: 1.5em;
		width: 100%;
	}

	#newsletter div.wrap div.singup_facebook div.head {
		margin-bottom: 0;
	}

	// .btn span.right.fb span {
	// 	background-image: url("images/fb_letter2.png");
	// }

	#latest_article {
		margin-bottom: 0;
	}

	#latest_article .item {
		float: left;
		margin-right: 0%;
		width: 100%;
	}

	#latest_article .wrap {
		margin-top: 2em;
	}

	#latest_article h1 {
		font-size: 2.3em;
	}

	#latest_article span#stay_connected {
		font-size: 1.2em;
		top: 1.3em;
		right: 2%;
		display: none;
	}

	#latest_article div.wrap div.borderline {
		margin: 2.5em 0 3.2em;
		width: 96%;
		display: none;
	}

	#our_movies div.wrap {
		margin-top: 2em;
		margin-bottom: 0.5em;
	}

	#our_movies div.wrap h1 {
		font-size: 2.3em;
	}

	#our_movies div.wrap div.head div.toggle {
		bottom: 0;
		float: right;
		position: relative;
		right: 0;
		width: 100%;
	}

	#our_movies div.wrap div.head div.toggle span {
		font-size: 0.9em;
		margin-right: 1%;
		margin-left: 0;
		padding: 0.95em 0;
		width: 24.25%;
		text-align: center;
	}

	#our_movies div.wrap div.head div.toggle span#upcoming {
		margin-right: 0%;
	}

	#our_movies div.wrap .movie_itm div.item h3 {
		font-size: 1em;
	}

	#social_feed div.wrap h1 {
		font-size: 2.3em;
	}

	#talebeh .wrap {
		margin-top: 2em;
	}

	#talebeh .talent {
		float: left;
		margin-right: 0;
		width: 100%;
	}

	#talebeh .talent h1 {
		font-size: 2.3em;
	}

	#talebeh .talent div.item div.rightside #about {
		font-size: 0.9em;
		line-height: 1.2em;
	}

	#talebeh .talent div.item div.leftside {
		max-width: 185px;
		width: 36%;
		margin-right: 4%;
	}

	#talebeh .talent div.item div.rightside {
		width: 60%;
	}

	#talebeh .talent div.item div.rightside h3 {
		font-size: 1.8em;
		line-height: 1.2em;
		margin-bottom: 0.2em;
	}

	#talebeh .talent div.item div.rightside .short_content {
		margin-bottom: 0.8em;
	}

	#talebeh .behinds {
		float: left;
		width: 100%;
	}

	#talebeh .behinds h1 {
		font-size: 2.3em;
	}

	#talebeh .behinds div#bcm div.item div.rightside article.short_content {
		display: none;
	}

	#talebeh .behinds div.item div.rightside h3 {
		font-size: 1.5em;
		line-height: 1.2em;
		margin-bottom: 0.5em;
	}

	#store {
		margin-bottom: 1em;
	}

	#store div.wrap .wrap_store {
		margin-right: 0;
		width: 100%;
	}

	#store div.wrap div.product-item div.leftside {
		width: 28%;
		height: auto;
	}

	#store div.wrap div.product-item div.rightside {
		width: 70%;
		padding: 0.8em;
		height: auto;
	}

	#store div.wrap h1 {
		font-size: 2.3em;
	}

	#store div.wrap div.product-item div.rightside a.store_link {
		font-size: 0.85em;
		margin: 0;
	}

	#store div.wrap div.product-item .borderline {
		margin: 1em 0 1em;
	}

	#store div.wrap div.store_form {
		display: none;
	}

	#store div.wrap div.store_form h1 {
		font-size: 1.8em;
		line-height: 1.35em;
	}

	#store div.wrap div.store_form div.form_side {
		height: 23em;
		padding: 1em 1.5em;
	}

	#store div.wrap div.store_form div.form_side .desc {
		font-size: 1.2em;
		line-height: 1.2em;
		margin: 0.2em 0 1.3em;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper div.itm {
		margin-bottom: 1.5em;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper div.itm textarea.comment {
		width: 100%;
		height: 8em;
		// background: url("images/form_icon/4.png") no-repeat scroll 1.5em 1.4em #fff;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper input.inout {
		height: 3.5em;
		line-height: 3.5em;
		margin-right: 2%;
		width: 49%;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper input.inout.email {
		margin-right: 0%;
	}

	#store div.wrap div.store_form div.form_side form#idea_form button.btn.form {
		top: 1em;
	}

	#store div.wrap div.product-item div.rightside h3 {
		font-size: 1.5em;
		line-height: 1.1em;
		margin-bottom: 0.2em;
	}

	#store div.wrap div.product-item div.rightside .short_content {
		margin-bottom: 1em;
	}

	#idea_form .btn.form span.left {
		font-size: 0.8em;
		height: 3.5em;
		line-height: 3.5em;
		padding: 0 0.5em;
		width: 12em;
	}

	#idea_form .btn.form span.right {
		font-size: 0.8em;
		height: 3.5em;
		line-height: 3.5em;
	}

	#red_carpet {
		margin-bottom: 1em;
	}

	#red_carpet div.wrap div.item {
		width: 100%;
	}

	#red_carpet.all_page div.wrap div.item {
		width: 100%;
		display: block;
		margin-bottom: 1em;
	}

	#red_carpet div.wrap a#all_events {
		font-size: 0.3em;
		right: 0.5em;
		top: 3em;
	}

	#red_carpet div.wrap div.item.first {
		width: 100%;
		display: block;
	}

	#red_carpet div.wrap div.item.noimage {
		width: 100%;
	}

	#red_carpet div.wrap div.item div.content_side div.date {
		margin: 0.5em 0 0.6em;
	}

	#red_carpet div.wrap h1 {
		font-size: 2.3em;
	}

	#red_carpet div.wrap div.item div.content_side h3 {
		font-size: 1.5em;
		margin-bottom: 0.5em;
		line-height: 1.2em;
	}

	#red_carpet div.wrap div.item div.img_side {
		float: left;
		width: 39%;
	}

	#red_carpet div.wrap div.item div.content_side {
		background-color: #f8f8f8;
		float: left;
		padding: 1em 1.4em 1.4em;
		width: 61%;
		min-height: 220px;
	}

	#red_carpet div.wrap div.item div.content_side div.link {
		margin-top: 1em;
	}

	#red_carpet div.wrap div.item div.content_side div.link a.more {
		font-size: 1em;
	}

	#partners {
		height: 8em;
	}

	#partners div.wrap div.head {
		display: none;
	}

	#partners div.wrap div.sponsor {
		margin-left: 0.5em;
	}

	#partners div.wrap div.sponsor.s1 {
		width: 5.8em;
		margin-top: 2.5em;
	}

	#partners div.wrap div.sponsor.s2 {
		width: 3.8em;
		margin-top: 2.1em;
	}

	#partners div.wrap div.sponsor.s3 {
		width: 5.8em;
		margin-top: 3em;
	}

	#partners div.wrap div.sponsor.s4 {
		width: 5.8em;
		margin-top: 2.8em;
		margin-left: 0.5em;
	}

	.site-footer {
		height: 20em;
	}

	.site-footer div.site-info {
		margin-top: 4em;
	}

	/*********************************************/

	.movies_single #movies_section .content_side {
		top: 0;
	}

	.movies_single #movies_section .content_side .wrap {
		width: 92%;
	}

	.movies_single #movies_section .content_side .wrap .ext {
		padding: 1em 1em 0.8em;
	}

	.movies_single #movies_section div.content_side .wrap .image_side .ext_img {
		position: relative;
		top: 0em;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side .topside {
		margin-bottom: 0.5em;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side div.top_content_left {
		margin-right: 0;
		width: 100%;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side div.top_content_left,
	.movies_single #movies_section div.content_side div.wrap div.right_side div.top_content_right {
		display: none;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side div.m_item.synoptis {
		margin-bottom: 0em;
	}

	.movies_single
		#movies_section
		div.content_side
		div.wrap
		div.right_side
		.top_content_left
		div.m_item
		span.article.synopsis {
		height: 130px;
	}

	#movies_section div.img_ext {
		height: 300px;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side h1 {
		font-size: 1.1em;
		line-height: 1.2em;
		margin-right: 0;
		margin-top: 0;
	}

	#movies_section div.content_side div.wrap div.right_side div.pg span.obi {
		width: 30%;
	}

	#movies_section div.content_side div.wrap div.right_side div.pg {
		display: none;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side .watch_online {
		margin-right: 1em;
		margin-top: 0.5em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side .watch_online span.watch_span a {
		font-size: 0.5em;
	}

	#movies_section div.content_side div.wrap div.ext div.image_side div.stay_review span.review_click a {
		font-size: 0.5em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.off_web {
		margin-right: 1em;
		margin-top: 0.5em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.off_web a {
		font-size: 0.5em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side a.btn {
		font-size: 0.5em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.social a {
		width: 16px;
		height: 16px;
		margin-right: 5px;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.social a.fb {
		// background: transparent url("images/social_movie1.png") no-repeat scroll 0 0;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.social a.twt {
		// background: transparent url("images/social_movie1.png") no-repeat scroll -21px 0;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.social a.vimeo {
		// background: transparent url("images/social_movie1.png") no-repeat scroll -42px 0;
	}

	#movies_section div.mobile_res div.wrap .m_item .article {
		font-size: 0.8em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.buy {
		margin-right: 0;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.social {
		margin-top: 0.85em;
	}

	#movies_section div.ratings_sm {
		margin: 10px auto;
	}

	#movies_section div.ratings_sm .read_rew {
		font-size: 10px;
	}

	.product_single #product_section .content_side {
		top: 6.5em;
	}

	.product_single #product_section div.content_side .wrap .image_side .ext_img {
		position: relative;
		top: 1em;
	}

	.product_single #product_section .content_side .wrap .ext {
		padding: 1em 1em 0.8em;
	}

	.product_single #product_section div.content_side div.wrap div.right_side h1 {
		font-size: 1em;
		line-height: 1.2em;
		margin-right: 0;
		margin-top: 0em;
	}

	.product_single #product_section div.content_side div.wrap div.right_side .topside {
		margin-bottom: 1em;
	}

	.product_single #product_section div.bottom_side div.price {
		margin-right: 1em;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.bottom_side a.btn {
		font-size: 0.56em;
		margin-bottom: 0.1em;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.pg span.obi {
		width: 30%;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.pg {
		display: none;
	}

	.product_single #product_section a.btn {
		font-size: 0.5em;
		margin-right: 0em;
	}

	.product_single #product_section div.content_side .wrap .image_side {
		margin-top: 0;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.bottom_side div.treiler,
	.product_single #product_section div.content_side div.wrap div.right_side div.bottom_side div.buy {
		margin: 0;
		line-height: 1em;
	}

	.product_single #product_section div.bottom_side div.price p.price span.heads {
		font-size: 1em;
	}

	.product_single #product_section div.bottom_side div.price p.price span.amount {
		font-size: 1.5em;
	}
	/**********************************************/

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.leftside {
		margin-right: 3%;
		max-width: 185px;
		width: 29%;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside {
		width: 67%;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside .short_content {
		margin-bottom: 1em;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside .short_content p {
		margin-bottom: 1em;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside #about {
		font-size: 1em;
		line-height: 1.2em;
	}

	.talent_highlight #more_like_this div.wrap div#bcm h1 {
		font-size: 2.5em;
		margin-bottom: 1.1em;
		padding-bottom: 0.2em;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside h3 {
		font-size: 1.8em;
		line-height: 1.2em;
		margin-bottom: 0.2em;
	}

	.behind #more_like_this div.wrap div#bcm div.item div.rightside h3 {
		font-size: 1.3em;
		line-height: 1.2em;
		margin-bottom: 0.5em;
	}

	.single .site-content .wrap {
		margin-top: 3em;
	}

	#more_product div.wrap div.product-item div.rightside .short_content {
		margin-bottom: 2em;
	}

	#more_product div.wrap h1 {
		font-size: 2.5em;
	}

	#more_product div.wrap div.product-item {
		margin-right: 0;
		width: 100%;
	}

	#more_product div.wrap div.product-item div.leftside {
		margin-right: 2%;
		width: 25%;
	}

	#more_product div.wrap div.product-item div.rightside {
		width: 73%;
	}

	#more_product div.wrap div.product-item div.rightside h3 {
		font-size: 1.4em;
		line-height: 1.1em;
		margin-bottom: 0.2em;
	}

	#more_product div.wrap div.product-item div.rightside div.price p.price {
		margin-bottom: 1em;
	}

	#behind_section {
		padding-bottom: 1em;
	}

	#behind_section div.wrap div#bsl div.item h1,
	.behind #more_like_this div.wrap div#bcm h1 {
		font-size: 1.8em;
		line-height: 1.2em;
	}

	#behind_section div.wrap div#bsl {
		padding-top: 3em;
	}

	#behind_section div.wrap div#bsl {
		width: 100%;
	}

	#talent_highlight div.wrap div#bsl div.item h1 {
		font-size: 2.5em;
	}

	#talent_highlight div.wrap div#bsl div.item div.topside span#about {
		font-size: 1.2em;
		top: -0.5em;
	}

	.talent_highlight #more_like_this div.wrap div#bcm {
		width: 100%;
	}

	#product_side div.wrap div.product-item {
		margin-right: 0;
		width: 100%;
	}

	#product_side div.wrap div.product-item div.leftside {
		width: 25%;
	}

	#product_side div.wrap div.product-item div.rightside {
		width: 73%;
	}

	#movies_section div.mobile_res {
		border-top: 7px solid #ea593b;
		border-bottom: 7px solid #029e93;
		float: left;
		width: 100%;
	}

	#movies_section div.mobile_res div.wrap {
		margin-top: 2em;
	}

	#movies_section div.mobile_res div.wrap .left_side {
		float: left;
		margin-right: 3%;
		width: 48.5%;
	}

	#movies_section div.mobile_res div.wrap .right_side {
		float: left;
		width: 48.5%;
	}

	#movies_section div.mobile_res div.wrap .m_item {
		color: #a9a9a9;
		font-family: "proxima_novalight";
		font-size: 0.9em;
		margin-bottom: 1.8em;
		width: 100%;
	}

	#movies_section div.mobile_res div.wrap .m_item h4 {
		color: #fff;
		font-family: "proxima_novaextrabold";
		font-size: 1em;
		text-decoration: none;
		text-transform: uppercase;
	}

	.product_single div.img_ext {
		height: 300px;
	}

	.product_single .mobile_res {
		border-top: 7px solid #ea593b;
		border-bottom: 7px solid #029e93;
		float: left;
		width: 100%;
		background-color: #0c0c0c;
	}

	.product_single .mobile_res div.wrap {
		margin-top: 2em;
	}

	.product_single .mobile_res div.wrap div.m_item {
		color: #a9a9a9;
		font-family: "proxima_novalight";
		font-size: 0.9em;
		margin-bottom: 1.8em;
		width: 100%;
	}

	.product_single .mobile_res div.wrap div.m_item h4 {
		color: #fff;
		font-family: "proxima_novaextrabold";
		font-size: 1em;
		text-decoration: none;
		text-transform: uppercase;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.top_content_left {
		display: none;
	}

	#talent_highlight div.wrap div#bsl {
		padding-top: 3em;
		width: 100%;
	}

	#talent_highlight div.wrap div#bsl div.item h1 {
		display: block;
	}

	#talent_highlight div.wrap div#bsl div.item div.leftside {
		float: left;
		width: 100%;
	}

	#talent_highlight div.wrap div#bsl div.item div.rightside {
		margin-top: 2em;
		width: 100%;
	}

	#talent_highlight div.wrap div#bsl div.item div.leftside span.pop_up {
		width: 100%;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item {
		width: 100%;
	}

	#behind_section div.wrap div#bsl div.item div.leftside {
		width: 100%;
	}

	#behind_section div.wrap div#bsl div.item div.leftside a.pop_up {
		width: 100%;
	}

	#behind_section div.wrap div#bsl div.item div.rightside {
		margin-top: 2em;
		width: 100%;
	}

	.behind #more_like_this div.wrap div#bcm div.item {
		width: 100%;
	}

	.behind #more_like_this div.wrap div#bcm div.item div.rightside {
		width: 57%;
	}

	.behind #more_like_this div.wrap div#bcm div.item div.rightside .short_content {
		display: none;
	}

	#red-carpet-event {
		padding-bottom: 1em;
		padding-top: 1em;
	}

	#red-carpet-event div.wrap {
		margin-top: 2em;
	}

	#red-carpet-event div.wrap div.img_side {
		background-color: #f8f8f8;
		float: left;
		margin-right: 0;
		width: auto;
		max-width: 100%;
	}

	#red-carpet-event div.wrap div.content_side,
	#red-carpet-event div.wrap div.content_side.full {
		margin-right: 0;
		width: 100%;
	}

	#red-carpet-event div.wrap div.content_side h1 {
		font-size: 2em;
		line-height: 1.1em;
		margin: 0.3em 0;
	}

	#red-carpet-event div.wrap div.form_side {
		display: none;
	}

	#primary.content-area {
		float: left;
		margin-right: 0;
		width: 100% !important;
	}

	#secondary.widget-area {
		float: left;
		margin-top: 2.05em;
		width: 100% !important;
	}

	.single .site-content #primary.content-area .site-main .entry-header h1.entry-title {
		font-size: 2em;
		line-height: 1.1em;
	}

	#secondary.widget-area .widget h1.widget-title {
		font-size: 1.5em;
		margin-bottom: 0.5em;
		padding-bottom: 0.5em;
	}

	#secondary.widget-area .widget ul li {
		margin-bottom: 0.5em;
	}

	.archive .site-main .page-header h1.page-title {
		color: #029e93;
		font-size: 2em;
		font-style: italic;
		line-height: 1.3em;
	}

	.archive .site-main .page-header h1.page-title span {
		display: block;
		font-size: 1.2em;
		color: #272727;
		font-family: "proxima_nova_bold";
		font-weight: normal;
		font-style: normal;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.bs {
		float: left;
		width: 100%;
	}

	.news_archive div.wrap #primary .entry-header h2.entry-title {
		font-size: 2em;
		line-height: 1.1em;
	}

	#overlay div#review-box {
		background-color: #fff;
		padding: 1.5em;
		position: absolute;
		width: 80%;
	}

	#overlay div#review-box a.review_box_close {
		// background: url("images/close_btn_rating.jpg") no-repeat scroll left top transparent;
		display: block;
		height: 32px;
		position: absolute;
		right: -5px;
		top: -5px;
		width: 31px;
	}

	#overlay div#review-box div.wrap div.external form#commentform h1 {
		color: #0c0c0c;
		font-family: "proxima_novablack";
		font-size: 2em;
		text-transform: uppercase;
	}

	#overlay div#review-box div.wrap div.external form#commentform div.rateit {
		float: left;
		margin-right: 1.5em;
		margin-top: 0em;
	}

	#overlay div#review-box div.wrap div.external form#commentform div.rate_text {
		color: #717171;
		float: left;
		font-family: "proxima_nova_regular";
		font-size: 0.8em;
		margin: 0em 0 1.5em;
	}

	#overlay div#review-box div.wrap div.external form#commentform p.comment-form-comment,
	#overlay div#review-box div.wrap div.external form#commentform p.comment-form-author,
	#overlay div#review-box div.wrap div.external form#commentform p.comment-form-email {
		margin-bottom: 0.5em;
	}

	#overlay div#review-box div.wrap div.external form#commentform textarea#comment {
		// background: url("images/form_icon/4.png") no-repeat scroll 1em 1em #fff;
		border: 1px solid #cfcfcf;
		border-radius: 0;
		color: #747474;
		font-family: "proxima_nova_regular";
		height: 5em;
		padding: 1em 1em 0 2.9em;
		resize: none;
		width: 100%;
	}

	#overlay div#review-box div.wrap div.external form#commentform input {
		background-color: #fff;
		border: 1px solid #cfcfcf;
		border-radius: 0;
		color: #747474;
		float: left;
		font-family: "proxima_nova_regular";
		height: 2em;
		line-height: 2em;
		padding: 0 1em 0 2.9em;
		width: 49%;
	}

	#overlay div#review-box div.wrap div.external form#commentform input#author {
		// background: url("images/form_icon/1.png") no-repeat scroll 1em center #fff;
		margin-right: 2%;
	}

	#overlay div#review-box div.wrap div.external form#commentform input#email {
		// background: url("images/form_icon/2.png") no-repeat scroll 1em center #fff;
		margin-right: 0;
	}

	#overlay div#review-box div.wrap div.external form#commentform button.btn.form {
		left: 0em;
		top: 1em;
		font-size: 0.61em;
	}

	#video-box {
		background-color: #000;
		position: absolute;
		right: 0;
		top: 0;
		width: 21em;
		height: 14em;
	}

	.video_box_close {
		// background: url("images/ico_close_btn2.png") no-repeat scroll left top transparent;
		display: block;
		height: 18px;
		position: absolute;
		right: -25px;
		top: 0;
		width: 17px;
	}
}

@media screen and (min-width: 0px) and (max-width: 359px) {
	.main-navigation {
		font-size: 0.65em;
	}

	.main-navigation .menu_inside h1 {
		font-size: 4em;
		margin-bottom: 0.2em;
		margin-top: 1em;
	}

	.main-navigation li {
		margin-bottom: 1em;
	}

	.main-navigation .menu_inside img#menu_close {
		width: 25px;
		right: 1em;
		top: 1em;
	}

	#masthead.site-header .wrap,
	.archive .site-content .wrap,
	.error404 .site-content .wrap,
	.single .site-content .wrap,
	.page .site-content .wrap,
	#partners div.wrap,
	#product_side div.wrap {
		width: 92%;
	}

	#masthead.site-header div.wrap {
		text-align: center;
	}

	#masthead.site-header {
		height: 6.8em;
	}

	#masthead.site-header div.wrap div.site-branding {
		left: 32%;
		top: 0.5em;
		width: 8.5em;
	}
	#masthead.site-header div.wrap div.social {
		display: none;
	}

	#masthead.site-header div.wrap div.menu-button {
		top: 2em;
		width: 3em;
		float: left;
	}

	#masthead.site-header div.wrap div.social span#ttl {
		display: none;
	}

	#movies_section div.mobile_res,
	#product_section div.mobile_res {
		display: block;
	}

	.btn span.right,
	.btn span.left {
		box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.13);
		display: inline-block;
		height: 3em;
		line-height: 3em;
		padding: 0 1em;
		transition: background-position 100ms ease 0s;
		vertical-align: top;
	}

	.btn span.right {
		padding: 0;
		transition: background-position 100ms ease 0s;
	}

	.btn {
		margin-bottom: 0.6em;
		font-size: 0.7em;
	}

	#homeslider .slider-for .slide_item .content_side div.wrap div.buttons {
		bottom: 5%;
		right: 0;
		position: absolute;
	}

	#homeslider .btn {
		display: block;
		font-size: 0.55em;
		width: 120px;
	}

	#homeslider .slider-for .slide_item .wrap {
		width: 92%;
	}

	#homeslider .slider-for .slide_item div.img_ext {
		height: 140px;
	}

	#homeslider .slider-for .slide_item .wrap h1 {
		bottom: 55%;
		font-size: 1em;
		width: 50%;
		line-height: 1.1em;
	}

	#homeslider .slider-nav {
		width: 96%;
	}

	#homeslider .slide_thumbnail_item.slick-center {
		// background: transparent url("images/slider/slide_thumb_activeline768.png") no-repeat scroll center top;
	}

	#homeslider .slide_thumbnail_item {
		padding-top: 0.8em;
	}

	.slick-slider {
		margin-bottom: 5px;
	}

	#newsletter div.wrap {
		width: 90%;
	}

	#newsletter div.wrap div.art span#bld {
		display: inline-block;
	}

	#newsletter div.wrap div.art {
		font-size: 2.1em;
		padding: 1.55em 0 0;
		text-align: center;
		width: 100%;
	}

	#newsletter div.wrap span.group {
		display: block;
		float: left;
		margin: 0 auto 3em;
		position: relative;
		width: 100%;
	}

	#newsletter div.wrap div.newsletter {
		display: block;
		float: none;
		margin: 2em auto 0;
		position: relative;
		width: auto;
	}

	#newsletter div.wrap div.newsletter div.head,
	#newsletter div.wrap div.singup_facebook div.head {
		font-size: 1.3em;
	}

	#newsletter div.wrap div.newsletter form input.newsletter-email {
		// background: url("images/form_icon/2.png") no-repeat scroll 1.6em 0.8em #fff;
		height: 3.1em;
		margin-top: 1px;
		width: 100%;
		padding: 0 1em 0 4em;
		margin-bottom: 0.6em;
	}

	#newsletter div.wrap div.newsletter form button.newsletter-submit {
		width: 100%;
	}

	#newsletter .btn span.left,
	#newsletter .btn.fbb span.left {
		width: 89%;
	}

	#newsletter .btn span.right {
		width: 11%;
	}

	#newsletter span.or {
		display: none;
	}

	#newsletter div.wrap div.singup_facebook span#facebook_login {
		display: inline-block;
		width: 100%;
	}

	#newsletter div.wrap div.singup_facebook {
		clear: both;
		float: left;
		margin-bottom: 2em;
		margin-top: 1.5em;
		width: 100%;
	}

	#newsletter div.wrap div.singup_facebook div.head {
		margin-bottom: 0;
	}

	.btn span.right.fb span {
		// background-image: url("images/fb_letter2.png");
	}

	#latest_article {
		margin-bottom: 0;
	}

	#latest_article .item {
		float: left;
		margin-right: 0%;
		width: 100%;
	}

	#latest_article .wrap {
		margin-top: 2em;
	}

	#latest_article h1 {
		font-size: 2.3em;
	}

	#latest_article span#stay_connected {
		font-size: 1.2em;
		top: 1.3em;
		right: 2%;
		display: none;
	}

	#latest_article div.wrap div.borderline {
		margin: 2.5em 0 3.2em;
		width: 96%;
		display: none;
	}

	#our_movies div.wrap {
		margin-top: 2em;
		margin-bottom: 0.5em;
	}

	#our_movies div.wrap h1 {
		font-size: 2.3em;
	}

	#our_movies div.wrap a.movie_itm div.item h3 {
		font-size: 1em;
		line-height: 1.1em;
	}

	#our_movies div.wrap div.head div.toggle {
		bottom: 0;
		float: right;
		position: relative;
		right: 0;
		width: 100%;
	}

	#our_movies div.wrap div.head div.toggle span {
		font-size: 0.85em;
		margin-right: 1%;
		margin-left: 0;
		padding: 0.95em 0;
		width: 24.25%;
		text-align: center;
	}

	#our_movies div.wrap div.head div.toggle span#upcoming {
		margin-right: 0%;
	}

	#our_movies div.wrap .movie_itm div.item h3 {
		font-size: 1em;
	}

	#social_feed div.wrap h1 {
		font-size: 2.3em;
	}

	#talebeh .wrap {
		margin-top: 2em;
	}

	#talebeh .talent {
		float: left;
		margin-right: 0;
		width: 100%;
	}

	#talebeh .talent h1 {
		font-size: 2em;
	}

	#talebeh .talent div.item div.rightside #about {
		font-size: 0.9em;
		line-height: 1.2em;
	}

	#talebeh .talent div.item div.leftside {
		max-width: 185px;
		width: 36%;
		margin-right: 4%;
	}

	#talebeh .talent div.item div.rightside {
		width: 60%;
	}

	#talebeh .talent div.item div.rightside h3 {
		font-size: 1.8em;
		line-height: 1.2em;
		margin-bottom: 0.2em;
	}

	#talebeh .talent div.item div.rightside .short_content {
		margin-bottom: 0.8em;
	}

	#talebeh .behinds {
		float: left;
		width: 100%;
	}

	#talebeh .behinds h1 {
		font-size: 2em;
	}

	#talebeh .behinds div#bcm div.item div.rightside article.short_content {
		display: none;
	}

	#talebeh .behinds div.item div.rightside h3 {
		font-size: 1.2em;
		line-height: 1.2em;
		margin-bottom: 0.5em;
	}

	#store {
		margin-bottom: 1em;
	}

	#store div.wrap .wrap_store {
		margin-right: 0;
		width: 100%;
	}

	#store div.wrap div.product-item div.leftside {
		width: 28%;
		height: auto;
	}

	#store div.wrap div.product-item div.rightside {
		width: 70%;
		padding: 0.8em;
		height: auto;
	}

	#store div.wrap h1 {
		font-size: 2.3em;
	}

	#store div.wrap div.product-item div.rightside a.store_link {
		font-size: 0.85em;
		margin: 0;
	}

	#store div.wrap div.product-item .borderline {
		margin: 1em 0 1em;
	}

	#store div.wrap div.store_form {
		display: none;
	}

	#store div.wrap div.store_form h1 {
		font-size: 1.8em;
		line-height: 1.35em;
	}

	#store div.wrap div.store_form div.form_side {
		height: 23em;
		padding: 1em 1.5em;
	}

	#store div.wrap div.store_form div.form_side .desc {
		font-size: 1.2em;
		line-height: 1.2em;
		margin: 0.2em 0 1.3em;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper div.itm {
		margin-bottom: 1.5em;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper div.itm textarea.comment {
		width: 100%;
		height: 8em;
		// background: url("images/form_icon/4.png") no-repeat scroll 1.5em 1.4em #fff;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper input.inout {
		height: 3.5em;
		line-height: 3.5em;
		margin-right: 2%;
		width: 49%;
	}

	#store div.wrap div.store_form div.form_side form#idea_form div#c_wrapper input.inout.email {
		margin-right: 0%;
	}

	#store div.wrap div.store_form div.form_side form#idea_form button.btn.form {
		top: 1em;
	}

	#store div.wrap div.product-item div.rightside h3 {
		font-size: 1.5em;
		line-height: 1.1em;
		margin-bottom: 0.2em;
	}

	#store div.wrap div.product-item div.rightside .short_content {
		margin-bottom: 1em;
	}

	#store div.wrap div.product-item div.rightside div.price p.price span.heads {
		font-size: 1em;
		line-height: 1.4em;
	}

	#store div.wrap div.product-item div.rightside div.price p.price span.amount {
		font-size: 1.4em;
	}

	#idea_form .btn.form span.left {
		font-size: 0.8em;
		height: 3.5em;
		line-height: 3.5em;
		padding: 0 0.5em;
		width: 12em;
	}

	#idea_form .btn.form span.right {
		font-size: 0.8em;
		height: 3.5em;
		line-height: 3.5em;
	}

	#red_carpet {
		margin-bottom: 1em;
	}

	#red_carpet div.wrap div.item {
		width: 100%;
	}

	#red_carpet.all_page div.wrap div.item {
		width: 100%;
		display: block;
		margin-bottom: 1em;
	}

	#red_carpet div.wrap a#all_events {
		font-size: 0.3em;
		right: 0.5em;
		top: 3.2em;
	}

	#red_carpet div.wrap div.item.first {
		width: 100%;
		display: block;
	}

	#red_carpet div.wrap div.item.noimage {
		width: 100%;
	}

	#red_carpet div.wrap div.item div.content_side div.date {
		margin: 0.5em 0 0.6em;
	}

	#red_carpet div.wrap h1 {
		font-size: 2em;
	}

	#red_carpet div.wrap div.item div.content_side h3 {
		font-size: 1.5em;
		margin-bottom: 0.5em;
		line-height: 1.2em;
	}

	#red_carpet div.wrap div.item div.img_side {
		float: left;
		width: 39%;
	}

	#red_carpet div.wrap div.item div.content_side {
		background-color: #f8f8f8;
		float: left;
		padding: 1em 1.4em 1.4em;
		width: 61%;
		min-height: 220px;
	}

	#red_carpet div.wrap div.item div.content_side div.link {
		margin-top: 1em;
	}

	#red_carpet div.wrap div.item div.content_side div.link a.more {
		font-size: 1em;
	}

	#partners {
		height: 6em;
	}

	#partners div.wrap div.head {
		display: none;
	}

	#partners div.wrap div.sponsor {
		margin-left: 0.5em;
	}

	#partners div.wrap div.sponsor.s1 {
		width: 5.2em;
		margin-top: 1.8em;
	}

	#partners div.wrap div.sponsor.s2 {
		width: 3.2em;
		margin-top: 1.5em;
	}

	#partners div.wrap div.sponsor.s3 {
		width: 5.2em;
		margin-top: 2.2em;
	}

	#partners div.wrap div.sponsor.s4 {
		width: 5.2em;
		margin-top: 2.1em;
		margin-left: 0.5em;
	}

	.site-footer {
		height: 20em;
	}

	.site-footer div.site-info {
		margin-top: 4em;
	}

	/*********************************************/

	.movies_single #movies_section .content_side {
		top: 0;
	}

	.movies_single #movies_section .content_side .wrap {
		width: 92%;
	}

	.movies_single #movies_section .content_side .wrap .ext {
		padding: 1em 1em 0.8em;
	}

	.movies_single #movies_section div.content_side .wrap .image_side .ext_img {
		position: relative;
		top: 0em;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side .topside {
		margin-bottom: 0.1em;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side div.top_content_left {
		margin-right: 0;
		width: 100%;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side div.top_content_left,
	.movies_single #movies_section div.content_side div.wrap div.right_side div.top_content_right {
		display: none;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side div.m_item.synoptis {
		margin-bottom: 0em;
	}

	.movies_single
		#movies_section
		div.content_side
		div.wrap
		div.right_side
		.top_content_left
		div.m_item
		span.article.synopsis {
		height: 130px;
	}

	#movies_section div.img_ext {
		height: 300px;
	}

	.movies_single #movies_section div.content_side div.wrap div.right_side h1 {
		font-size: 1.1em;
		line-height: 1.2em;
		margin-right: 0;
		margin-top: 0;
	}

	#movies_section div.content_side div.wrap div.right_side div.pg span.obi {
		width: 30%;
	}

	#movies_section div.content_side div.wrap div.right_side div.pg {
		display: none;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side .watch_online {
		margin-right: 0.5em;
		margin-top: 0.2em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.social a {
		width: 16px;
		height: 16px;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.social a.fb {
		// background: transparent url("images/social_movie1.png") no-repeat scroll 0 0;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.social a.twt {
		// background: transparent url("images/social_movie1.png") no-repeat scroll -21px 0;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.social a.vimeo {
		// background: transparent url("images/social_movie1.png") no-repeat scroll -42px 0;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side .watch_online span.watch_span a {
		font-size: 0.5em;
	}

	#movies_section div.content_side div.wrap div.ext div.image_side div.stay_review span.review_click a {
		font-size: 0.5em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.off_web {
		margin-right: 1em;
		margin-top: 0.2em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.off_web a {
		font-size: 0.5em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side a.btn {
		font-size: 0.4em;
	}

	#movies_section .btn span.left {
		width: 14em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.social a {
		margin-right: 5px;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.treiler {
		margin-right: 0.5em;
	}

	#movies_section div.mobile_res div.wrap .m_item .article {
		font-size: 0.8em;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.buy {
		margin-right: 0;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.social {
		margin-top: 0.5em;
	}

	#movies_section div.ratings_sm {
		margin: 25px auto 0;
	}

	#movies_section div.ratings_sm .read_rew {
		font-size: 10px;
	}

	.product_single #product_section .content_side {
		top: 6.5em;
	}

	.product_single #product_section div.content_side .wrap .image_side .ext_img {
		position: relative;
		top: 1em;
	}

	.product_single #product_section .content_side .wrap .ext {
		padding: 1em 1em 0.2em;
	}

	.product_single #product_section div.content_side div.wrap div.right_side h1 {
		font-size: 1em;
		line-height: 1.2em;
		margin-right: 0;
		margin-top: 0em;
	}

	.product_single #product_section div.content_side div.wrap div.right_side .topside {
		margin-bottom: 0.1em;
	}

	.product_single #product_section div.bottom_side div.price {
		margin-right: 1em;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.bottom_side a.btn {
		font-size: 0.43em;
		margin-bottom: 0.1em;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.pg span.obi {
		width: 30%;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.pg {
		display: none;
	}

	.product_single #product_section a.btn {
		font-size: 0.5em;
		margin-right: 0.5em;
		margin-bottom: 0;
	}

	.product_single #product_section div.bottom_side div.price {
		margin-right: 1em;
	}

	.product_single #product_section div.content_side .wrap .image_side {
		margin-top: 0;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.bottom_side div.treiler,
	.product_single #product_section div.content_side div.wrap div.right_side div.bottom_side div.buy {
		margin: 0;
		line-height: 1em;
	}

	.product_single #product_section div.bottom_side div.price p.price span.heads {
		font-size: 1em;
	}

	.product_single #product_section div.bottom_side div.price p.price span.amount {
		font-size: 1.5em;
	}
	/**********************************************/

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.leftside {
		margin-right: 3%;
		max-width: 185px;
		width: 29%;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside {
		width: 67%;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside .short_content {
		margin-bottom: 1em;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside .short_content p {
		margin-bottom: 1em;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside #about {
		font-size: 1em;
		line-height: 1.2em;
	}

	.talent_highlight #more_like_this div.wrap div#bcm h1 {
		font-size: 2.5em;
		margin-bottom: 1.1em;
		padding-bottom: 0.2em;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item div.rightside h3 {
		font-size: 1.8em;
		line-height: 1.2em;
		margin-bottom: 0.2em;
	}

	.behind #more_like_this div.wrap div#bcm div.item div.rightside h3 {
		font-size: 1.1em;
		line-height: 1.2em;
		margin-bottom: 0.5em;
	}

	.single .site-content .wrap {
		margin-top: 3em;
	}

	#more_product div.wrap div.product-item div.rightside .short_content {
		margin-bottom: 2em;
	}

	#more_product div.wrap h1 {
		font-size: 2.5em;
	}

	#more_product div.wrap div.product-item {
		margin-right: 0;
		width: 100%;
	}

	#more_product div.wrap div.product-item div.leftside {
		margin-right: 2%;
		width: 25%;
	}

	#more_product div.wrap div.product-item div.rightside {
		width: 73%;
	}

	#more_product div.wrap div.product-item div.rightside h3 {
		font-size: 1.2em;
		line-height: 1.1em;
		margin-bottom: 0.2em;
	}

	#more_product div.wrap div.product-item div.rightside div.price p.price span.amount {
		font-size: 1.2em;
		line-height: 1;
	}

	#more_product div.wrap div.product-item div.rightside div.price p.price span.heads {
		font-size: 1em;
		line-height: 1.4em;
	}

	#more_product div.wrap div.product-item div.rightside div.price p.price {
		margin-bottom: 1em;
	}

	#product_side div.wrap {
		margin-top: 2.5em;
	}

	#product_side div.wrap h1 {
		font-size: 2.5em;
		margin-bottom: 0.3em;
	}

	#product_side div.wrap div.product-item div.rightside h3 {
		font-size: 1.4em;
		line-height: 1.1em;
		margin-bottom: 0.2em;
	}

	#product_side div.wrap div.product-item div.rightside div.price p.price span.heads {
		font-size: 1em;
		line-height: 1.4em;
	}

	#product_side div.wrap div.product-item div.rightside .short_content {
		margin-bottom: 1em;
	}

	#product_side div.wrap div.product-item div.rightside div.price p.price span.amount {
		font-size: 1.3em;
		line-height: 1;
	}

	#behind_section {
		padding-bottom: 1em;
	}

	#behind_section div.wrap div#bsl div.item h1,
	.behind #more_like_this div.wrap div#bcm h1 {
		font-size: 1.5em;
		line-height: 1.2em;
	}

	#behind_section div.wrap div#bsl {
		padding-top: 3em;
	}

	#behind_section div.wrap div#bsl {
		width: 100%;
	}

	#talent_highlight div.wrap div#bsl div.item h1 {
		font-size: 2.5em;
	}

	#talent_highlight div.wrap div#bsl div.item div.topside span#about {
		font-size: 1.2em;
		top: -0.5em;
	}

	.talent_highlight #more_like_this div.wrap div#bcm {
		width: 100%;
	}

	#product_side div.wrap div.product-item {
		margin-right: 0;
		width: 100%;
	}

	#product_side div.wrap div.product-item div.leftside {
		width: 25%;
	}

	#product_side div.wrap div.product-item div.rightside {
		width: 73%;
	}

	#movies_section div.mobile_res {
		border-top: 7px solid #ea593b;
		border-bottom: 7px solid #029e93;
		float: left;
		width: 100%;
	}

	#movies_section div.mobile_res div.wrap {
		margin-top: 2em;
	}

	#movies_section div.mobile_res div.wrap .left_side {
		float: left;
		margin-right: 3%;
		width: 48.5%;
	}

	#movies_section div.mobile_res div.wrap .right_side {
		float: left;
		width: 48.5%;
	}

	#movies_section div.mobile_res div.wrap .m_item {
		color: #a9a9a9;
		font-family: "proxima_novalight";
		font-size: 0.9em;
		margin-bottom: 1.8em;
		width: 100%;
	}

	#movies_section div.mobile_res div.wrap .m_item h4 {
		color: #fff;
		font-family: "proxima_novaextrabold";
		font-size: 1em;
		text-decoration: none;
		text-transform: uppercase;
	}

	.product_single div.img_ext {
		height: 270px;
	}

	.product_single .mobile_res {
		border-top: 7px solid #ea593b;
		border-bottom: 7px solid #029e93;
		float: left;
		width: 100%;
		background-color: #0c0c0c;
	}

	.product_single .mobile_res div.wrap {
		margin-top: 2em;
	}

	.product_single .mobile_res div.wrap div.m_item {
		color: #a9a9a9;
		font-family: "proxima_novalight";
		font-size: 0.9em;
		margin-bottom: 1.8em;
		width: 100%;
	}

	.product_single .mobile_res div.wrap div.m_item h4 {
		color: #fff;
		font-family: "proxima_novaextrabold";
		font-size: 1em;
		text-decoration: none;
		text-transform: uppercase;
	}

	.product_single #product_section div.content_side div.wrap div.right_side div.top_content_left {
		display: none;
	}

	#talent_highlight div.wrap div#bsl {
		padding-top: 3em;
		width: 100%;
	}

	#talent_highlight div.wrap div#bsl div.item h1 {
		display: block;
	}

	#talent_highlight div.wrap div#bsl div.item div.leftside {
		float: left;
		width: 100%;
	}

	#talent_highlight div.wrap div#bsl div.item div.rightside {
		margin-top: 2em;
		width: 100%;
	}

	#talent_highlight div.wrap div#bsl div.item div.leftside span.pop_up {
		width: 100%;
	}

	.talent_highlight #more_like_this div.wrap div#bcm div.item {
		width: 100%;
	}

	#behind_section div.wrap div#bsl div.item div.leftside {
		width: 100%;
	}

	#behind_section div.wrap div#bsl div.item div.leftside a.pop_up {
		width: 100%;
	}

	#behind_section div.wrap div#bsl div.item div.rightside {
		margin-top: 2em;
		width: 100%;
	}

	.behind #more_like_this div.wrap div#bcm div.item {
		width: 100%;
	}

	.behind #more_like_this div.wrap div#bcm div.item div.rightside {
		width: 57%;
	}

	.behind #more_like_this div.wrap div#bcm div.item div.rightside .short_content {
		display: none;
	}

	#red-carpet-event {
		padding-bottom: 1em;
		padding-top: 1em;
	}

	#red-carpet-event div.wrap {
		margin-top: 2em;
	}

	#red-carpet-event div.wrap div.img_side {
		background-color: #f8f8f8;
		float: left;
		margin-right: 0;
		width: auto;
		max-width: 100%;
	}

	#red-carpet-event div.wrap div.content_side,
	#red-carpet-event div.wrap div.content_side.full {
		margin-right: 0;
		width: 100%;
	}

	#red-carpet-event div.wrap div.content_side h1 {
		font-size: 1.5em;
		line-height: 1.1em;
		margin: 0.3em 0;
	}

	#red-carpet-event div.wrap div.form_side {
		display: none;
	}

	#primary.content-area {
		float: left;
		margin-right: 0;
		width: 100% !important;
	}

	#secondary.widget-area {
		float: left;
		margin-top: 2.05em;
		width: 100% !important;
	}

	.single .site-content #primary.content-area .site-main .entry-header h1.entry-title {
		font-size: 2em;
		line-height: 1.1em;
	}

	#secondary.widget-area .widget h1.widget-title {
		font-size: 1.5em;
		margin-bottom: 0.5em;
		padding-bottom: 0.5em;
	}

	#secondary.widget-area .widget ul li {
		margin-bottom: 0.5em;
	}

	.archive .site-main .page-header h1.page-title {
		color: #029e93;
		font-size: 1.8em;
		font-style: italic;
		line-height: 1.3em;
	}

	.archive .site-main .page-header h1.page-title span {
		display: block;
		font-size: 1.2em;
		color: #272727;
		font-family: "proxima_nova_bold";
		font-weight: normal;
		font-style: normal;
	}

	#movies_section div.content_side div.wrap div.right_side div.bottom_side div.bs {
		float: left;
		width: 100%;
	}

	.news_archive div.wrap #primary .entry-header h2.entry-title {
		font-size: 1.6em;
		line-height: 1.1em;
	}

	#overlay div#review-box {
		background-color: #fff;
		padding: 1.5em;
		position: absolute;
		width: 80%;
	}

	#overlay div#review-box a.review_box_close {
		// background: url("images/close_btn_rating.jpg") no-repeat scroll left top transparent;
		display: block;
		height: 32px;
		position: absolute;
		right: -5px;
		top: -5px;
		width: 31px;
	}

	#overlay div#review-box div.wrap div.external form#commentform h1 {
		color: #0c0c0c;
		font-family: "proxima_novablack";
		font-size: 1.4em;
		text-transform: uppercase;
	}

	#overlay div#review-box div.wrap div.external form#commentform div.rateit {
		float: left;
		margin-right: 1.5em;
		margin-top: 0em;
	}

	#overlay div#review-box div.wrap div.external form#commentform div.rate_text {
		color: #717171;
		float: left;
		font-family: "proxima_nova_regular";
		font-size: 0.7em;
		margin: 0em 0 1.5em;
	}

	#overlay div#review-box div.wrap div.external form#commentform p.comment-form-comment,
	#overlay div#review-box div.wrap div.external form#commentform p.comment-form-author,
	#overlay div#review-box div.wrap div.external form#commentform p.comment-form-email {
		margin-bottom: 0.5em;
	}

	#overlay div#review-box div.wrap div.external form#commentform textarea#comment {
		// background: url("images/form_icon/4.png") no-repeat scroll 1em 1em #fff;
		border: 1px solid #cfcfcf;
		border-radius: 0;
		color: #747474;
		font-family: "proxima_nova_regular";
		height: 5em;
		padding: 1em 1em 0 2.9em;
		resize: none;
		width: 100%;
	}

	#overlay div#review-box div.wrap div.external form#commentform input {
		background-color: #fff;
		border: 1px solid #cfcfcf;
		border-radius: 0;
		color: #747474;
		float: left;
		font-family: "proxima_nova_regular";
		height: 2em;
		line-height: 2em;
		padding: 0 1em 0 2.9em;
		width: 49%;
	}

	#overlay div#review-box div.wrap div.external form#commentform input#author {
		// background: url("images/form_icon/1.png") no-repeat scroll 1em center #fff;
		margin-right: 2%;
	}

	#overlay div#review-box div.wrap div.external form#commentform input#email {
		// background: url("images/form_icon/2.png") no-repeat scroll 1em center #fff;
		margin-right: 0;
	}

	#overlay div#review-box div.wrap div.external form#commentform button.btn.form {
		left: 0em;
		top: 1em;
		font-size: 0.61em;
	}

	#video-box {
		background-color: #000;
		position: absolute;
		right: 0;
		top: 0;
		width: 18em;
		height: 12em;
	}

	.video_box_close {
		// background: url("images/ico_close_btn2.png") no-repeat scroll left top transparent;
		display: block;
		height: 18px;
		position: absolute;
		right: -25px;
		top: 0;
		width: 17px;
	}
}

.scroll-pane,
.scroll-pane-news,
.scroll-pane-store {
	overflow: auto;
	width: calc(100vw - 57px);
}

.scroll-content,
.scroll-content-news,
.scroll-content-store {
	width: calc((100vw * 5) - (25px * 10));
	display: flex;
}

.scroll-bar-wrap,
.scroll-bar-wrap-news,
.scroll-bar-wrap-store {
	background: #f8f8f8 none repeat scroll 0 0;
	clear: left;
	padding: 0 4px 0 2px;
	margin: 30px -1px;
}

.scroll-bar-wrap .ui-slider,
.scroll-bar-wrap-news .ui-slider,
.scroll-bar-wrap-store .ui-slider {
	background: #f8f8f8 none repeat scroll 0 0;
	border: 0 none;
	border-radius: 0;
	height: 9px;
	margin: 0 auto;
}

.scroll-bar-wrap .ui-handle-helper-parent,
.scroll-bar-wrap-news .ui-handle-helper-parent,
.scroll-bar-wrap-store .ui-handle-helper-parent {
	position: relative;
	width: 100%;
	height: 100%;
	margin: 0 auto;
}

.scroll-bar-wrap .ui-slider-handle,
.scroll-bar-wrap-news .ui-slider-handle,
.scroll-bar-wrap-store .ui-slider-handle {
	height: 13px;
	top: -2px;
	margin-left: -0.6em;
	cursor: pointer;
	position: absolute;
	width: 1.2em;
	z-index: 2;
}

.scroll-bar-wrap .ui-slider-handle .ui-icon,
.scroll-bar-wrap-news .ui-slider-handle .ui-icon,
.scroll-bar-wrap-store .ui-slider-handle .ui-icon {
	margin: -8px auto 0;
	position: relative;
	top: 50%;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
	// background: url("images/border_img.jpg") no-repeat scroll center center #029E93;
	border: none;
	color: #029e93;
	font-weight: bold;
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
	// background: url("images/border_img.jpg") no-repeat scroll center center #029E93;
	border: none;
	color: #029e93;
	font-weight: bold;
}

.ui-corner-all,
.ui-corner-bottom,
.ui-corner-right,
.ui-corner-br {
	border-radius: 0;
}

#overlay #popUp-box {
	background-color: #000;
	max-width: 600px;
	position: absolute;
	width: 70%;
}

#overlay #popUp-box #formside {
}

#overlay #popUp-box a.popUp_box_close {
	// background: transparent url("images/thanks/thanks_close_btn.png") no-repeat scroll 0 0;
	display: block;
	height: 36px;
	position: absolute;
	right: -12px;
	top: -12px;
	width: 36px;
	z-index: 2;
}

#overlay #popUp-box #formside .image_ext {
	position: relative;
	width: 100%;
	text-align: center;
	z-index: 1;
}

#overlay #popUp-box #formside .image_ext img {
	position: relative;
	top: 20px;
}

#overlay #popUp-box #formside .text {
	background-color: #fff;
	margin: 0 10px;
	padding: 25px 15px;
	text-align: center;
}

#overlay #popUp-box #formside .text h1 {
	font-family: "proxima_nova_bold";
	font-size: 37px;
	margin-top: 1em;
}

#overlay #popUp-box #formside .text h3 {
	font-family: "proxima_novalight";
	font-size: 29px;
	margin-bottom: 1em;
}

#overlay #popUp-box #formside .soci {
	background-color: #f8f8f8;
	margin: 0 10px 10px;
	text-align: center;
	padding: 30px 0;
}

#overlay #popUp-box #formside .soci .social_footer {
}
