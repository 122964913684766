.rateit {
    display: -moz-inline-box;
    display: inline-block;
    position: relative;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
}

.rateit .rateit-range
{
    position: relative;
    display: -moz-inline-box;
    display: inline-block;
    background: url(assets/images/star.png);
    height: 18px;
    outline: none;
}

.mfp-content .rateit .rateit-range
{
    position: relative;
    display: -moz-inline-box;
    display: inline-block;
    background: url(assets/images/star2.png);
    height: 18px;
    outline: none;
	border: 1px solid transparent;
}

.rateit .rateit-range * {
    display:block;
}

/* for IE 6 */
* html .rateit, * html .rateit .rateit-range
{
    display: inline;
}

/* for IE 7 */
* + html .rateit, * + html .rateit .rateit-range
{
    display: inline;
}

.rateit .rateit-hover, .rateit .rateit-selected
{
    position: absolute;
    left: 0px;
}

.rateit .rateit-hover-rtl, .rateit .rateit-selected-rtl
{
    left: auto;
    right: 0px;
}

.rateit .rateit-hover
{
    background: url(assets/images/star.png) left -18px;
}

.mfp-content .rateit .rateit-hover{
    background: url(assets/images/star2.png) left -18px;
}

.rateit .rateit-hover-rtl
{
    background-position: right -18px;
}

.rateit .rateit-selected
{
    background: url(assets/images/star.png) left -18px;
}

.mfp-content .rateit .rateit-selected
{
    background: url(assets/images/star2.png) left -18px;
}

.rateit .rateit-selected-rtl
{
    background-position: right -18px;
}

.rateit .rateit-preset
{
    background: url(assets/images/star.png) left -18px;
}

.mfp-content .rateit .rateit-preset
{
    background: url(assets/images/star2.png) left -18px;
}

.rateit .rateit-preset-rtl
{
    background: url(assets/images/star.png) left -18px;
}

.mfp-content .rateit .rateit-preset-rtl
{
    background: url(assets/images/star2.png) left -18px;
}

.rateit button.rateit-reset
{
    background: url(assets/images/delete.gif) 0 0;
    width: 16px;
    height: 16px;
    display: -moz-inline-box;
    display: inline-block;
    float: left;
    outline: none;
    border:none;
    padding: 0;
}

.rateit button.rateit-reset:hover, .rateit button.rateit-reset:focus
{
    background-position: 0 -18px;
}

/*****************************************************************************/
.rateit_sm {
    display: -moz-inline-box;
    display: inline-block;
    position: relative;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
}

.rateit_sm .rateit-range
{
    position: relative;
    display: -moz-inline-box;
    display: inline-block;
    background: url(assets/images/star_sm.png);
    height: 16px;
    outline: none;
}

#overlay .rateit_sm .rateit-range
{
    position: relative;
    display: -moz-inline-box;
    display: inline-block;
    background: url(assets/images/star_sm.png);
    height: 16px;
    outline: none;
}

.rateit_sm .rateit-range * {
    display:block;
}

/* for IE 6 */
* html .rateit_sm, * html .rateit_sm .rateit-range
{
    display: inline;
}

/* for IE 7 */
* + html .rateit_sm, * + html .rateit_sm .rateit-range
{
    display: inline;
}

.rateit_sm .rateit-hover, .rateit_sm .rateit-selected
{
    position: absolute;
    left: 0px;
}

.rateit_sm .rateit-hover-rtl, .rateit_sm .rateit-selected-rtl
{
    left: auto;
    right: 0px;
}

.rateit_sm .rateit-hover
{
    background: url(assets/images/star_sm.png) left -16px;
}

#overlay .rateit_sm .rateit-hover{
    background: url(assets/images/star_sm.png) left -16px;
}

.rateit_sm .rateit-hover-rtl
{
    background-position: right -16px;
}

.rateit_sm .rateit-selected
{
    background: url(assets/images/star_sm.png) left -16px;
}

#overlay .rateit_sm .rateit-selected
{
    background: url(assets/images/star_sm.png) left -16px;
}

.rateit_sm .rateit-selected-rtl
{
    background-position: right -16px;
}

.rateit_sm .rateit-preset
{
    background: url(assets/images/star_sm.png) left -16px;
}

#overlay .rateit_sm .rateit-preset
{
    background: url(assets/images/star_sm.png) left -16px;
}

.rateit_sm .rateit-preset-rtl
{
    background: url(assets/images/star_sm.png) left -16px;
}

#overlay .rateit_sm .rateit-preset-rtl
{
    background: url(assets/images/star_sm.png) left -16px;
}

.rateit_sm button.rateit-reset
{
    background: url(assets/images/delete.gif) 0 0;
    width: 16px;
    height: 16px;
    display: -moz-inline-box;
    display: inline-block;
    float: left;
    outline: none;
    border:none;
    padding: 0;
}

.rateit_sm button.rateit-reset:hover, .rateit_sm button.rateit-reset:focus
{
    background-position: 0 -16px;
}
